import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { Menu, Space, Typography } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Logo from './logo';
import Notifications from './notifications';
import ProfileMenu from './profile-menu';
import { CONTACT_US_EMAIL, CONTACT_US_PHONE } from '@common/contacts';
import {
  ADMIN,
  BANKING,
  KYC,
  MARKET_WATCH,
  PROFILE,
  ROUTE_PLAN,
  SHIPOWNERS,
  SUPPLIERS,
} from '@common/routes';
import { ACCESS_READ, ACCESS_WRITE } from 'src/access-checker/access-types';
import { AccessContext } from 'src/access-checker/provider';
import { AccessKey } from 'src/access-checker/types';
import { useAuth } from 'src/hooks/auth';

const TOGGLE_MENU = 'menu';
const TOGGLE_PROFILE = 'profile';

type CollapsedItem = typeof TOGGLE_MENU | typeof TOGGLE_PROFILE;

type MenuItem = {
  key: string;
  accessKey?: AccessKey;
  className?: string;
  to?: string;
  render(): React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const Navigation: React.FC = () => {
  const [collapsed, setCollapsed] = useState<CollapsedItem>();

  const constToggleMenuItems = (item: CollapsedItem): void => {
    setCollapsed(collapsed === item ? undefined : item);
  };

  const { location } = useHistory();
  const [t] = useTranslation();
  const activeClassname = (path?: string) => {
    if (!path) return '';
    return location.pathname.search(path) === -1
      ? ''
      : 'ant-menu-item-selected';
  };
  const { logout, role = 'guest' } = useAuth();
  const isAdmin = role.search(/admin/i) !== -1;

  const menuItems: MenuItem[] = [
    {
      key: 'admin',
      to: ADMIN.APP,
      render: () => <Link to={ADMIN.APP}>{t('header_navigation.admin')}</Link>,
      accessKey: 'nav.admin',
    },
    {
      key: 'shipowners',
      to: SHIPOWNERS.APP,
      render: () => (
        <Link to={SHIPOWNERS.APP}>{t('header_navigation.shipowners')}</Link>
      ),
      accessKey: 'nav.shipowners',
    },
    {
      key: 'suppliers',
      to: SUPPLIERS.APP,
      render: () => (
        <Link to={SUPPLIERS.APP}>{t('header_navigation.suppliers')}</Link>
      ),
      accessKey: 'nav.suppliers',
    },
    {
      key: 'banking',
      to: BANKING.APP,
      render: () => (
        <Link to={BANKING.APP}>{t('header_navigation.banking')}</Link>
      ),
      accessKey: 'nav.banking',
    },
    ...(isAdmin
      ? []
      : [
          {
            key: 'plan',
            to: ROUTE_PLAN.APP,
            disabled: true,
            render: () => (
              <Link to={ROUTE_PLAN.APP}>
                {t('header_navigation.route_plan')}
              </Link>
            ),
          },
          {
            key: 'watch',
            to: MARKET_WATCH.APP,
            disabled: true,
            render: () => (
              <Link to={MARKET_WATCH.APP}>
                {t('header_navigation.market_watch')}
              </Link>
            ),
          },
        ]),
    {
      key: 'profile-menu:kyc-registration-mobile',
      accessKey: 'kyc.app.menu',
      className: 'header-response-menu__mobile-item',
      render: () => (
        <Link to={KYC.APP}>{t('header_navigation.kyc_registration')}</Link>
      ),
    },
    {
      key: 'profile',
      className: 'header-response-menu__mobile-item',
      to: PROFILE.APP,
      render: () => (
        <Link to={PROFILE.APP}>{t('header_navigation.profile')}</Link>
      ),
    },
    {
      key: 'space-5',
      className: 'header-response-menu__mobile-item',
      render: () => <div className="padding-bottom__lg" />,
    },
    {
      key: 'contact-menu:contact-support',
      accessKey: 'nav.company',
      className:
        'header-response-menu__item header-response-menu__item--small header-response-menu__mobile-item',
      render: () => (
        <div>
          <div>
            <Typography.Text>
              {t('header_navigation.contact_support')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Link href={`mailto:${CONTACT_US_EMAIL}`}>
              {CONTACT_US_EMAIL}
            </Typography.Link>
          </div>
          <div>
            <Typography.Link href={`tel:${CONTACT_US_PHONE}`}>
              {CONTACT_US_PHONE}
            </Typography.Link>
          </div>
        </div>
      ),
    },
    {
      key: 'contact-menu:live-chat',
      className:
        'header-response-menu__item  header-response-menu__mobile-item',
      render: () => (
        <>
          {t('header_navigation.live_chat')}{' '}
          <WechatOutlined className={`header-response-menu__chat-icon`} />
        </>
      ),
      onClick: () => {
        window.$chatwoot?.toggle();
      },
    },
    {
      key: 'space-3',
      className: 'header-response-menu__mobile-item',
      render: () => <div className="padding-bottom__lg" />,
    },
    {
      key: 'space-1',
      className: 'header-response-menu__mobile-item',
      render: () => <div className="padding-bottom__lg" />,
    },
    {
      key: 'space-2',
      className: 'header-response-menu__mobile-item',
      render: () => <div className="padding-bottom__lg" />,
    },
    {
      key: 'sign-out',
      className:
        'header-response-menu__mobile-item header-response-menu__sign-out',
      render: () => t('header_navigation.sign_out'),
      onClick: logout,
    },
  ];

  const accessState = useContext(AccessContext);

  const menuItemsFiltered = menuItems.filter(
    ({ accessKey }) =>
      !accessKey ||
      accessState[accessKey] === ACCESS_READ ||
      accessState[accessKey] === ACCESS_WRITE,
  );

  return (
    <div>
      <Space className="header-body">
        <div className="header-side-holder">
          <Logo />
        </div>
        <Menu
          className={`header-response-menu header-main-color ${
            collapsed === TOGGLE_MENU ? 'header-response-menu--active' : ''
          }`}
          mode="horizontal"
          theme="dark"
          disabledOverflow={true}
        >
          {menuItemsFiltered.map((item) => (
            <Menu.Item
              key={item.key}
              className={activeClassname(item.to) + ' ' + item.className || ''}
              disabled={item.disabled ?? false}
            >
              {item.render()}
            </Menu.Item>
          ))}
        </Menu>
        <div className="header-side-holder header-side-holder-left-align">
          <Menu
            mode="horizontal"
            theme="dark"
            disabledOverflow={true}
            selectedKeys={[]}
          >
            {/* TODO(arustamyan): replace children with items prop */}
            <Menu.Item key="notifications" className="header-menu-icon">
              <Notifications />
            </Menu.Item>
            <Menu.Item
              key="profile-menu-item"
              className="header-profile-menu__menu-item"
            >
              <ProfileMenu />
            </Menu.Item>
            <Menu.Item
              key={TOGGLE_MENU}
              className="header-menu-toggle header-menu-icon"
              onClick={(): void => {
                constToggleMenuItems(TOGGLE_MENU);
              }}
            >
              {collapsed === TOGGLE_MENU ? (
                <MenuUnfoldOutlined className="header-menu-icon" />
              ) : (
                <MenuFoldOutlined className="header-menu-icon" />
              )}
            </Menu.Item>
          </Menu>
        </div>
      </Space>
    </div>
  );
};

export default Navigation;
