import { FileOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import FormTitle from './form-title';
import { useDownloadFile } from 'src/hooks';
import { useTradingFiles } from 'src/hooks/use-trading-files';

export const Attachments: React.FC = () => {
  const { tradingFiles = [], fetching } = useTradingFiles();
  const [t] = useTranslation();
  const downloadFile = useDownloadFile();

  if (!tradingFiles || !tradingFiles.length) return <></>;

  return (
    <div className="offset-bottom trading-attachments">
      <FormTitle>{t('attachments.files')}</FormTitle>
      <Space direction="vertical">
        {tradingFiles.map(({ id, name, type }) => (
          <Card
            key={id}
            loading={fetching}
            size="small"
            className="trading-attachments__card"
            onClick={() => downloadFile(id)}
          >
            <Space>
              <FileOutlined />
              <Typography.Text code>{t(`attachments.${type}`)}</Typography.Text>
              <Typography.Link>{name}</Typography.Link>
            </Space>
          </Card>
        ))}
      </Space>
    </div>
  );
};

export default Attachments;
