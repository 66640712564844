import * as Types from './types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WalletDataFragment = { __typename?: 'wallet', wallet_id: string, balanceAmount?: number, availableAmount?: number, onHoldAmount?: number, currency: { __typename?: 'currency', fiat_multiplier: number, id: string, code: string }, transactions?: Array<{ __typename?: 'money_transaction', amount: number, id: string, updated_at?: string }> };

export type TxTradingOrderFragment = { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> };

export type TransactionFragment = { __typename?: 'money_transaction', amount: number, comment?: string, deposit_bank_name?: string, reference_number?: string, id: string, shortId: string, updatedAt?: string, wallet1?: string, wallet2?: string, wallet3?: string, txType: string, status: string, isIncome?: boolean, balanceUpTo?: number, onHoldUpTo?: number, wallet?: Array<{ __typename?: 'wallet', id: string, currency: { __typename?: 'currency', code: string }, company: { __typename?: 'company', name: string } }>, walletFrom?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, walletTo?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, currency?: Array<{ __typename?: 'currency', id: string, code: string }>, tradingOrder?: { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> }, bankAccount?: { __typename?: 'company_bank_account', iban: string, swift: string, correspondent_swift?: string, bank_address: string, correspondent_bank_name?: string, bankName: string, name: string } };

export type UserWalletsSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  currencyId?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type UserWalletsSubscription = { __typename?: 'subscription_root', user?: { __typename?: 'user', company?: { __typename?: 'company', availableAmount?: number, wallets: Array<{ __typename?: 'wallet', wallet_id: string, balanceAmount?: number, availableAmount?: number, onHoldAmount?: number, currency: { __typename?: 'currency', fiat_multiplier: number, id: string, code: string }, transactions?: Array<{ __typename?: 'money_transaction', amount: number, id: string, updated_at?: string }> }> } } };

export type CreateUserWalletMutationVariables = Types.Exact<{
  data: Types.CreateWalletInput;
}>;


export type CreateUserWalletMutation = { __typename?: 'mutation_root', create_wallet: { __typename?: 'DummyOutput', ok: boolean } };

export type AllInternalTxsQueryVariables = Types.Exact<{
  lastId: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Types.MoneyTransactionBoolExp>;
}>;


export type AllInternalTxsQuery = { __typename?: 'query_root', transactions: Array<{ __typename?: 'money_transaction', amount: number, comment?: string, deposit_bank_name?: string, reference_number?: string, id: string, shortId: string, updatedAt?: string, wallet1?: string, wallet2?: string, wallet3?: string, txType: string, status: string, isIncome?: boolean, balanceUpTo?: number, onHoldUpTo?: number, wallet?: Array<{ __typename?: 'wallet', id: string, currency: { __typename?: 'currency', code: string }, company: { __typename?: 'company', name: string } }>, walletFrom?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, walletTo?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, currency?: Array<{ __typename?: 'currency', id: string, code: string }>, tradingOrder?: { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> }, bankAccount?: { __typename?: 'company_bank_account', iban: string, swift: string, correspondent_swift?: string, bank_address: string, correspondent_bank_name?: string, bankName: string, name: string } }> };

export type AllExternalTxsQueryVariables = Types.Exact<{
  lastId: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Types.MoneyTransactionBoolExp>;
}>;


export type AllExternalTxsQuery = { __typename?: 'query_root', transactions: Array<{ __typename?: 'money_transaction', amount: number, comment?: string, deposit_bank_name?: string, reference_number?: string, id: string, shortId: string, updatedAt?: string, wallet1?: string, wallet2?: string, wallet3?: string, txType: string, status: string, isIncome?: boolean, balanceUpTo?: number, onHoldUpTo?: number, wallet?: Array<{ __typename?: 'wallet', id: string, currency: { __typename?: 'currency', code: string }, company: { __typename?: 'company', name: string } }>, walletFrom?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, walletTo?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, currency?: Array<{ __typename?: 'currency', id: string, code: string }>, tradingOrder?: { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> }, bankAccount?: { __typename?: 'company_bank_account', iban: string, swift: string, correspondent_swift?: string, bank_address: string, correspondent_bank_name?: string, bankName: string, name: string } }> };

export type InternalTxsSubscriptionVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Types.MoneyTransactionBoolExp>;
}>;


export type InternalTxsSubscription = { __typename?: 'subscription_root', transactions: Array<{ __typename?: 'money_transaction', amount: number, comment?: string, deposit_bank_name?: string, reference_number?: string, id: string, shortId: string, updatedAt?: string, wallet1?: string, wallet2?: string, wallet3?: string, txType: string, status: string, isIncome?: boolean, balanceUpTo?: number, onHoldUpTo?: number, wallet?: Array<{ __typename?: 'wallet', id: string, currency: { __typename?: 'currency', code: string }, company: { __typename?: 'company', name: string } }>, walletFrom?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, walletTo?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, currency?: Array<{ __typename?: 'currency', id: string, code: string }>, tradingOrder?: { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> }, bankAccount?: { __typename?: 'company_bank_account', iban: string, swift: string, correspondent_swift?: string, bank_address: string, correspondent_bank_name?: string, bankName: string, name: string } }> };

export type ExternalTxsSubscriptionVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Types.MoneyTransactionBoolExp>;
}>;


export type ExternalTxsSubscription = { __typename?: 'subscription_root', transactions: Array<{ __typename?: 'money_transaction', amount: number, comment?: string, deposit_bank_name?: string, reference_number?: string, id: string, shortId: string, updatedAt?: string, wallet1?: string, wallet2?: string, wallet3?: string, txType: string, status: string, isIncome?: boolean, balanceUpTo?: number, onHoldUpTo?: number, wallet?: Array<{ __typename?: 'wallet', id: string, currency: { __typename?: 'currency', code: string }, company: { __typename?: 'company', name: string } }>, walletFrom?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, walletTo?: { __typename?: 'wallet', company: { __typename?: 'company', name: string } }, currency?: Array<{ __typename?: 'currency', id: string, code: string }>, tradingOrder?: { __typename?: 'trading_order', additional: Record<string, any>, short_id: string, eta: string, copied_marginal_percentage: number, copied_platform_fee: number, status?: string, total?: number, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, adjusted_volume?: number, admin_adjusted_volume?: number, meta?: { __typename?: 'product_meta', short_name: string } }> }, bankAccount?: { __typename?: 'company_bank_account', iban: string, swift: string, correspondent_swift?: string, bank_address: string, correspondent_bank_name?: string, bankName: string, name: string } }> };

export type WalletWithdrawInitMutationVariables = Types.Exact<{
  data: Types.WalletWithdrawRequestCodeInput;
}>;


export type WalletWithdrawInitMutation = { __typename?: 'mutation_root', wallet_withdraw_request_code?: { __typename?: 'DummyOutput', ok: boolean } };

export type WalletWithdrawRequestMutationVariables = Types.Exact<{
  data: Types.WalletWithdrawRequestInput;
}>;


export type WalletWithdrawRequestMutation = { __typename?: 'mutation_root', wallet_withdraw_request: { __typename?: 'DummyOutput', ok: boolean } };

export type CompanyBankAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyBankAccountsQuery = { __typename?: 'query_root', accounts: Array<{ __typename?: 'company_bank_account', account_name: string, currency_id: string, account_holder: string, bank_name: string, bank_address: string, iban: string, swift: string, correspondent_bank_name?: string, correspondent_swift?: string, transfer_details?: string, id: string, currency: { __typename?: 'currency', id: string, code: string } }> };

export type CompanyBankAccountCodeMutationVariables = Types.Exact<{
  data: Types.CompanyBankAccountCodeInput;
}>;


export type CompanyBankAccountCodeMutation = { __typename?: 'mutation_root', company_bank_account_code?: { __typename?: 'DummyOutput', ok: boolean } };

export type CompanyBankAccountActionMutationVariables = Types.Exact<{
  data: Types.CompanyBankAccountActionInput;
}>;


export type CompanyBankAccountActionMutation = { __typename?: 'mutation_root', company_bank_account_action: { __typename?: 'DummyOutput', ok: boolean } };

export type DepositEmailMutationVariables = Types.Exact<{
  data: Types.DepositEmailInput;
}>;


export type DepositEmailMutation = { __typename?: 'mutation_root', deposit_email: { __typename?: 'DummyOutput', ok: boolean } };

export const WalletDataFragmentDoc = gql`
    fragment WalletData on wallet {
  wallet_id
  currency: currency_rel {
    id: currency_id
    code: currency_code
    fiat_multiplier
  }
  balanceAmount: balance_amount_com(args: {})
  availableAmount: available_amount_com(args: {})
  onHoldAmount: on_hold_amount_com(args: {})
  transactions: transactions_com(args: {}) {
    id: money_transaction_id
    amount
    updated_at: updated_at_com
  }
}
    `;
export const TxTradingOrderFragmentDoc = gql`
    fragment TxTradingOrder on trading_order {
  vessel: company_vessel_rel {
    meta: meta_rel {
      name: ship_name
    }
  }
  port: ship_port_rel {
    name
  }
  shipowner: shipowner_com {
    company: company_rel {
      name: company_name
    }
  }
  supplier: supplier_com {
    company: company_rel {
      name: company_name
    }
  }
  additional
  short_id
  eta
  status: status_com
  copied_marginal_percentage
  copied_platform_fee
  total: total_price_com
  products: products_rel {
    volume_from
    volume_to
    adjusted_volume
    admin_adjusted_volume
    meta: product_meta_rel {
      short_name
    }
  }
}
    `;
export const TransactionFragmentDoc = gql`
    fragment Transaction on money_transaction {
  id: money_transaction_id
  shortId: short_id
  amount
  updatedAt: updated_at_com
  wallet1: wallet_1
  wallet2: wallet_2
  wallet3: wallet_3
  txType: tx_type
  status: tx_status
  isIncome: is_income_com
  balanceUpTo: balance_up_to_com
  onHoldUpTo: on_hold_up_to_com
  wallet: my_wallet_com {
    id: wallet_id
    currency: currency_rel {
      code: currency_code
    }
    company: company_rel {
      name: company_name
    }
  }
  walletFrom: wallet_1_full_rel {
    company: company_rel {
      name: company_name
    }
  }
  walletTo: wallet_2_full_rel {
    company: company_rel {
      name: company_name
    }
  }
  currency: currency_com {
    id: currency_id
    code: currency_code
  }
  tradingOrder: trading_order_rel {
    ...TxTradingOrder
  }
  bankAccount: company_bank_account_rel {
    bankName: bank_name
    name: account_name
    iban
    swift
    correspondent_swift
    bank_address
    correspondent_bank_name
  }
  comment
  deposit_bank_name
  reference_number
}
    ${TxTradingOrderFragmentDoc}`;
export const UserWalletsDocument = gql`
    subscription UserWallets($userId: uuid!, $currencyId: uuid) {
  user: user_by_pk(user_id: $userId) {
    company: company_rel {
      availableAmount: available_amount_com(args: {currency_id: $currencyId})
      wallets: wallets_rel {
        ...WalletData
      }
    }
  }
}
    ${WalletDataFragmentDoc}`;

export function useUserWalletsSubscription<TData = UserWalletsSubscription>(options: Omit<Urql.UseSubscriptionArgs<UserWalletsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<UserWalletsSubscription, TData>) {
  return Urql.useSubscription<UserWalletsSubscription, TData, UserWalletsSubscriptionVariables>({ query: UserWalletsDocument, ...options }, handler);
};
export const CreateUserWalletDocument = gql`
    mutation CreateUserWallet($data: CreateWalletInput!) {
  create_wallet(data: $data) {
    ok
  }
}
    `;

export function useCreateUserWalletMutation() {
  return Urql.useMutation<CreateUserWalletMutation, CreateUserWalletMutationVariables>(CreateUserWalletDocument);
};
export const AllInternalTxsDocument = gql`
    query AllInternalTxs($lastId: timestamptz!, $limit: Int!, $filter: money_transaction_bool_exp = {}) {
  transactions: money_transaction(
    where: {tx_type: {_in: ["transfer"]}, _and: [{updated_at_com: {_lt: $lastId}}, $filter]}
    order_by: {updated_at_com: desc}
    limit: $limit
  ) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

export function useAllInternalTxsQuery(options: Omit<Urql.UseQueryArgs<AllInternalTxsQueryVariables>, 'query'>) {
  return Urql.useQuery<AllInternalTxsQuery>({ query: AllInternalTxsDocument, ...options });
};
export const AllExternalTxsDocument = gql`
    query AllExternalTxs($lastId: timestamptz!, $limit: Int!, $filter: money_transaction_bool_exp = {}) {
  transactions: money_transaction(
    where: {tx_type: {_in: ["deposit", "withdraw"]}, _and: [{updated_at_com: {_lt: $lastId}}, $filter]}
    order_by: {updated_at_com: desc}
    limit: $limit
  ) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

export function useAllExternalTxsQuery(options: Omit<Urql.UseQueryArgs<AllExternalTxsQueryVariables>, 'query'>) {
  return Urql.useQuery<AllExternalTxsQuery>({ query: AllExternalTxsDocument, ...options });
};
export const InternalTxsDocument = gql`
    subscription InternalTxs($limit: Int!, $filter: money_transaction_bool_exp = {}) {
  transactions: money_transaction(
    where: {tx_type: {_in: ["transfer"]}, _and: [$filter]}
    order_by: [{updated_at_com: desc}, {short_id: asc}]
    limit: $limit
  ) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

export function useInternalTxsSubscription<TData = InternalTxsSubscription>(options: Omit<Urql.UseSubscriptionArgs<InternalTxsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<InternalTxsSubscription, TData>) {
  return Urql.useSubscription<InternalTxsSubscription, TData, InternalTxsSubscriptionVariables>({ query: InternalTxsDocument, ...options }, handler);
};
export const ExternalTxsDocument = gql`
    subscription ExternalTxs($limit: Int!, $filter: money_transaction_bool_exp = {}) {
  transactions: money_transaction(
    where: {tx_type: {_in: ["deposit", "withdraw"]}, _and: [$filter]}
    order_by: [{updated_at_com: desc}, {short_id: asc}]
    limit: $limit
  ) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

export function useExternalTxsSubscription<TData = ExternalTxsSubscription>(options: Omit<Urql.UseSubscriptionArgs<ExternalTxsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ExternalTxsSubscription, TData>) {
  return Urql.useSubscription<ExternalTxsSubscription, TData, ExternalTxsSubscriptionVariables>({ query: ExternalTxsDocument, ...options }, handler);
};
export const WalletWithdrawInitDocument = gql`
    mutation WalletWithdrawInit($data: WalletWithdrawRequestCodeInput!) {
  wallet_withdraw_request_code(data: $data) {
    ok
  }
}
    `;

export function useWalletWithdrawInitMutation() {
  return Urql.useMutation<WalletWithdrawInitMutation, WalletWithdrawInitMutationVariables>(WalletWithdrawInitDocument);
};
export const WalletWithdrawRequestDocument = gql`
    mutation WalletWithdrawRequest($data: WalletWithdrawRequestInput!) {
  wallet_withdraw_request(data: $data) {
    ok
  }
}
    `;

export function useWalletWithdrawRequestMutation() {
  return Urql.useMutation<WalletWithdrawRequestMutation, WalletWithdrawRequestMutationVariables>(WalletWithdrawRequestDocument);
};
export const CompanyBankAccountsDocument = gql`
    query CompanyBankAccounts {
  accounts: company_bank_account(where: {active: {_eq: true}}) {
    id: company_bank_account_id
    account_name
    currency_id
    currency: currency_rel {
      id: currency_id
      code: currency_code
    }
    account_holder
    bank_name
    bank_address
    iban
    swift
    correspondent_bank_name
    correspondent_swift
    transfer_details
  }
}
    `;

export function useCompanyBankAccountsQuery(options?: Omit<Urql.UseQueryArgs<CompanyBankAccountsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyBankAccountsQuery>({ query: CompanyBankAccountsDocument, ...options });
};
export const CompanyBankAccountCodeDocument = gql`
    mutation CompanyBankAccountCode($data: CompanyBankAccountCodeInput!) {
  company_bank_account_code(data: $data) {
    ok
  }
}
    `;

export function useCompanyBankAccountCodeMutation() {
  return Urql.useMutation<CompanyBankAccountCodeMutation, CompanyBankAccountCodeMutationVariables>(CompanyBankAccountCodeDocument);
};
export const CompanyBankAccountActionDocument = gql`
    mutation CompanyBankAccountAction($data: CompanyBankAccountActionInput!) {
  company_bank_account_action(data: $data) {
    ok
  }
}
    `;

export function useCompanyBankAccountActionMutation() {
  return Urql.useMutation<CompanyBankAccountActionMutation, CompanyBankAccountActionMutationVariables>(CompanyBankAccountActionDocument);
};
export const DepositEmailDocument = gql`
    mutation DepositEmail($data: DepositEmailInput!) {
  deposit_email(data: $data) {
    ok
  }
}
    `;

export function useDepositEmailMutation() {
  return Urql.useMutation<DepositEmailMutation, DepositEmailMutationVariables>(DepositEmailDocument);
};