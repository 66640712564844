import { useTranslation } from 'react-i18next';

import FinalAmountModalContent from './final-amount-modal-content';
import { FinalAmountModalForm } from './types';
import { useShipownerOrderActionMutation } from '@common/__generated__/trading.urql';
import { ShipownerAction } from '@common/trading/status';
import FormModal, { FormProps } from 'src/components/form-modal';
import { XL_MODAL_WIDTH } from 'src/config/styles';

export type FinalAmountModalProps = {
  id: string;
  disabled?: boolean;
};

export const FinalAmountModal: React.FC<FinalAmountModalProps> = ({
  id,
  disabled,
}) => {
  const [t] = useTranslation();

  if (!id) {
    return null;
  }
  const [, sendShipownerAction] = useShipownerOrderActionMutation();

  return (
    <FormModal
      title={t('shipowner.final_amount_of_fuel')}
      width={XL_MODAL_WIDTH}
      formProps={
        {
          onSubmit: async ({ setVisibility, products }) => {
            const finalVolumes = Object.fromEntries(
              Object.entries(products).map(([productId, data]) => [
                productId,
                data.final_volume,
              ]),
            );
            const { data } = await sendShipownerAction({
              data: {
                action: ShipownerAction.ENTER_FINAL_QTY,
                order_id: id,
                final_quantity_products: finalVolumes,
              },
            });
            if (data) {
              setVisibility(false);
              return { status: true };
            }
            return { status: false };
          },
        } as FormProps<FinalAmountModalForm>
      }
      okButtonProps={{
        className: 'cy-final-amount-modal-submit',
      }}
      buttonProps={{
        className: 'cy-final-amount-modal',
        buttonText: t('shipowner.form.enter_the_final_amount_of_fuel'),
        disabled,
      }}
    >
      <FinalAmountModalContent id={id} />
    </FormModal>
  );
};

export default FinalAmountModal;
