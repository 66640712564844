import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

export const GuestFooter: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className="centered-text">
      <Paragraph>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </Paragraph>
      <Paragraph type="secondary">{t('guest_footer.copyright')}</Paragraph>
    </div>
  );
};

export default GuestFooter;
