import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { PaymentFeeProps } from './types';
import { currencyFormatter } from 'src/components/currency-input';

const currencyMap = {
  USD: '$:value',
  EUR: '€:value',
};

export const PaymentFee: React.FC<PaymentFeeProps> = ({
  product,
  platformFee,
  total,
  currencyFormat = 'USD',
}) => {
  const [t] = useTranslation();

  return (
    <div className="payment-calculation">
      <Row>
        <Col span={12}>
          <div>{t('shipowner.calc_product')}</div>
          <div>{t('shipowner.calc_platform_fee')}</div>
          <div className="payment-calculation__total">
            {t('shipowner.calc_total')}
          </div>
        </Col>
        <Col span={12}>
          <div>
            {currencyMap[currencyFormat].replace(
              ':value',
              currencyFormatter(product),
            )}
          </div>
          <div>
            {currencyMap[currencyFormat].replace(
              ':value',
              currencyFormatter(platformFee),
            )}
          </div>
          <div className="payment-calculation__total">
            {currencyMap[currencyFormat].replace(
              ':value',
              currencyFormatter(total),
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentFee;
