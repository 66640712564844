import { HasuraMetadataResponse } from './hasura';

/** NOTE: values of thie enum aren't type- or runtimechecked, be sure that they
 * are consistent with roles in hasura/metadata/inherited_roles.yaml */
export enum UserRole {
  GUEST = 'guest',
  REGULAR = 'regular',
  KYC_SUBMITTED = 'kyc_submitted',
  SUPER_SHIPOWNER = 'super_shipowner',
  SHIPOWNER = 'shipowner',
  SUPER_SUPPLIER = 'super_supplier',
  SUPPLIER = 'supplier',
  SUPER_SHIPOWNER_SUPPLIER = 'super_shipowner_supplier',
}

export enum VerifyStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  VERIFIED = 'verified',
}

type HasuraRoles = HasuraMetadataResponse['metadata']['inherited_roles'];

export function isReservedRole(r: UserRole) {
  return (
    [UserRole.GUEST, UserRole.KYC_SUBMITTED].includes(r) || r.startsWith('base')
  );
}

export function flattenInheritedRoles(
  roleDef: HasuraRoles[number],
  inheritedRoles: HasuraRoles,
): string[] {
  return roleDef.role_set.flatMap((r) => {
    const ir = inheritedRoles.find((ir) => ir.role_name === r);
    return ir ? flattenInheritedRoles(ir, inheritedRoles) : r;
  });
}

export enum ActivityType {
  SHIPOWNER,
  SUPPLIER,
  SHIPOWNER_SUPPLIER,
}

export function activityType(role: UserRole) {
  switch (role) {
    case UserRole.SHIPOWNER:
    case UserRole.SUPER_SHIPOWNER:
      return ActivityType.SHIPOWNER;
    case UserRole.SUPPLIER:
    case UserRole.SUPER_SUPPLIER:
      return ActivityType.SUPPLIER;
    case UserRole.SUPER_SHIPOWNER_SUPPLIER:
      return ActivityType.SHIPOWNER_SUPPLIER;
    default:
      return;
  }
}
