import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import FormTitle from 'src/components/form-title';

type TemplateLayoutProps = {
  title: string;
  readed?: boolean;
  href?: string;
  description?: string;
  children?: React.ReactNode;
};

const NotificationTemplateLayout: React.FC<TemplateLayoutProps> = ({
  title,
  description,
  children,
  href,
  readed,
}) => {
  const history = useHistory();
  return (
    <div
      className={`container notifications-list__item ${
        readed ? '' : 'notifications-list__item--unread'
      }`}
      onClick={() => {
        if (href) history.push(href);
        // TODO: made notification status readed
      }}
    >
      <FormTitle suffix={false}>
        <>{title}</>
      </FormTitle>
      {description && <Typography>{description}</Typography>}
      {children}
    </div>
  );
};

export default NotificationTemplateLayout;
