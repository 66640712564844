import { Row, RowProps } from 'antd';

type Props = RowProps & React.RefAttributes<HTMLDivElement>;

export const BaseRow = (props: Props) => (
  <Row gutter={{ xs: 12, sm: 24 }} {...props}>
    {props.children}
  </Row>
);

export default BaseRow;
