import { useEffect, useState } from 'react';

import { useTradingInfo } from './use-trading-info';
import { useTradingFilesQuery } from '@gql/trading.urql';

const fileTypes = ['attachments', 'bdn', 'confirmation', 'nomination'] as const;

type FileItem = {
  id: string;
  name: string;
  __typename: 'file';
};

type TradingFile = FileItem & {
  type: typeof fileTypes[number];
};

export const useTradingFiles = () => {
  const [tradingFiles, setTradingFiles] = useState<TradingFile[]>();
  const { orderInfo, tradingId } = useTradingInfo();
  const [{ data, fetching }, reexecuteQuery] = useTradingFilesQuery({
    variables: { id: tradingId },
    pause: !tradingId,
  });

  useEffect(() => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [orderInfo]);

  useEffect(() => {
    const order = data?.trading_order;
    if (order) {
      let files: TradingFile[] = [];
      for (const type of fileTypes) {
        const typeFiles = order[type] as FileItem[];
        if (typeFiles) {
          files = [...files, ...typeFiles.map((file) => ({ ...file, type }))];
        }
      }
      setTradingFiles(files.filter((file) => file.id));
    }
  }, [data, tradingId]);

  return { tradingFiles, fetching, tradingId, data: data?.trading_order };
};
