import { FormItemProps, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormItemChanges, { HistoryFormat } from './form-item-changes';
import { FormLoadingContext } from './form-main';
import { ACCESS_DENIED, ACCESS_WRITE } from 'src/access-checker/access-types';
import { AccessContext } from 'src/access-checker/provider';
import { AccessKey } from 'src/access-checker/types';

export type FItemProps<T> = {
  rules?: Rule[];
  label?: string;
  accessKey?: AccessKey;
  listName?: string[];
  disableHistory?: boolean;
  historyFormat?: HistoryFormat<T>;
  historyNames?: FormItemProps['name'][];
  historySeparator?: React.ReactNode;
} & FormItemProps;

export const FormItem = <T,>({
  rules = [],
  children,
  label,
  accessKey,
  listName,
  disableHistory,
  historyFormat,
  historyNames,
  historySeparator,
  className,
  ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PropsWithChildren<FItemProps<T>>) => {
  const [t] = useTranslation();
  let showRequiredSym = !!props.required;
  const accessState = useContext(AccessContext);
  const { changeHistory } = useContext(FormLoadingContext);

  const modRules: Rule[] = rules.map((rule) => {
    if (typeof rule === 'function') {
      return rule;
    }
    const mod: { message?: string } = {};
    if (rule.required) {
      showRequiredSym = true;
      if (!rule.message && label) {
        mod.message = t('form.errors.required', { label });
      }
    }
    return { ...rule, ...mod };
  });

  const names = historyNames ?? [props.name];

  const modLabelText = label
    ? label + ' ' + (showRequiredSym ? t('form.required_suffix') : '')
    : label;

  const showChanges =
    Array.isArray(changeHistory) &&
    changeHistory.length > 0 &&
    names.length > 0 &&
    !disableHistory;

  const modLabel = showChanges ? (
    <FormItemChanges
      listName={listName}
      names={names}
      format={historyFormat}
      changeHistory={changeHistory}
      separator={historySeparator}
      label={modLabelText}
    />
  ) : (
    modLabelText
  );

  const additionalClassname = showChanges
    ? 'form-item-change-history__form-item'
    : '';
  const classNameMod = `${className ?? ''} ${additionalClassname}`;

  if (accessKey && accessState) {
    if (accessState[accessKey] === ACCESS_DENIED) {
      return null;
    } else if (accessState[accessKey] !== ACCESS_WRITE) {
      const childrenWithProps: FItemProps<T>['children'] = React.Children.map(
        children,
        (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              // @ts-expect-error prop types
              disabled: true,
            });
          }
          return child;
        },
      );

      return (
        <Form.Item
          {...props}
          rules={modRules}
          label={modLabel}
          className={classNameMod}
        >
          {Array.isArray(childrenWithProps)
            ? childrenWithProps[0]
            : childrenWithProps}
        </Form.Item>
      );
    }
  }

  return (
    <Form.Item
      {...props}
      rules={modRules}
      label={modLabel}
      className={classNameMod}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
