import { DownloadOutlined, HistoryOutlined } from '@ant-design/icons';
import { Col, List, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import BaseRow from './base-row';
import { currencyFormatter } from './currency-input';
import { dateFormat } from './date-format';
import FormTitle from './form-title';
import { RFQHistory } from '@common/notifications';
import { OrderStatus } from '@common/trading/status';
import { useDownloadFile } from 'src/hooks';
import { useTradingFiles } from 'src/hooks/use-trading-files';

type Props = {
  changeHistory: RFQHistory[];
  isSupplier?: boolean;
};

const downloadStatuses = {
  [OrderStatus.SUPPLIER_BDN_UPLOADED]: 'bdn',
};

const getStatusI18nKey = (item: RFQHistory, isSupplier?: boolean) => {
  let statusKey: string;
  if (item.assigned_status === OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED) {
    statusKey = isSupplier
      ? 'supplier_final_qty_entered'
      : 'shipowner_final_qty_entered';
  } else {
    statusKey = item.assigned_status ?? '';
  }
  return ('offer_statuses.' + statusKey).replaceAll(':', '_');
};

const OrderHistory: React.FC<Props> = ({ changeHistory, isSupplier }) => {
  const [t] = useTranslation();
  const donwloadFile = useDownloadFile();
  const { data } = useTradingFiles();
  if (!Array.isArray(changeHistory) || !changeHistory.length) return null;

  return (
    <div className="order-history">
      <List
        size="small"
        className="offset-bottom"
        header={
          <div className="full-width">
            <BaseRow justify="space-between">
              <Col>
                <FormTitle suffix={false} className="offset-bottom__none">
                  <>{t('form.order_history')}</>
                </FormTitle>
              </Col>
              <Col>
                <BaseRow>
                  <Col className="order-history__price">
                    <FormTitle suffix={false} className="offset-bottom__none">
                      <Space>
                        <span>{t('form.price')}</span>
                      </Space>
                    </FormTitle>
                  </Col>
                  <Col className="order-history__date">
                    <FormTitle suffix={false} className="offset-bottom__none">
                      <Space>
                        <span>{t('form.order_history_date')}</span>
                        <HistoryOutlined />
                      </Space>
                    </FormTitle>
                  </Col>
                </BaseRow>
              </Col>
            </BaseRow>
          </div>
        }
        bordered
        dataSource={changeHistory}
        renderItem={(item) => {
          const download = downloadStatuses[item.assigned_status ?? ''];
          const [fileItem] = download && data ? data[download] : [];

          return (
            <List.Item key={item.id}>
              <div className="full-width">
                <BaseRow justify="space-between">
                  <Col>
                    <Typography.Text code>
                      {t(getStatusI18nKey(item, isSupplier))}
                    </Typography.Text>
                  </Col>
                  {!!fileItem && (
                    <Col>
                      <Typography.Link
                        title={fileItem.name}
                        onClick={() => {
                          if (fileItem) donwloadFile(fileItem.id);
                        }}
                      >
                        <DownloadOutlined />
                      </Typography.Link>
                    </Col>
                  )}
                  <Col>
                    <BaseRow>
                      <Col className="order-history__price">
                        <Typography.Text>
                          {item.price ? currencyFormatter(item.price) : '-'}
                        </Typography.Text>
                      </Col>
                      <Col className="order-history__date">
                        <Typography.Text>
                          {dateFormat(item.version_updated_at, true)}
                        </Typography.Text>
                      </Col>
                    </BaseRow>
                  </Col>
                </BaseRow>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default OrderHistory;
