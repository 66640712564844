import { Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import BankAccountForm, { BankAccountFormFields } from './bank-account-form';
import BankAccountPinForm, {
  BankAccountPinFormFields,
} from './bank-account-pin-form';
import { AccountAction } from '@common/company-bank-account';
import { useGetProfileInfoQuery } from '@gql/auth.urql';
import {
  useCompanyBankAccountCodeMutation,
  useCompanyBankAccountActionMutation,
} from '@gql/banking.urql';
import FormModal, { FormProps } from 'src/components/form-modal';
import { DEFAULT_MODAL_WIDTH } from 'src/config/styles';

export type BankAccountForm = BankAccountFormFields;
export type BankAccountConfirmForm = BankAccountPinFormFields;
type Props = {
  account?: BankAccountFormFields;
};

const CREATE_MODE = {
  title: 'banking.add_bank_account_modal_title',
  button: 'banking.navigation.add_bank_account',
  ok_button: 'banking.add_bank_account',
  double_title: 'banking.create_account_confirmation_title',
  double_button: 'banking.add_bank_account',
  double_children: 'banking.create_account_confirmation',
};

const EDIT_MODE = {
  title: 'banking.edit_bank_account_modal_title',
  button: 'banking.edit_bank_account',
  ok_button: 'banking.edit_bank_account',
  double_title: 'banking.edit_account_confirmation_title',
  double_button: 'banking.edit_bank_account',
  double_children: 'banking.edit_account_confirmation',
};

export const AddBankAccountModal: React.FC<Props> = ({ account }) => {
  const [form] = Form.useForm<BankAccountFormFields>();
  const [formConfirm] = Form.useForm<BankAccountConfirmForm>();
  const [, accountAction] = useCompanyBankAccountActionMutation();
  const [, generateCode] = useCompanyBankAccountCodeMutation();
  const [{ data: profileInfo, fetching: loading }] = useGetProfileInfoQuery();
  const [t] = useTranslation();
  if (loading) return <Spin />;
  const initialValues = {
    account_holder: profileInfo?.user?.company?.name ?? '',
    ...account,
  };

  const createMode = typeof account === 'undefined';
  const settings = createMode ? CREATE_MODE : EDIT_MODE;

  return (
    <FormModal
      title={t(settings.title)}
      width={DEFAULT_MODAL_WIDTH}
      doubleSubmit={{
        type: 'edit',
        title: t(settings.double_title),
        button: t(settings.double_button),
        buttonType: 'primary',
        children: t(settings.double_children),
        onSubmit: async ({ setVisibility }) => {
          const { data } = await generateCode({
            data: { action: AccountAction.CREATE },
          });
          if (data) setVisibility(true); // next step
          return { status: !!data };
        },
        beforeOpen: (setVisibility) => {
          form.validateFields().then(() => {
            setVisibility(true);
          });
        },
      }}
      doubleSubmitActiveOn={[0]}
      formProps={[
        {
          form,
          initialValues,
          requiredMark: false,
          layout: 'vertical',
          onSubmit: async () => ({ status: true }),
          formNode: <BankAccountForm key="1" />,
        } as FormProps,
        {
          form: formConfirm,
          requiredMark: false,
          layout: 'vertical',
          onSubmit: async ({ setVisibility, confirmation_code }) => {
            const values = form.getFieldsValue();
            const { data } = await accountAction(
              {
                data: {
                  action: AccountAction.CREATE,
                  confirmation_code,
                  account_info: values,
                },
              },
              { additionalTypenames: ['company_bank_account'] },
            );
            if (data) {
              form.resetFields();
              formConfirm.resetFields();
              setVisibility(false);
            }
            return { status: !!data };
          },
          formNode: <BankAccountPinForm key="2" />,
        } as FormProps<BankAccountPinFormFields>,
      ]}
      buttonProps={{
        type: 'primary',
        buttonText: t(settings.button),
      }}
      okButtonText={t(settings.ok_button)}
    ></FormModal>
  );
};

export default AddBankAccountModal;
