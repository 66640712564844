import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { KYC } from '@common/routes';
import { LayoutMain, LAYOUT_MODE_MIXED } from 'src/layouts/main/main';
const KYCRegistration = lazy(() => import('./registration/registration'));

export const KycApp: React.FC = () => (
  <LayoutMain mode={LAYOUT_MODE_MIXED}>
    <Switch>
      <Route path={KYC.APP} component={KYCRegistration} />
    </Switch>
  </LayoutMain>
);

export default KycApp;
