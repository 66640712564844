import { InputNumber, FormItemProps, InputNumberProps } from 'antd';
import { useContext, useEffect, useState } from 'react';

import { FormLoadingContext } from './form-main';
import { getInitialValueByName } from './helpers';

const mod = 1000;

export const volumeFormatter = (
  value,
  info: { userTyping?: boolean } = {},
  currency?: string,
) => {
  const options = currency ? { style: 'currency', currency } : undefined;
  const { userTyping = false } = info;
  if (value) value /= mod;
  const res = new Intl.NumberFormat('ja-JP', options).format(value);
  if (!userTyping) {
    if (!res || res === '0') return '0.000';
    if (res.search(/\./) === -1) return res + '.000';
    else if (res.search(/\.[0-9]{1}$/) !== -1) return res + '00';
    else if (res.search(/\.[0-9]{2}$/) !== -1) return res + '0';
  }
  return res;
};

const createVolumeFormatter = () => volumeFormatter;

type Props = InputNumberProps<number> & {
  formItemName?: FormItemProps['name'];
};

export const VolumeInput: React.FC<Props> = ({
  onChange,
  value,
  defaultValue,
  formItemName,
  min,
  max,
  ...rest
}) => {
  const { initialValues } = useContext(FormLoadingContext);
  let initialValue = defaultValue;
  if (formItemName && initialValues) {
    initialValue = getInitialValueByName(initialValues, formItemName);
  }

  const checValueMinMax = <T extends number | undefined>(value: T): T => {
    if (typeof value === 'undefined') return value;
    const val: number = value; // FIX: value stil can be undefined if no do this
    if (typeof min !== 'undefined' && min > val) return min as T;
    if (typeof max !== 'undefined' && val > max) return max as T;
    return value;
  };

  const [valueLocal, setValueLocal] = useState<number | undefined>(
    checValueMinMax(initialValue),
  );

  const handleChange = (value: number) => {
    const converted = checValueMinMax(value ? value * mod : value);
    setValueLocal(converted);
    onChange?.(converted);
  };

  const handleStep = (_value, { type }) => {
    const nextValue = checValueMinMax(
      (valueLocal ?? 0) + mod * (type === 'up' ? 1 : -1),
    );
    setValueLocal(nextValue);
    onChange?.(nextValue);
  };

  useEffect(() => {
    setValueLocal(value);
  }, [value]);

  return (
    <InputNumber
      value={valueLocal}
      onChange={handleChange}
      formatter={createVolumeFormatter()}
      defaultValue={defaultValue}
      onStep={handleStep}
      step={mod}
      {...rest}
    />
  );
};

export default VolumeInput;
