import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

import { ExtendRfqModalForm, ExtendRfqModalProps } from './types';
import { useShipownerOrderActionMutation } from '@common/__generated__/trading.urql';
import { ShipownerAction } from '@common/trading/status';
import FormItem from 'src/components/form-item';
import FormModal from 'src/components/form-modal';
import { SMALL_MODAL_WIDTH } from 'src/config/styles';

export const ExtendRFQModal: React.FC<ExtendRfqModalProps> = ({
  id,
  disabled,
}) => {
  const [t] = useTranslation();
  const [, sendShipownerAction] = useShipownerOrderActionMutation();

  if (!id) {
    return null;
  }
  return (
    <FormModal
      title={t('shipowner.modal_extend_rfq')}
      width={SMALL_MODAL_WIDTH}
      formProps={{
        requiredMark: false,
        layout: 'vertical',
        initialValues: { new_validity_period: 24 },
        onSubmit: async ({
          setVisibility,
          new_validity_period,
        }: ExtendRfqModalForm) => {
          const { data } = await sendShipownerAction({
            data: {
              order_id: id,
              action: ShipownerAction.EXTEND_RFQ,
              new_validity_period,
            },
          });
          if (data) {
            setVisibility(false);
            return { status: true };
          }
          return { status: false };
        },
      }}
      buttonProps={{
        disabled,
        type: 'primary',
        buttonText: t('shipowner.extend_rfq'),
      }}
    >
      <div className="container padding-bottom">
        <FormItem
          label={t('shipowner.form.hours')}
          name="new_validity_period"
          rules={[
            {
              required: true,
              message: t('shipowner.errors.new_validity_period_required'),
            },
          ]}
        >
          <InputNumber min={1} className="full-width" />
        </FormItem>
      </div>
    </FormModal>
  );
};

export default ExtendRFQModal;
