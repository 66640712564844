import { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './styles/index.less';
import { App } from './app';

ReactDOM.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
