import { useEffect, useState } from 'react';

import { useTradingInfo } from './use-trading-info';
import { RFQHistory } from '@common/notifications';
import { useTradingHistoryQuery } from '@gql/trading.urql';
import { groupChangesByRfqFormFields } from 'src/components/helpers';

export const useTradingHistory = () => {
  const [changeHistory, setChangeHistory] = useState<RFQHistory[]>([]);
  const {
    orderInfo,
    tradingId,
    fetching: fetchingTradingOrder,
  } = useTradingInfo();
  const [{ data: tradingHistoryData, fetching }, reexecuteQuery] =
    useTradingHistoryQuery({
      variables: { id: tradingId },
      pause: !tradingId,
    });

  useEffect(() => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo) {
      setChangeHistory(
        groupChangesByRfqFormFields(
          orderInfo,
          tradingHistoryData?.trading_order,
        ),
      );
    }
  }, [tradingHistoryData]);

  return {
    fetching: fetchingTradingOrder || fetching,
    orderInfo,
    tradingId,
    changeHistory,
  };
};
