import { createContext, useReducer } from 'react';

import { actionSetNotifications } from './actions';
import notificationsContextReducer, { defaultState } from './reducer';
import { Subscription } from './subscription';
import { useAuthSubscription } from 'src/hooks';

export const NotificationContext = createContext(defaultState);

export const NotoficationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    notificationsContextReducer,
    defaultState,
  );
  const { userId } = useAuthSubscription();

  return (
    <NotificationContext.Provider value={state}>
      {userId && (
        <Subscription
          key={userId}
          userId={userId}
          onChange={(data) => {
            // console.log('actionSetNotifications', data);
            dispatch(actionSetNotifications(data));
          }}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotoficationsProvider;
