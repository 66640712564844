import { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { BANKING } from '@common/routes';
import { PrivateRoute } from 'src/components/private-route';
import LayoutBanking from 'src/layouts/banking/banking';
import { LayoutMain } from 'src/layouts/main/main';

const BalancePage = lazy(() => import('./balance'));
const BankAccountsPage = lazy(() => import('./bank-accounts'));

export const BankingApp: React.FC = () => (
  <LayoutMain>
    <LayoutBanking>
      <Switch>
        <PrivateRoute
          accessProps={{
            accessKey: 'banking.your-balance',
            show403: true,
          }}
          path={BANKING.INTERNAL_TRANSACTIONS}
          component={BalancePage}
        />
        <PrivateRoute
          accessProps={{
            accessKey: 'banking.your-balance',
            show403: true,
          }}
          path={BANKING.EXTERNAL_TRANSACTIONS}
          component={BalancePage}
        />
        <PrivateRoute
          accessProps={{
            accessKey: 'banking.bank-accounts',
            show403: true,
          }}
          path={BANKING.ACCOUNTS}
          component={BankAccountsPage}
        />
        <Redirect to={BANKING.INTERNAL_TRANSACTIONS} />
      </Switch>
    </LayoutBanking>
  </LayoutMain>
);

export default BankingApp;
