import { BellOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '@common/notifications';
import { PROFILE } from '@common/routes';
import { passwordExpiringCheck } from 'src/components/helpers';
import { useAuth } from 'src/hooks';
import { NotificationContext } from 'src/notifications/provider';
import templates from 'src/notifications/templates';

export const Notifications: React.FC = () => {
  const { notifications, unread } = useContext(NotificationContext);
  const { profileInfo } = useAuth();
  // TODO: Get last password change date
  const lastChange = '2022-04-03T11:07:51.876+00:00';
  const { expiring, daysLeft } = passwordExpiringCheck(lastChange);
  const [t] = useTranslation();

  return (
    <Dropdown
      overlay={
        <div className="container--white notifications-list">
          {expiring && (
            <div>
              {templates.text({
                text: t('notification.password_change_attention', {
                  left: daysLeft,
                }),
                href: PROFILE.USER_PROFILE_CHANGE_PASSWORD,
                type: NotificationType.TEXT,
                title: t('notification.password_change_attention_title'),
              })}
            </div>
          )}
          {notifications.map((notification, index) => {
            const template = templates[notification.type] ?? templates.default;
            return <div key={index}>{template(notification as never)}</div>;
          })}
        </div>
      }
      placement="bottom"
    >
      <div className={expiring || unread ? 'notifications-unread-icon' : ''}>
        <BellOutlined className={`header-notification-bell header-menu-icon`} />
      </div>
    </Dropdown>
  );
};

export default Notifications;
