import * as Types from './types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderProductFragment = { __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } };

export type ShipownerOrderTableFragment = { __typename?: 'trading_order', assigned_status: string, expires_at?: string, additional: Record<string, any>, eta: string, etd: string, id: string, shortId: string, price?: number, status?: string, updated_at?: string, isSupplierOffer?: boolean, port?: { __typename?: 'ship_port', name: string, id: string }, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } }>, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, vessel?: { __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', imo?: string, mmsi: string, country_code?: string, additional: Record<string, any>, id: string, name: string } } };

export type ShipownerAllOffersSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type ShipownerAllOffersSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'trading_order', assigned_status: string, expires_at?: string, additional: Record<string, any>, eta: string, etd: string, id: string, shortId: string, price?: number, status?: string, updated_at?: string, isSupplierOffer?: boolean, port?: { __typename?: 'ship_port', name: string, id: string }, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } }>, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, vessel?: { __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', imo?: string, mmsi: string, country_code?: string, additional: Record<string, any>, id: string, name: string } } }> };

export type ShipownerRfqsSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type ShipownerRfqsSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'trading_order', assigned_status: string, expires_at?: string, additional: Record<string, any>, eta: string, etd: string, id: string, shortId: string, price?: number, status?: string, updated_at?: string, isSupplierOffer?: boolean, port?: { __typename?: 'ship_port', name: string, id: string }, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } }>, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, vessel?: { __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', imo?: string, mmsi: string, country_code?: string, additional: Record<string, any>, id: string, name: string } } }> };

export type SupplierOrdersSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type SupplierOrdersSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'trading_order', assigned_status: string, expires_at?: string, additional: Record<string, any>, eta: string, etd: string, id: string, shortId: string, price?: number, status?: string, updated_at?: string, isSupplierOffer?: boolean, port?: { __typename?: 'ship_port', name: string, id: string }, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } }>, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, vessel?: { __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', imo?: string, mmsi: string, country_code?: string, additional: Record<string, any>, id: string, name: string } } }> };

export type TradingOrderInfoSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type TradingOrderInfoSubscription = { __typename?: 'subscription_root', trading_order?: { __typename?: 'trading_order', assigned_status: string, additional: Record<string, any>, expires_at?: string, eta: string, etd: string, currency_id: string, company_vessel_id?: string, id: string, shortId: string, price?: number, status?: string, updated_at?: string, isCancellable?: boolean, port?: { __typename?: 'ship_port', name: string, id: string }, createdBy?: { __typename?: 'user', email: string }, products: Array<{ __typename?: 'trading_order_product', volume_from: number, volume_to: number, measure: string, price?: number, admin_adjusted_volume?: number, id: string, final_qty?: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, shortName: string, fullName: string } }>, bdnDocument?: Array<{ __typename?: 'file', id: string, fileName?: string, mimeType?: string }> } };

export type CreateShipownerRequestMutationVariables = Types.Exact<{
  data: Types.ShipownerRequestInput;
}>;


export type CreateShipownerRequestMutation = { __typename?: 'mutation_root', create_shipowner_request: { __typename?: 'DummyOutput', ok: boolean } };

export type CreateSupplierOfferMutationVariables = Types.Exact<{
  data: Types.SupplierOfferInput;
}>;


export type CreateSupplierOfferMutation = { __typename?: 'mutation_root', create_supplier_offer: { __typename?: 'CreateSupplierOfferOutput', order_id: string } };

export type SupplierOrderActionMutationVariables = Types.Exact<{
  data: Types.SupplierOrderAction;
}>;


export type SupplierOrderActionMutation = { __typename?: 'mutation_root', send_supplier_order_action: { __typename?: 'SupplierOrderActionOutput', order_id?: string } };

export type ShipownerOrderActionMutationVariables = Types.Exact<{
  data: Types.ShipownerOrderAction;
}>;


export type ShipownerOrderActionMutation = { __typename?: 'mutation_root', send_shipowner_order_action: { __typename?: 'DummyOutput', ok: boolean, description?: string } };

export type TradingFormMetaQueryVariables = Types.Exact<{
  vessel_filter?: Types.InputMaybe<Types.CompanyVesselBoolExp>;
}>;


export type TradingFormMetaQuery = { __typename?: 'query_root', ports: Array<{ __typename?: 'ship_port', name: string, active: boolean, id: string, countryCode: string }>, currency: Array<{ __typename?: 'currency', active: boolean, id: string, code: string }>, vessel: Array<{ __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', mmsi: string, imo?: string, additional: Record<string, any>, name: string, countryCode?: string } }>, product: Array<{ __typename?: 'product_meta', additional: Record<string, any>, active: boolean, id: string, shortName: string, fullName: string }>, measureUnit: Array<{ __typename?: 'measure_unit_meta', name: string, description?: string, active: boolean, id: string }> };

export type TradingHistoryQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type TradingHistoryQuery = { __typename?: 'query_root', trading_order?: { __typename?: 'trading_order', orderHistory?: Array<{ __typename?: 'trading_order_versionlog_view', additional?: Record<string, any>, assigned_status?: string, company_vessel_id?: string, created_by_user_id?: string, currency_id?: string, eta?: string, etd?: string, expires_at?: string, ship_port_id?: string, shipowner_request_order_id?: string, short_id?: string, trading_order_id?: string, version_additional?: Record<string, any>, version_is_deleted?: boolean, version_updated_at?: string, version_updated_by?: string, price?: number }>, productHistory?: Array<{ __typename?: 'trading_order_product_versionlog_view', additional?: Record<string, any>, adjusted_volume?: number, measure?: string, price?: number, product_meta_id?: string, trading_order_id?: string, updated_at?: string, version_additional?: Record<string, any>, version_is_deleted?: boolean, version_updated_at?: string, version_updated_by?: string, volume_from?: number, volume_to?: number }> } };

export type TradingFilesQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type TradingFilesQuery = { __typename?: 'query_root', trading_order?: { __typename?: 'trading_order', bdn?: Array<{ __typename?: 'file', id: string, name?: string }>, attachments?: Array<{ __typename?: 'file', id: string, name?: string }>, nomination?: Array<{ __typename?: 'file', id: string, name?: string }>, confirmation?: Array<{ __typename?: 'file', id: string, name?: string }> } };

export const OrderProductFragmentDoc = gql`
    fragment OrderProduct on trading_order_product {
  id: product_meta_id
  meta: product_meta_rel {
    shortName: short_name
    fullName: full_name
    additional
  }
  volume_from
  volume_to
  measure
  price
  final_qty: adjusted_volume
  admin_adjusted_volume
}
    `;
export const ShipownerOrderTableFragmentDoc = gql`
    fragment ShipownerOrderTable on trading_order {
  id: trading_order_id
  shortId: short_id
  price: total_price_com
  port: ship_port_rel {
    name
    id: ship_port_id
  }
  status: status_com
  assigned_status
  updated_at: updated_at_com
  expires_at
  isSupplierOffer: is_supplier_offer_com
  additional
  products: products_rel {
    ...OrderProduct
  }
  eta
  etd
  shipowner: shipowner_com {
    company: company_rel {
      name: company_name
    }
  }
  supplier: supplier_com {
    company: company_rel {
      name: company_name
    }
  }
  vessel: company_vessel_rel {
    id: company_vessel_id
    meta: meta_rel {
      id: vessel_meta_id
      name: ship_name
      imo
      mmsi
      country_code
      additional
    }
  }
}
    ${OrderProductFragmentDoc}`;
export const ShipownerAllOffersDocument = gql`
    subscription ShipownerAllOffers {
  order: shipowner_all_offers_fun {
    ...ShipownerOrderTable
  }
}
    ${ShipownerOrderTableFragmentDoc}`;

export function useShipownerAllOffersSubscription<TData = ShipownerAllOffersSubscription>(options: Omit<Urql.UseSubscriptionArgs<ShipownerAllOffersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ShipownerAllOffersSubscription, TData>) {
  return Urql.useSubscription<ShipownerAllOffersSubscription, TData, ShipownerAllOffersSubscriptionVariables>({ query: ShipownerAllOffersDocument, ...options }, handler);
};
export const ShipownerRfqsDocument = gql`
    subscription ShipownerRfqs {
  order: shipowner_my_rfqs_fun {
    ...ShipownerOrderTable
  }
}
    ${ShipownerOrderTableFragmentDoc}`;

export function useShipownerRfqsSubscription<TData = ShipownerRfqsSubscription>(options: Omit<Urql.UseSubscriptionArgs<ShipownerRfqsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ShipownerRfqsSubscription, TData>) {
  return Urql.useSubscription<ShipownerRfqsSubscription, TData, ShipownerRfqsSubscriptionVariables>({ query: ShipownerRfqsDocument, ...options }, handler);
};
export const SupplierOrdersDocument = gql`
    subscription SupplierOrders {
  order: supplier_orders_fun {
    ...ShipownerOrderTable
  }
}
    ${ShipownerOrderTableFragmentDoc}`;

export function useSupplierOrdersSubscription<TData = SupplierOrdersSubscription>(options: Omit<Urql.UseSubscriptionArgs<SupplierOrdersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<SupplierOrdersSubscription, TData>) {
  return Urql.useSubscription<SupplierOrdersSubscription, TData, SupplierOrdersSubscriptionVariables>({ query: SupplierOrdersDocument, ...options }, handler);
};
export const TradingOrderInfoDocument = gql`
    subscription TradingOrderInfo($id: uuid!) {
  trading_order: trading_order_by_pk(trading_order_id: $id) {
    id: trading_order_id
    shortId: short_id
    price: total_price_com
    port: ship_port_rel {
      id: ship_port_id
      name
    }
    createdBy: created_by_rel {
      email
    }
    status: status_com
    assigned_status
    updated_at: updated_at_com
    additional
    products: products_rel {
      ...OrderProduct
    }
    expires_at
    eta
    etd
    currency_id
    company_vessel_id
    bdnDocument: bdn_document_com {
      id: file_id
      fileName: file_name
      mimeType: mime_type
    }
    isCancellable: is_cancellable_com
  }
}
    ${OrderProductFragmentDoc}`;

export function useTradingOrderInfoSubscription<TData = TradingOrderInfoSubscription>(options: Omit<Urql.UseSubscriptionArgs<TradingOrderInfoSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TradingOrderInfoSubscription, TData>) {
  return Urql.useSubscription<TradingOrderInfoSubscription, TData, TradingOrderInfoSubscriptionVariables>({ query: TradingOrderInfoDocument, ...options }, handler);
};
export const CreateShipownerRequestDocument = gql`
    mutation CreateShipownerRequest($data: ShipownerRequestInput!) {
  create_shipowner_request(data: $data) {
    ok
  }
}
    `;

export function useCreateShipownerRequestMutation() {
  return Urql.useMutation<CreateShipownerRequestMutation, CreateShipownerRequestMutationVariables>(CreateShipownerRequestDocument);
};
export const CreateSupplierOfferDocument = gql`
    mutation CreateSupplierOffer($data: SupplierOfferInput!) {
  create_supplier_offer(data: $data) {
    order_id
  }
}
    `;

export function useCreateSupplierOfferMutation() {
  return Urql.useMutation<CreateSupplierOfferMutation, CreateSupplierOfferMutationVariables>(CreateSupplierOfferDocument);
};
export const SupplierOrderActionDocument = gql`
    mutation SupplierOrderAction($data: SupplierOrderAction!) {
  send_supplier_order_action(data: $data) {
    order_id
  }
}
    `;

export function useSupplierOrderActionMutation() {
  return Urql.useMutation<SupplierOrderActionMutation, SupplierOrderActionMutationVariables>(SupplierOrderActionDocument);
};
export const ShipownerOrderActionDocument = gql`
    mutation ShipownerOrderAction($data: ShipownerOrderAction!) {
  send_shipowner_order_action(data: $data) {
    ok
    description
  }
}
    `;

export function useShipownerOrderActionMutation() {
  return Urql.useMutation<ShipownerOrderActionMutation, ShipownerOrderActionMutationVariables>(ShipownerOrderActionDocument);
};
export const TradingFormMetaDocument = gql`
    query TradingFormMeta($vessel_filter: company_vessel_bool_exp) {
  ports: ship_port {
    id: ship_port_id
    name
    countryCode: country_code
    active
  }
  currency {
    id: currency_id
    code: currency_code
    active
  }
  vessel: company_vessel(where: $vessel_filter) {
    id: company_vessel_id
    meta: meta_rel {
      name: ship_name
      mmsi
      countryCode: country_code
      imo
      additional
    }
  }
  product: product_meta {
    id: product_meta_id
    shortName: short_name
    fullName: full_name
    additional
    active
  }
  measureUnit: measure_unit_meta {
    id: measure_unit_meta_id
    name
    description
    active
  }
}
    `;

export function useTradingFormMetaQuery(options?: Omit<Urql.UseQueryArgs<TradingFormMetaQueryVariables>, 'query'>) {
  return Urql.useQuery<TradingFormMetaQuery>({ query: TradingFormMetaDocument, ...options });
};
export const TradingHistoryDocument = gql`
    query TradingHistory($id: uuid!) {
  trading_order: trading_order_by_pk(trading_order_id: $id) {
    orderHistory: order_history_com(args: {}) {
      additional
      assigned_status
      company_vessel_id
      created_by_user_id
      currency_id
      eta
      etd
      expires_at
      ship_port_id
      shipowner_request_order_id
      short_id
      trading_order_id
      version_additional
      version_is_deleted
      version_updated_at
      version_updated_by
      price: total_price_com
    }
    productHistory: product_history_com(args: {}) {
      additional
      adjusted_volume
      measure
      price
      product_meta_id
      trading_order_id
      updated_at
      version_additional
      version_is_deleted
      version_updated_at
      version_updated_by
      volume_from
      volume_to
    }
  }
}
    `;

export function useTradingHistoryQuery(options: Omit<Urql.UseQueryArgs<TradingHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<TradingHistoryQuery>({ query: TradingHistoryDocument, ...options });
};
export const TradingFilesDocument = gql`
    query TradingFiles($id: uuid!) {
  trading_order: trading_order_by_pk(trading_order_id: $id) {
    bdn: bdn_document_com {
      id: file_id
      name: file_name
    }
    attachments: attachments_com {
      id: file_id
      name: file_name
    }
    nomination: nomination_document_com {
      id: file_id
      name: file_name
    }
    confirmation: confirmation_document_com {
      id: file_id
      name: file_name
    }
  }
}
    `;

export function useTradingFilesQuery(options: Omit<Urql.UseQueryArgs<TradingFilesQueryVariables>, 'query'>) {
  return Urql.useQuery<TradingFilesQuery>({ query: TradingFilesDocument, ...options });
};