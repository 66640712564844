import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthState } from '../graphql/cookie-auth';
import { AUTHORIZED_HOME } from '@common/routes';
import { useAuthSubscription } from 'src/hooks';

export type TopRouteLocationState = { from?: Location } | undefined;

interface PrivateRouteProps extends RouteProps {
  component: React.FC;
}

export const PublicRoute: React.FC<PrivateRouteProps> = ({
  component,
  ...rest
}) => {
  const auth = useAuthSubscription();
  const Component = component;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth.authState !== AuthState.AUTHORIZED) {
          return <Component />;
        }
        const to = (location.state as TopRouteLocationState)?.from ?? {
          pathname: AUTHORIZED_HOME,
          state: { from: location },
        };
        return <Redirect to={to} />;
      }}
    />
  );
};
