import { useEffect } from 'react';

import {
  NotificationsSubscription,
  useNotificationsSubscription,
} from '@gql/auth.urql';

type Props = {
  userId: string;
  onChange: (data: NotificationsSubscription['user_notification']) => void;
};

export const Subscription: React.FC<Props> = ({ userId, onChange }) => {
  const [{ data }] = useNotificationsSubscription();

  useEffect(() => {
    if (userId && data) {
      onChange(data.user_notification);
    }
  }, [userId, data]);
  return <></>;
};

export default Subscription;
