import { Button, Modal, ModalProps, ButtonProps, Row, Col } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal, {
  ConfirmationModalProps,
} from './confirmation-modal';
import FormFooter from './form-footer';
import { FormMain, FormMainProps, OnSubmitType } from './form-main';
import { ButtonSubmit, ButtonSubmitProps } from './form-main-submit';

type ButtonPropsType = ButtonProps & {
  buttonText: React.ReactNode;
};

export type ButtonPropsTypeMod = ButtonPropsType & {
  beforeOpen?: (setIsModalVisible: (visible: boolean) => void) => void;
};

type SetVisibilityCb = (visibility: boolean) => void;
export type FormProps<T = any> = Omit<FormMainProps<T>, 'onSubmit'> & {
  formNode?: React.ReactNode;
  onSubmit: OnSubmitType<T & { setVisibility: SetVisibilityCb }>;
};

type FormModalProps = ModalProps & {
  buttonProps?: ButtonPropsTypeMod;
  formProps: FormProps | FormProps[];
  okButtonText?: string;
  cancelButtonText?: string;
  key?: string;
  onActiveFormChange?: (form: number) => void;
  doubleSubmit?: ConfirmationModalProps;
  doubleSubmitActiveOn?: number[];
};

export const FormModal: React.FC<FormModalProps> = ({
  children,
  footer,
  buttonProps,
  formProps,
  visible,
  onCancel,
  cancelButtonProps,
  okButtonProps,
  okButtonText,
  cancelButtonText,
  onActiveFormChange,
  doubleSubmit,
  doubleSubmitActiveOn = [],
  ...rest
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [t] = useTranslation();
  const [activeForm, setActiveForm] = useState(0);
  const handleChangeActiveForm = (nextVal: number) => {
    setActiveForm(nextVal);
    onActiveFormChange && onActiveFormChange(nextVal);
  };
  const renderButton = (allProps: ButtonPropsTypeMod) => {
    const { buttonText, beforeOpen, ...restProps } = allProps;
    return (
      <Button
        {...restProps}
        onClick={() => {
          if (typeof beforeOpen !== 'function') {
            setIsModalVisible(true);
            return;
          }
          beforeOpen(setIsModalVisible);
        }}
      >
        {buttonText}
      </Button>
    );
  };

  const useButton = typeof buttonProps !== 'undefined';

  const handleCancel = (e?) => {
    if (useButton) setIsModalVisible(false);
    onCancel && onCancel(e);
  };

  const handlePrevForm = (e?) => {
    if (activeForm === 0) {
      handleCancel();
    } else {
      handleChangeActiveForm(activeForm - 1);
    }
  };

  const handleNextForm = (e?) => {
    const next = Array.isArray(formProps) && activeForm < formProps.length - 1;
    if (next) handleChangeActiveForm(activeForm + 1);
    return next;
  };

  const setVisibilitySetter = (visibility: boolean) => {
    handleNextForm();
    if (!visibility) handleChangeActiveForm(0);
    setIsModalVisible(visibility);
  };

  const renderForm = (
    { onSubmit, formNode = '', ...restProps }: FormProps,
    key: number,
  ) => (
    <div key={key} className={activeForm === key ? '' : 'hide'}>
      <FormMain
        {...restProps}
        onSubmit={(values) => {
          if (typeof onSubmit === 'undefined') {
            const next = handleNextForm();
            if (!next) handleCancel();
            return { status: true };
          }
          return onSubmit({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(values as any),
            setVisibility: setVisibilitySetter,
          });
        }}
      >
        {formNode}
        {children}
        {footer !== false && (
          <FormFooter>
            {footer || (
              <Row gutter={8} justify="end">
                <Col>
                  <Button
                    {...cancelButtonProps}
                    key="cancel"
                    onClick={handlePrevForm}
                    htmlType="button"
                  >
                    {cancelButtonText ?? t('shipowner.modal_cancel')}
                  </Button>
                </Col>
                <Col>
                  {doubleSubmit && doubleSubmitActiveOn.includes(activeForm) ? (
                    <ConfirmationModal
                      {...doubleSubmit}
                      onSubmit={({ setVisibility, ...rest }) => {
                        setVisibility(false);
                        doubleSubmit.onSubmit({
                          ...rest,
                          setVisibility: setVisibilitySetter,
                        });
                        return Promise.resolve({ status: true });
                      }}
                    />
                  ) : (
                    <ButtonSubmit
                      {...(okButtonProps as ButtonSubmitProps)}
                      key="ok"
                    >
                      {okButtonText ?? t('shipowner.modal_confirm')}
                    </ButtonSubmit>
                  )}
                </Col>
              </Row>
            )}
          </FormFooter>
        )}
      </FormMain>
    </div>
  );

  return (
    <>
      {useButton && renderButton(buttonProps)}
      <Modal
        {...rest}
        footer={false}
        visible={useButton ? isModalVisible : visible}
        onCancel={handleCancel}
        className={`ant-modal__no-container ${
          !rest.title ? 'ant-modal__no-title' : ''
        }`}
      >
        {Array.isArray(formProps)
          ? formProps.map((pr, key) => renderForm(pr, key))
          : renderForm(formProps, 0)}
      </Modal>
    </>
  );
};

export default FormModal;
