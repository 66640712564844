import { Spin } from 'antd';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthState } from '../graphql/cookie-auth';
import { useReconnectOnRoleChange } from '../hooks/reconnect-on-role-change';
import { ACCOUNT } from '@common/routes';
import Access, { AccessProps } from 'src/access-checker/access';
import { authContext, useAuthSubscription } from 'src/hooks';
import { useChatwoot } from 'src/hooks/chatwoot';

interface PrivateRouteProps extends RouteProps {
  component: React.FC;
  accessProps?: AccessProps; // for admin pages
  roles?: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  accessProps,
  ...rest
}) => {
  const auth = useAuthSubscription();
  useReconnectOnRoleChange();
  useChatwoot(auth.profileInfo);

  if (auth.authState === AuthState.AUTHORIZING) {
    return <Spin />;
  }

  const Component = component;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          auth.authState === AuthState.AUTHORIZED ||
          auth.authState === AuthState.REFRESHING
        ) {
          const inner = accessProps ? (
            <>
              <Access {...accessProps}>
                <Component />
              </Access>
              {auth.authState === AuthState.REFRESHING && <Spin />}
            </>
          ) : (
            <Component />
          );
          return (
            <authContext.Provider value={auth}>{inner}</authContext.Provider>
          );
        }
        const to = {
          pathname:
            auth.authState === AuthState.INVALID_JWT
              ? ACCOUNT.EXPIRED
              : ACCOUNT.LOGIN,
          state: { from: location },
        };
        return <Redirect to={to} />;
      }}
    />
  );
};
