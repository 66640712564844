import { useEffect } from 'react';

import { type CheckAuthSubscription } from '@gql/auth.urql';

type ProfileUser = CheckAuthSubscription['user'];

/**
 * Keep chatwoot state across all useChatwoot hook calls
 * to prevent redundant `setUser` calls
 */
let didSetChatwootUser: boolean | undefined = undefined;

export function useChatwoot(user: ProfileUser | undefined) {
  useEffect(() => {
    function onChatwootLoad(this: Window) {
      const identifierHash = user?.jwtAuths[0]?.chatwoot_identifier_hash;
      if (identifierHash && didSetChatwootUser !== true) {
        this.$chatwoot?.setUser(user.id, {
          email: user.email,
          identifier_hash: identifierHash,
        });
        didSetChatwootUser = true;
      } else if (!identifierHash && didSetChatwootUser !== false) {
        this.$chatwoot?.reset();
        didSetChatwootUser = false;
      }
    }
    if (window.$chatwoot?.hasLoaded) {
      onChatwootLoad.call(window);
    } else {
      window.addEventListener('chatwoot:ready', onChatwootLoad);
      return () => {
        window.removeEventListener('chatwoot:ready', onChatwootLoad);
      };
    }
  }, [user?.id]);
}
