import { useEffect, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { ADMIN, SHIPOWNERS, SUPPLIERS } from '@common/routes';

const tradingIdContainPages = [
  SHIPOWNERS.ALL_OFFERS_DETAILS,
  SHIPOWNERS.MANAGE_REQUEST_DETAILS,
  SUPPLIERS.RFQ,
  ADMIN.OFFERS_INFO,
  ADMIN.OFFERS_VIEW,
];

export const useTradingId = () => {
  const [tradingId, setTradingId] = useState('');
  const history = useHistory();

  useEffect(() => {
    for (const pagePath of tradingIdContainPages) {
      const match = matchPath<{ id: string }>(history.location.pathname, {
        path: pagePath,
        exact: true,
      });

      if (match) {
        setTradingId(match.params.id);
        break;
      }
    }
  });

  return { tradingId };
};
