import { StatusedOrder } from './frontend';
import { OrderColors, OrderStatus } from './status';

enum RFQStatuses {
  REQUEST = 'request',
  CANCELED = 'cancelled',
  ORDERED = 'ordered',
  WAITING_FOR_DELIVERY = 'waiting_for_delivery',
  DELIVERED = 'delivered',
  INVOICED = 'invoiced',
  PAID = 'paid',
  EXPIRED = 'expired',
}

// Shipowner: Status on button for shipowner ("Manage request")
const statusToShipownerManageRequestLabel: {
  [k in OrderStatus]?: RFQStatuses;
} = {
  [OrderStatus.NEW]: RFQStatuses.REQUEST,
  [OrderStatus.SHIPOWNER_CANCEL]: RFQStatuses.CANCELED,
  [OrderStatus.SHIPOWNER_EXTENDED_RFQ]: RFQStatuses.REQUEST,
  [OrderStatus.SUPPLIER_REJECTED]: RFQStatuses.REQUEST,
  [OrderStatus.SUPPLIER_SENT_OFFER]: RFQStatuses.REQUEST,
  [OrderStatus.SUPPLIER_AMENDED]: RFQStatuses.REQUEST,
  [OrderStatus.SHIPOWNER_DECLINED]: RFQStatuses.REQUEST,
  [OrderStatus.SHIPOWNER_SENT_COUNTER_OFFER]: RFQStatuses.REQUEST,
  [OrderStatus.SHIPOWNER_ACCEPTED]: RFQStatuses.ORDERED,
  // expired: skipped
  [OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED]: RFQStatuses.WAITING_FOR_DELIVERY,
  [OrderStatus.SHIPOWNER_FINAL_QTY_MODIFIED]: RFQStatuses.WAITING_FOR_DELIVERY,
  [OrderStatus.SUPPLIER_BDN_UPLOADED]: RFQStatuses.DELIVERED,
  [OrderStatus.INVOICED]: RFQStatuses.INVOICED,
  [OrderStatus.PAID]: RFQStatuses.PAID,
};

const statusToColor: { [s in RFQStatuses]?: OrderColors } = {
  [RFQStatuses.CANCELED]: OrderColors.DANGER,
  [RFQStatuses.EXPIRED]: OrderColors.DANGER,
  [RFQStatuses.ORDERED]: OrderColors.SUCCESS,
  [RFQStatuses.WAITING_FOR_DELIVERY]: OrderColors.SUCCESS,
  [RFQStatuses.DELIVERED]: OrderColors.SUCCESS,
  [RFQStatuses.INVOICED]: OrderColors.SUCCESS,
  [RFQStatuses.PAID]: OrderColors.DONE,
};

export function getShipownerManageRequestLabel(order: {
  status?: string;
  isSupplierOffer?: boolean;
}): string | undefined {
  if (!order.status) return;
  const prefix = 'order_status.rfq.shipowner.';
  if (order.status === 'expired') {
    return prefix + (order.isSupplierOffer ? 'request' : 'expired');
  }
  return prefix + statusToShipownerManageRequestLabel[order.status];
}

export function getShipownerRFQStatusColor(order: StatusedOrder): OrderColors {
  if (!order.status) return OrderColors.WARNING;
  const offerStatus = statusToShipownerManageRequestLabel[order.status];
  if (!offerStatus) return OrderColors.WARNING;
  return statusToColor[offerStatus] ?? OrderColors.WARNING;
}

export const onlyValidStatusesForShipownerRFQ: OrderStatus[] = [
  OrderStatus.NEW, // request
  OrderStatus.SHIPOWNER_EXTENDED_RFQ, // request
  OrderStatus.SUPPLIER_REJECTED, // request
  OrderStatus.SUPPLIER_SENT_OFFER, // request
  OrderStatus.SUPPLIER_AMENDED, // request
  OrderStatus.SHIPOWNER_DECLINED, // request
  OrderStatus.SHIPOWNER_SENT_COUNTER_OFFER, // request
  OrderStatus.SHIPOWNER_ACCEPTED, // ordered
  OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED, // ordered
  OrderStatus.SHIPOWNER_FINAL_QTY_MODIFIED, // ordered
  OrderStatus.SUPPLIER_BDN_UPLOADED, // delivered
  OrderStatus.INVOICED, //invoiced
];

export const shipownerManageRequestStatusRequest: OrderStatus[] = [
  // Request
  OrderStatus.NEW,
  OrderStatus.SHIPOWNER_EXTENDED_RFQ,
  OrderStatus.SUPPLIER_REJECTED,
  OrderStatus.SUPPLIER_SENT_OFFER,
  OrderStatus.SUPPLIER_AMENDED,
  OrderStatus.SHIPOWNER_DECLINED,
  OrderStatus.SHIPOWNER_SENT_COUNTER_OFFER,
];

// Request, Ordered, Waiting for delivery, Delivered, Invoiced
export const shipownerActiveManageRequestStatuses: OrderStatus[] = [
  // Request
  ...shipownerManageRequestStatusRequest,
  // Request end
  OrderStatus.SHIPOWNER_ACCEPTED,
  OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED,
  OrderStatus.SHIPOWNER_FINAL_QTY_MODIFIED,
  OrderStatus.SUPPLIER_BDN_UPLOADED,
  OrderStatus.INVOICED,
];

export const showDocumentsModalForShipownerManageRequest: OrderStatus[] = [
  OrderStatus.SHIPOWNER_ACCEPTED,
  OrderStatus.EXPIRED,
  OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED,
  OrderStatus.SHIPOWNER_FINAL_QTY_MODIFIED,
  OrderStatus.SUPPLIER_BDN_UPLOADED,
  OrderStatus.INVOICED,
  OrderStatus.PAID,
];
