import { DeleteOutlined } from '@ant-design/icons';
import { Col, Input, Select, Tooltip } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessKeys, DisabledFields } from './create-request-form';
import { useTradingFormMetaQuery } from '@common/__generated__/trading.urql';
import { Uuid } from '@common/db-types';
import { OrderFormValuesProduct } from '@common/trading/frontend';
import BaseRow from 'src/components/base-row';
import CurrencyInput from 'src/components/currency-input';
import FormItem from 'src/components/form-item';
import {
  FORMAT_PRICE,
  FORMAT_SELECT,
  FORMAT_VOLUME,
} from 'src/components/form-item-changes';
import ButtonSubmit from 'src/components/form-main-submit';
import VolumeInput from 'src/components/volume-input';

type ProductFieldProps = {
  field: FormListFieldData;
  showTitle?: boolean;
  disabled?: boolean;
  showPrice?: boolean;
  disabledFields: DisabledFields;
  accessKeys: AccessKeys;
  usedProducts?: Uuid[];
  showVolumeFromTo?: boolean;
  showFinalQty?: boolean;
  showAdminAdjustedVolume?: boolean;
} & FormListOperation;

export const initialProductDefaultValues: Partial<OrderFormValuesProduct> = {
  product_meta_id: '',
  volume_from: 0,
  volume_to: 0,
};

export const ProductField: React.FC<ProductFieldProps> = ({
  showTitle,
  field,
  remove,
  disabled,
  showPrice,
  disabledFields,
  accessKeys,
  usedProducts = [],
  showVolumeFromTo = true,
  showFinalQty = true,
  showAdminAdjustedVolume = true,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [t] = useTranslation();
  const [{ data, fetching }] = useTradingFormMetaQuery();
  const productOptions = data?.product ?? [];
  const measureUnitOptions = data?.measureUnit ?? [];

  const renderLabel = (trKey: string): string | undefined =>
    showTitle ? t(trKey) : undefined;

  const [volumeFrom, setVolumeFrom] = useState<number>();

  let colCount = 2;
  if (showAdminAdjustedVolume) colCount += 1;
  if (showFinalQty) colCount += 1;
  if (showVolumeFromTo) colCount += 1;
  if (showPrice) colCount += 1;
  const colSize = colCount === 5 ? 5 : 24 / colCount;
  const specialColSize = 24 % colCount || colSize;
  const mod = showVolumeFromTo ? 1 : 0;

  return (
    <BaseRow gutter={6} align="top" className="flex-nowrap cy-product-item">
      <Col flex={1}>
        <BaseRow gutter={6} align="top" className="flex-wrap">
          <Col xs={colSize}>
            <FormItem
              listName={['products']}
              name={[field.name, 'product_meta_id']}
              accessKey={accessKeys['product_meta_id']}
              className="form-item-label-nowrap cy-name-product_name"
              label={renderLabel('shipowner.form.product.name')}
              rules={[
                {
                  required: true,
                  message: t('shipowner.form.errors.name_required'),
                },
              ]}
              historyFormat={{
                type: FORMAT_SELECT,
                options: productOptions,
                key: 'id',
                value: 'fullName',
              }}
            >
              <Select
                loading={fetching}
                disabled={disabled || disabledFields.product_name}
              >
                {productOptions.map((product) => (
                  <Select.Option
                    key={product.id}
                    value={product.id}
                    disabled={
                      usedProducts.includes(product.id) || !product.active
                    }
                  >
                    <Tooltip title={product.fullName}>
                      {product.shortName}
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={colSize + mod} className={showVolumeFromTo ? '' : 'hide'}>
            <FormItem
              className="form-item-label-nowrap"
              label={renderLabel('shipowner.form.product.volume_from_to')}
              listName={['products']}
              historyNames={[
                [field.name, 'volume_from'],
                [field.name, 'volume_to'],
              ]}
              historyFormat={{
                type: FORMAT_VOLUME,
              }}
              required
              accessKey={accessKeys['volume_from_to']}
            >
              <Input.Group compact>
                <FormItem
                  disableHistory
                  noStyle
                  name={[field.name, 'volume_from']}
                  rules={[
                    {
                      required: true,
                      message: t('shipowner.form.errors.volume_from_required'),
                    },
                  ]}
                >
                  <VolumeInput
                    formItemName={['products', field.name, 'volume_from']}
                    className="site-input-split__first cy-name-volume_from"
                    onChange={(value) => setVolumeFrom(value)}
                    disabled={disabled || disabledFields.volume_from_to}
                    min={0.001}
                    controls={false}
                  />
                </FormItem>
                <Input className="site-input-split" placeholder="-" disabled />
                <FormItem
                  disableHistory
                  noStyle
                  listName={['products']}
                  name={[field.name, 'volume_to']}
                  rules={[
                    {
                      required: true,
                      message: t('shipowner.form.errors.volume_to_required'),
                    },
                  ]}
                >
                  <VolumeInput
                    formItemName={['products', field.name, 'volume_to']}
                    controls={false}
                    className="site-input-split__next cy-name-volume_to"
                    disabled={disabled || disabledFields.volume_from_to}
                    min={volumeFrom ? volumeFrom / 1000 : volumeFrom}
                  />
                </FormItem>
              </Input.Group>
            </FormItem>
          </Col>
          <Col xs={specialColSize} className={showFinalQty ? '' : 'hide'}>
            <FormItem
              className="form-item-label-nowrap cy-name-final_qty"
              label={renderLabel('shipowner.form.product.final_qty')}
              accessKey={accessKeys['product_final_qty']}
              listName={['products']}
              name={[field.name, 'final_qty']}
            >
              <VolumeInput
                className="full-width"
                formItemName={['products', field.name, 'final_qty']}
                controls={false}
                disabled
              />
            </FormItem>
          </Col>
          <Col
            xs={specialColSize}
            className={showAdminAdjustedVolume ? '' : 'hide'}
          >
            <FormItem
              className="form-item-label-nowrap cy-name-admin_adjusted_volume"
              label={renderLabel(
                'shipowner.form.product.admin_adjusted_volume',
              )}
              accessKey={accessKeys['product_admin_adjusted_volume']}
              listName={['products']}
              name={[field.name, 'admin_adjusted_volume']}
            >
              <VolumeInput
                className="full-width"
                formItemName={['products', field.name, 'admin_adjusted_volume']}
                controls={false}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={colSize - mod}>
            <FormItem
              listName={['products']}
              accessKey={accessKeys['measure']}
              label={renderLabel('shipowner.form.product.measure')}
              name={[field.name, 'measure']}
              className="form-item-label-nowrap cy-name-measure"
              rules={[
                {
                  required: true,
                  message: t('shipowner.form.errors.product_measure_required'),
                },
              ]}
              historyFormat={{
                type: FORMAT_SELECT,
                options: measureUnitOptions,
                key: 'id',
                value: 'name',
              }}
            >
              <Select disabled={disabled || disabledFields.measure}>
                {measureUnitOptions.map((m) => (
                  <Select.Option key={m.id} value={m.id} disabled={!m.active}>
                    <Tooltip title={m.description}>
                      {t(`unit.${m.name}`)}
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          {showPrice && (
            <Col xs={colSize}>
              <FormItem
                listName={['products']}
                accessKey={accessKeys['price']}
                label={renderLabel('shipowner.form.product.price')}
                name={[field.name, 'price']}
                className="form-item-label-nowrap cy-name-volume_price"
                rules={[
                  {
                    required: true,
                    message: t('shipowner.form.errors.product_price_required'),
                  },
                ]}
                historyFormat={{
                  type: FORMAT_PRICE,
                }}
              >
                <CurrencyInput
                  formItemName={['products', field.name, 'price']}
                  disabled={disabled || disabledFields.product_price}
                  className="full-width"
                />
              </FormItem>
            </Col>
          )}
        </BaseRow>
      </Col>
      {!disabled && !disabledFields.product_delete && (
        <Col>
          <FormItem label={showTitle ? ' ' : undefined}>
            <ButtonSubmit
              accessKey={accessKeys['remove_product']}
              type="primary"
              danger
              className="action-button cy-action-remove_product"
              icon={<DeleteOutlined />}
              onClick={() => remove(field.name)}
              title={t('shipowner.form.product.remove')}
            />
          </FormItem>
        </Col>
      )}
    </BaseRow>
  );
};

export default ProductField;
