import { lazy } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { SUPPLIERS } from '@common/routes';
import { LayoutMain } from 'src/layouts/main/main';
const SuppliersPage = lazy(() => import('./supplier'));

export const SuppliersApp: React.FC = () => {
  const history = useHistory();
  // TODO:  allowForRoles: ['regular', 'supplier']
  const isOnAppPage = history.location.pathname === SUPPLIERS.APP;

  return (
    <LayoutMain>
      <div
        className={`wrapper wrapper--offset-top ${!isOnAppPage && 'hide--lg'}`}
      ></div>
      <div className="wrapper wrapper--offset-bottom">
        <Switch>
          <Route path={SUPPLIERS.APP} component={SuppliersPage} />
        </Switch>
      </div>
    </LayoutMain>
  );
};

export default SuppliersApp;
