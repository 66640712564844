// NB: this file must be in common directory, because
// backend code uses routes for email link generation etc

const ACCOUNT_PREFIX = '/account';

export const ACCOUNT = {
  APP: ACCOUNT_PREFIX,
  LOGIN: `${ACCOUNT_PREFIX}/login`,
  REGISTRATION: `${ACCOUNT_PREFIX}/registration`,
  FORGOT_PASSWORD: `${ACCOUNT_PREFIX}/forgot-password`,
  EMAIL_CONFIRMATION: `${ACCOUNT_PREFIX}/email-confirmation`,
  EXPIRED: `${ACCOUNT_PREFIX}/expired`,
} as const;

export const FORGOT_PASSWORD_EMPLOYEE_PARAM = 'q';

const BANKING_PREFIX = '/banking';

export const BANKING = {
  APP: BANKING_PREFIX,
  INTERNAL_TRANSACTIONS: `${BANKING_PREFIX}/internal-transactions`,
  EXTERNAL_TRANSACTIONS: `${BANKING_PREFIX}/external-transactions`,
  ACCOUNTS: `${BANKING_PREFIX}/company-bank-accounts`,
} as const;

const KYC_PREFIX = '/kyc';

export const KYC = {
  APP: KYC_PREFIX,
  REGISTRATION: `${KYC_PREFIX}/registration`,
  REGISTRATION_1: `${KYC_PREFIX}/registration/on-boarding`,
  REGISTRATION_2: `${KYC_PREFIX}/registration/general-information`,
  REGISTRATION_3: `${KYC_PREFIX}/registration/additional-information`,
  REGISTRATION_4: `${KYC_PREFIX}/registration/contact-information`,
  REGISTRATION_5: `${KYC_PREFIX}/registration/finish`,
} as const;

const REQUEST_FOR_ROLE_PREFIX = '/request-role';

export const REQUEST_FOR_ROLE = {
  APP: REQUEST_FOR_ROLE_PREFIX,
} as const;

const PROFILE_PREFIX = '/profile';
const COMPANY_PROFILE_PREFIX = `${PROFILE_PREFIX}/company-profile` as const;
const USER_PROFILE_PREFIX = `${PROFILE_PREFIX}/user-profile` as const;

export const PROFILE = {
  APP: PROFILE_PREFIX,
  USER_PROFILE: USER_PROFILE_PREFIX,
  USER_PROFILE_EDIT: `${USER_PROFILE_PREFIX}/edit`,
  USER_PROFILE_CHANGE_PASSWORD: `${USER_PROFILE_PREFIX}/change-password`,
  COMPANY_PROFILE: COMPANY_PROFILE_PREFIX,
  COMPANY_PROFILE_MAIN_INFO: `${COMPANY_PROFILE_PREFIX}/main-info`,
  COMPANY_PROFILE_MAIN_INFO_EDIT: `${COMPANY_PROFILE_PREFIX}/main-info/edit`,
  COMPANY_PROFILE_BLACK_LIST: `${COMPANY_PROFILE_PREFIX}/black-list`,
  COMPANY_PROFILE_VESSELS: `${COMPANY_PROFILE_PREFIX}/vessels`,
  COMPANY_PROFILE_VESSELS_VIEW: `${COMPANY_PROFILE_PREFIX}/vessels/:vessel_id`,
  COMPANY_PROFILE_EMPLOYEES: `${COMPANY_PROFILE_PREFIX}/employees`,
  COMPANY_PROFILE_DOCUMENTS: `${COMPANY_PROFILE_PREFIX}/documents`,
} as const;

const SHIPOWNERS_PREFIX = '/shipowners';

export const SHIPOWNERS = {
  APP: SHIPOWNERS_PREFIX,
  ALL_OFFERS: `${SHIPOWNERS_PREFIX}/all-offers`,
  ALL_OFFERS_DETAILS: `${SHIPOWNERS_PREFIX}/all-offers/:id`,
  MANAGE_REQUEST: `${SHIPOWNERS_PREFIX}/manage-request`,
  MANAGE_REQUEST_DETAILS: `${SHIPOWNERS_PREFIX}/manage-request/:id`,
} as const;

const SUPPLIERS_PREFIX = '/suppliers';

export const SUPPLIERS = {
  APP: SUPPLIERS_PREFIX,
  RFQ: `${SUPPLIERS_PREFIX}/:id`,
} as const;

const ROUTE_PLAN_PREFIX = '/route-plan';

export const ROUTE_PLAN = {
  APP: ROUTE_PLAN_PREFIX,
} as const;

const MARKET_WATCH_PREFIX = '/market-watch';

export const MARKET_WATCH = {
  APP: MARKET_WATCH_PREFIX,
} as const;

const ADMIN_PREFIX = '/admin';
const ADMIN_USERS = `${ADMIN_PREFIX}/users` as const;
const ADMIN_USERS_PROFILE = `${ADMIN_PREFIX}/users/:id` as const;
const ADMIN_USER_PROFILE_PREFIX =
  `${ADMIN_USERS_PROFILE}/user-profile` as const;
const ADMIN_COMPANIES = `${ADMIN_PREFIX}/companies` as const;
const ADMIN_COMPANY_PROFILE = `${ADMIN_COMPANIES}/:id` as const;
const ADMIN_VESSELS = `${ADMIN_PREFIX}/vessels` as const;
const ADMIN_LOGS = `${ADMIN_PREFIX}/logs` as const;
const ADMIN_DOCUMENTS = `${ADMIN_PREFIX}/documents` as const;
const ADMIN_TRANSACTIONS = `${ADMIN_PREFIX}/transactions` as const;
const ADMIN_OFFERS = `${ADMIN_PREFIX}/offers` as const;

export const ADMIN = {
  APP: ADMIN_PREFIX,
  USERS: ADMIN_USERS,
  USERS_PROFILE: ADMIN_USERS_PROFILE,
  VESSELS: ADMIN_VESSELS,
  VESSELS_LIST: `${ADMIN_VESSELS}/list`,
  VESSELS_INFO: `${ADMIN_VESSELS}/list/:id`,
  COMPANIES: ADMIN_COMPANIES,
  COMPANIES_LIST: `${ADMIN_COMPANIES}/list`,
  COMPANY_PROFILE: ADMIN_COMPANY_PROFILE,
  COMPANY_PROFILE_MAIN_INFO: `${ADMIN_COMPANY_PROFILE}/main-info`,
  COMPANY_PROFILE_MAIN_INFO_EDIT: `${ADMIN_COMPANY_PROFILE}/main-info/edit`,
  COMPANY_PROFILE_BLACK_LIST: `${ADMIN_COMPANY_PROFILE}/black-list`,
  COMPANY_PROFILE_VESSELS: `${ADMIN_COMPANY_PROFILE}/vessels`,
  COMPANY_PROFILE_VESSELS_VIEW: `${ADMIN_COMPANY_PROFILE}/vessels/:vessel_id`,
  COMPANY_PROFILE_EMPLOYEES: `${ADMIN_COMPANY_PROFILE}/employees`,
  COMPANY_PROFILE_DOCUMENTS: `${ADMIN_COMPANY_PROFILE}/documents`,
  COMPANY_PROFILE_FINANCIAL_FIELD: `${ADMIN_COMPANY_PROFILE}/financial-field`,
  COMPANY_PROFILE_MASTER_DATA: `${ADMIN_COMPANY_PROFILE}/master-data`,
  TRANSACTIONS: ADMIN_TRANSACTIONS,
  TRANSACTIONS_INTERNAL: `${ADMIN_TRANSACTIONS}/internal-transactions`,
  TRANSACTIONS_EXTERNAL: `${ADMIN_TRANSACTIONS}/external-transactions`,
  DOCUMENTS: ADMIN_DOCUMENTS,
  DOCUMENTS_LIST: `${ADMIN_DOCUMENTS}/all`,
  OFFERS: ADMIN_OFFERS,
  OFFERS_LIST: `${ADMIN_OFFERS}/list`,
  OFFERS_INFO: `${ADMIN_OFFERS}/list/:id`,
  OFFERS_VIEW: `${ADMIN_OFFERS}/view/:id`,
  LOGS: ADMIN_LOGS,
  MASTER_DATA: `${ADMIN_PREFIX}/master-data`,
  LOGS_LIST: `${ADMIN_LOGS}/all`,
  USER_PROFILE: ADMIN_USER_PROFILE_PREFIX,
  USER_PROFILE_EDIT: `${ADMIN_USER_PROFILE_PREFIX}/edit`,
  USER_PROFILE_CHANGE_PASSWORD: `${ADMIN_USER_PROFILE_PREFIX}/change-password`,
  EMAIL_PLAYGROUND: `${ADMIN_PREFIX}/email-playground`,
} as const;

export const HOME_URL = '/';
export const ERROR_FALLBACK = ACCOUNT.LOGIN;
export const ERROR_403 = ACCOUNT.LOGIN;
export const ERROR_404 = '';
export const AUTHORIZED_HOME = PROFILE.APP;

export const TERMS_URL = 'https://xmar.com/terms';
