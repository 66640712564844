// import { FileAttachment, SupplierOfferInput } from '../__generated__/types';
// import { Uuid } from '../db-types';

/** There is 16 statuses in total, but `expired` means two statuses: one for
 * shipowner-request, other is for supplier-offer */
export enum OrderStatus {
  NEW = 'new',
  // RFQ is cancelled either by it's Shipowner or by an Admin
  SHIPOWNER_CANCEL = 'shipowner:cancel',
  // RFQ validity time is extended
  SHIPOWNER_EXTENDED_RFQ = 'shipowner:extended_rfq',
  // An offer is rejected
  SUPPLIER_REJECTED = 'supplier:rejected',
  // A new Offer is created
  SUPPLIER_SENT_OFFER = 'supplier:sent_offer',
  // An Offer is updated by the Supplier
  SUPPLIER_AMENDED = 'supplier:amended',
  // An Offer is declined by the Shipowner (All Offers tab)
  SHIPOWNER_DECLINED = 'shipowner:declined',
  SHIPOWNER_SENT_COUNTER_OFFER = 'shipowner:sent_counter_offer',
  SHIPOWNER_ACCEPTED = 'shipowner:accepted',
  EXPIRED = 'expired',
  SHIPOWNER_FINAL_QTY_ENTERED = 'shipowner:final_qty_entered',
  SHIPOWNER_FINAL_QTY_MODIFIED = 'shipowner:final_qty_modified',
  SUPPLIER_BDN_UPLOADED = 'supplier:bdn_uploaded',
  INVOICED = 'invoiced',
  PAID = 'paid',
}

export enum OrderColors {
  WARNING = 'warning',
  DANGER = 'danger',
  NEW = 'new',
  SUCCESS = 'success',
  DONE = 'done',
}

export const SHIPOWNER_STATUSES: OrderStatus[] = [
  OrderStatus.NEW,
  OrderStatus.SHIPOWNER_ACCEPTED,
  OrderStatus.PAID,
  OrderStatus.INVOICED,
  OrderStatus.SHIPOWNER_CANCEL,
  OrderStatus.SHIPOWNER_DECLINED,
  OrderStatus.EXPIRED,
];

export const SUPPLIER_STATUSES: OrderStatus[] = [
  OrderStatus.NEW,
  // ...
];

// type BaseArgs = { order_id: string };

export enum ShipownerAction {
  DECLINE = 'decline',
  SEND_COUNTER_OFFER = 'send_counter_offer',
  // 'accept:send_code': BaseArgs;
  ACCEPT = 'accept',
  ACCEPT_CONFIRM = 'accept_confirm',
  EXTEND_RFQ = 'extend_rfq',
  CANCEL = 'cancel',
  ENTER_FINAL_QTY = 'enter_final_qty',
}

// export type ShipownerActionBody = {
//   [ShipownerAction.DECLINE]: BaseArgs & { reason?: string };
//   [ShipownerAction.SEND_COUNTER_OFFER]: BaseArgs & {
//     order: SupplierOfferInput;
//   };
//   [ShipownerAction.ACCEPT]: BaseArgs;
//   [ShipownerAction.EXTEND_RFQ]: BaseArgs & { new_validity_period: number };
//   [ShipownerAction.CANCEL]: BaseArgs;
//   [ShipownerAction.ENTER_FINAL_QTY]: BaseArgs & {
//     products: Record<Uuid, number>;
//   };
// };

export enum SupplierAction {
  AMEND = 'amend',
  REJECT = 'reject',
  UPLOAD_BDN = 'upload_bdn',
}

// export type SupplierActionBody = {
//   [SupplierAction.AMEND]: BaseArgs & { order: SupplierOfferInput };
//   [SupplierAction.REJECT]: BaseArgs;
//   [SupplierAction.UPLOAD_BDN]: BaseArgs & { file: FileAttachment };
// };

export enum AdminAction {
  CONFIRM_BDN = 'confirm_bdn',
  CANCEL = 'cance',
  UPDATE_ORDER = 'update_order',
}

export type MapToGqlHandler<T> = {
  [K in keyof T]: { action: K; args: T[K] };
}[keyof T];

// export type ShipownerOrderActionData = MapToGqlHandler<ShipownerActionBody>;
// export type SupplierOrderActionData = MapToGqlHandler<SupplierActionBody>;
