import dayjs, { Dayjs } from 'dayjs';

type Props = {
  dateValue: string;
  datetime?: boolean;
};

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = DEFAULT_DATE_FORMAT + ' HH:mm';

type DateValue = string | number | Dayjs;

export const dateFormat = (
  dateValue?: DateValue,
  datetime?: boolean,
  defaultReturn?: string | undefined,
): string | undefined => {
  if (!dateValue) return defaultReturn;
  const dayjsobj = dayjs(dateValue);

  if (!dayjsobj.isValid()) {
    console.warn('Invalid Date', dateValue);
    return defaultReturn;
  }

  return dayjs(dateValue).format(
    datetime ? DEFAULT_DATETIME_FORMAT : DEFAULT_DATE_FORMAT,
  );
};

export const stringToDateSaveForInput = (
  dateValue?: DateValue,
): Dayjs | undefined => {
  if (typeof dateValue === 'undefined') return;
  const dayjsobj = dayjs(dateValue);
  if (!dayjsobj.isValid()) {
    console.warn('Invalid Date', dateValue);
    return;
  }

  return dayjsobj;
};

export const DateFormat: React.FC<Props> = ({ dateValue, datetime }) => {
  const dateFormatted = dateFormat(dateValue, datetime, '-');
  return <>{dateFormatted}</>;
};

export default DateFormat;
