import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FormItem from 'src/components/form-item';
import FormItemWithLink from 'src/components/form-item-with-link';
import FormTitle from 'src/components/form-title';

export const InvoicedForm: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <FormTitle>{t('supplier.form.completed_deal')}</FormTitle>,
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        className="form-no-label-dots form-label-text-left-align"
      >
        <fieldset disabled={disabled}>
          <FormItem label={t('supplier.form.rfq_created')} name="rfq_created">
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItem label={t('supplier.form.offer_sent')} name="offer_sent">
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItem
            label={t('supplier.form.counteroffer_sent')}
            name="counteroffer_sent"
          >
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItem
            label={t('supplier.form.approved_offer')}
            name="approved_offer"
          >
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItemWithLink
            label={t('supplier.form.nomination')}
            name="nomination"
            linkTitle={t('supplier.form.download')}
            linkClick={(e) => console.log(e)}
          >
            <Input placeholder="Lorem" />
          </FormItemWithLink>
          <FormItemWithLink
            label={t('supplier.form.confirmation')}
            name="confirmation"
            linkTitle={t('supplier.form.download')}
            linkTo="/suppliers"
          >
            <Input placeholder="Lorem" />
          </FormItemWithLink>
          <FormItem
            label={t('supplier.form.ammount_on_hold')}
            name="ammount_on_hold"
          >
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItemWithLink
            label={t('supplier.form.delivered')}
            name="delivered"
            linkTitle={t('supplier.form.download')}
            linkClick={(e) => console.log(e)}
          >
            <Input placeholder="Lorem" />
          </FormItemWithLink>
          <FormItem label={t('supplier.form.invoiced')} name="invoiced">
            <Input placeholder="Lorem" />
          </FormItem>
          <FormItem
            label={t('supplier.form.payment_completed')}
            name="payment_completed"
          >
            <Input placeholder="Lorem" />
          </FormItem>
        </fieldset>
      </Form>
    </>
  );
};

export default InvoicedForm;
