import { Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StatusBox } from '../layout-elements/status';
import TabNavigation from '../layout-elements/tab-navigation';
import {
  UserRole,
  VerifyStatus,
  activityType,
  ActivityType,
} from '@common/roles';
import { KYC, PROFILE } from '@common/routes';
import LayoutLoader from 'src/components/layout-loader';
import { useAuth } from 'src/hooks';
import RequestRoleModal from 'src/pages/request-role/request-role-modal';

const CustomAction: React.FC = () => {
  const { profileInfo, role } = useAuth();
  const [t] = useTranslation();
  if (!profileInfo) return <Spin />;
  const activity = activityType(role as UserRole);
  let requestBtn: React.ReactNode = null;
  let activityLabel = '';
  if (activity === ActivityType.SHIPOWNER) {
    activityLabel = 'profile.business_activity_shipowner';
    requestBtn = <RequestRoleModal />;
  } else if (activity === ActivityType.SUPPLIER) {
    activityLabel = 'profile.business_activity_supplier';
    requestBtn = <RequestRoleModal />;
  } else if (activity === ActivityType.SHIPOWNER_SUPPLIER) {
    activityLabel = 'profile.business_activity_shipowner_supplier';
  } else {
    activityLabel = 'profile.business_activity_unknown';
  }
  return (
    <Space>
      <Typography.Text>
        {t('profile.business_activity', { activity: t(activityLabel) })}
      </Typography.Text>
      {requestBtn}
      <StatusBox status={profileInfo.verifyStatus as VerifyStatus} />
    </Space>
  );
};

export const LayoutProfile: React.FC = ({ children }) => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <TabNavigation
        offset={history.location.pathname.search(PROFILE.USER_PROFILE) !== -1}
        tabs={[
          {
            accessKey: 'profile.nav.user-profile',
            title: t('profile.navigation.users_profile'),
            url: PROFILE.USER_PROFILE,
            key: PROFILE.USER_PROFILE,
          },
          {
            accessKey: 'profile.nav.company-profile',
            title: t('profile.navigation.companies_profile'),
            url: PROFILE.COMPANY_PROFILE,
            key: PROFILE.COMPANY_PROFILE,
          },
        ]}
        customActions={<CustomAction />}
      />
      <div className="wrapper wrapper--offset-bottom">
        <LayoutLoader>{children}</LayoutLoader>
      </div>
    </>
  );
};

export default LayoutProfile;
