import { Spin, Tooltip } from 'antd';
import { t } from 'i18next';

import { useVesselInfoQuery } from '@gql/auth.urql';
import {
  renderDescription,
  VesselDescription,
} from 'src/pages/profile/companies-page-vessel-details';

type Props = {
  id?: string;
};
type PropsTip = {
  id: string;
};

const VesselInfo: React.FC<PropsTip> = ({ id }) => {
  const [{ data, fetching }] = useVesselInfoQuery({
    variables: { id },
  });
  if (fetching) return <Spin />;
  const vessel = data?.vessel;
  const vesselMeta = vessel?.meta;
  if (!vesselMeta) return <>{t('shipowner.vessel_not_found')}</>;
  return <>{renderDescription(vessel as unknown as VesselDescription, true)}</>;
};

export const VesselInfoTooltip: React.FC<Props> = ({ id, children }) => (
  <Tooltip color="white" title={!!id && <VesselInfo id={id} />}>
    {children}
  </Tooltip>
);

export default VesselInfoTooltip;
