import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Col, Form, Input, Radio, Space } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import BaseRow from './base-row';
import { stringToDateSaveForInput } from './date-format';
import FormItem from './form-item';
import FormModal from './form-modal';
import { useUpdateProfileInfoMutation } from '@gql/auth.urql';
import { useAuthSubscription } from 'src/hooks';

type FormValues = {
  review: string;
};

export const ReviewModal = () => {
  const [t] = useTranslation();
  const { profileInfo, userId } = useAuthSubscription();
  const [form] = Form.useForm<FormValues>();
  const storeKey = userId + '_review_hide';
  const storeItem = stringToDateSaveForInput(
    localStorage.getItem(storeKey) ?? '',
  );
  const [, updateProfileInfo] = useUpdateProfileInfoMutation();
  const closed = storeItem && dayjs().diff(storeItem) < 1000 * 60 * 60;

  if (closed || !userId) return <></>;
  return (
    <FormModal
      title={t('profile.review_title')}
      formProps={{
        layout: 'vertical',
        form,
        onSubmit: async ({ review }: FormValues) => {
          if (!profileInfo) return { status: false };
          await updateProfileInfo({
            userId: profileInfo.id,
            newUser: { show_review_modal: false },
          });
          return { status: true };
        },
      }}
      onCancel={async () => {
        if (!profileInfo) return;
        await updateProfileInfo({
          userId: profileInfo.id,
          newUser: { show_review_modal: false },
        });
      }}
      cancelButtonProps={{
        className: 'cy-cancel-review-modal',
      }}
      visible={profileInfo?.show_review_modal}
    >
      <div className="container offset-bottom review-modal">
        <div className="offset-bottom" />
        <BaseRow justify="center" className="offset-bottom">
          <Col>
            <FormItem
              name="review"
              rules={[
                {
                  required: true,
                  message: t('form.errors.review_required'),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="horizontal" size={20}>
                  <Radio value={1}>
                    <DislikeOutlined />
                  </Radio>
                  <Radio value={2}>
                    <LikeOutlined />
                  </Radio>
                </Space>
              </Radio.Group>
            </FormItem>
          </Col>
        </BaseRow>
        <FormItem
          name="additional_information"
          label={t('form.additional_information')}
        >
          <Input.TextArea rows={4} />
        </FormItem>
      </div>
    </FormModal>
  );
};

export default ReviewModal;
