import { default as c } from 'country-codes-list';
const { all, findOne } = c;

export const countryNames: {
  code: string;
  name: string;
  flag: string;
  callingCode: string;
}[] = all().map((c: Record<string, unknown>) => ({
  code: (c.countryCode as string).toLowerCase(),
  name: c.countryNameEn,
  flag: c.flag,
  callingCode: c.countryCallingCode,
}));

export const countryCallingCodes: string[] = all().map(
  (c: { countryCallingCode: string }) => c.countryCallingCode,
);
export const countryNameToCode = (name: string): string | undefined =>
  findOne('countryNameEn', name)?.countryCode;
export const countryCodeToName = (code: string): string | undefined =>
  findOne('countryCode', code)?.countryNameEn;
