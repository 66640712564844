import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOME_URL } from '@common/routes';
import logo from 'src/images/logo/logo.svg';

const { Title } = Typography;

export const Logo: React.FC = () => {
  const [t] = useTranslation();
  return (
    <Link to={HOME_URL}>
      <Space>
        <img src={logo} alt="Logo" />
        <Title level={3} className="header-logo-text">
          {t('header_navigation.logo')}
        </Title>
      </Space>
    </Link>
  );
};

export default Logo;
