import { getStateByRoleSet } from './access-mapper';
import { AccessKey, AccessState, AccessType } from './types';

export const SET_ACCESS = 'SET_ACCESS';
export const SET_ACCESS_KEY = 'SET_ACCESS_KEY';

type ActionSetAccess = {
  type: typeof SET_ACCESS;
  payload: AccessState;
};
type ActionSetAccessKey = {
  type: typeof SET_ACCESS_KEY;
  payload: {
    key: AccessKey;
    value: AccessType;
  };
};
export type AccessAction = ActionSetAccess | ActionSetAccessKey;

export const actionChangeState = (roleSet: string[]): AccessAction => ({
  type: SET_ACCESS,
  payload: getStateByRoleSet(roleSet),
});

export const actionChangeKey = (
  key: AccessKey,
  value: AccessType,
): AccessAction => ({
  type: SET_ACCESS_KEY,
  payload: { key, value },
});
