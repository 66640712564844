import * as Types from './types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RegisterMutationVariables = Types.Exact<{
  data: Types.RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'mutation_root', register: { __typename?: 'RegisterOutput', user_id: number } };

export type SendConfirmationCodeMutationVariables = Types.Exact<{
  data: Types.SendConfirmationCodeInput;
}>;


export type SendConfirmationCodeMutation = { __typename?: 'mutation_root', send_confirmation_code: { __typename?: 'DummyOutput', ok: boolean } };

export type ConfirmEmailMutationVariables = Types.Exact<{
  data: Types.RegisterConfirmInput;
}>;


export type ConfirmEmailMutation = { __typename?: 'mutation_root', register_confirm: { __typename?: 'DummyOutput', ok: boolean } };

export type CheckAuthSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckAuthSubscription = { __typename?: 'subscription_root', user?: { __typename?: 'user', role: string, email: string, additional: Record<string, any>, show_review_modal?: boolean, id: string, verifyStatus?: string, jwtAuths: Array<{ __typename?: 'jwt_auth', chatwoot_identifier_hash?: string }>, company?: { __typename?: 'company', id: string } } };

export type ProfilePageUserFragment = { __typename?: 'user', user_id: string, email: string, phone_number?: string, session_timeout: number, role: string, additional: Record<string, any>, first_name?: string, last_name?: string, jwtAuths: Array<{ __typename?: 'jwt_auth', chatwoot_identifier_hash?: string, additional: Record<string, any>, createdAt: string }>, company?: { __typename?: 'company', additional: Record<string, any>, name: string } };

export type GetProfileInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProfileInfoQuery = { __typename?: 'query_root', user?: { __typename?: 'user', user_id: string, email: string, phone_number?: string, session_timeout: number, role: string, additional: Record<string, any>, first_name?: string, last_name?: string, jwtAuths: Array<{ __typename?: 'jwt_auth', chatwoot_identifier_hash?: string, additional: Record<string, any>, createdAt: string }>, company?: { __typename?: 'company', additional: Record<string, any>, name: string } } };

export type UpdateProfileInfoMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  newUser: Types.UserSetInput;
}>;


export type UpdateProfileInfoMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: string } };

export type ResetPasswordMutationVariables = Types.Exact<{
  data: Types.ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'mutation_root', reset_password: { __typename?: 'DummyOutput', ok: boolean } };

export type ResetPasswordConfirmMutationVariables = Types.Exact<{
  data: Types.ResetPasswordConfirmInput;
}>;


export type ResetPasswordConfirmMutation = { __typename?: 'mutation_root', reset_password_confirm: { __typename?: 'DummyOutput', ok: boolean } };

export type RegisterEmployeeMutationVariables = Types.Exact<{
  data: Types.RegisterEmployeeInExistingCompanyInput;
}>;


export type RegisterEmployeeMutation = { __typename?: 'mutation_root', register_employee_in_existing_company: { __typename?: 'DummyOutput', ok: boolean } };

export type SubscribeToProfileSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type SubscribeToProfileSubscription = { __typename?: 'subscription_root', user: Array<{ __typename?: 'user', email: string, session_timeout: number }> };

export type ShipPortsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShipPortsQuery = { __typename?: 'query_root', ports: Array<{ __typename?: 'ship_port', name: string, active: boolean, id: string, countryCode: string }> };

export type ConfirmEmailCodeQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type ConfirmEmailCodeQuery = { __typename?: 'query_root', confirmation_code: Array<{ __typename?: 'confirmation_code', code: string }> };

export type ConfirmCodeFormFillQueryVariables = Types.Exact<{
  data: Types.ConfirmCodeFormFillInput;
}>;


export type ConfirmCodeFormFillQuery = { __typename?: 'query_root', confirm_code_form_fill: { __typename?: 'ConfirmCodeFormFillOutput', confirmation_code?: string, email: string } };

export type UpdateCompanyInfoMutationVariables = Types.Exact<{
  data: Types.CompanySetInput;
}>;


export type UpdateCompanyInfoMutation = { __typename?: 'mutation_root', update_company?: { __typename?: 'company_mutation_response', returning: Array<{ __typename?: 'company', company_id: string }> } };

export type OnBoardingMutationVariables = Types.Exact<{
  data: Types.SubmitKycInput;
}>;


export type OnBoardingMutation = { __typename?: 'mutation_root', submit_kyc: { __typename?: 'DummyOutput', ok: boolean } };

export type RequestAnotherActivityMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestAnotherActivityMutation = { __typename?: 'mutation_root', request_another_activity?: { __typename?: 'DummyOutput', ok: boolean } };

export type CurrenciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrenciesQuery = { __typename?: 'query_root', currency: Array<{ __typename?: 'currency', active: boolean, id: string, code: string }> };

export type NotificationsSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NotificationsSubscription = { __typename?: 'subscription_root', user_notification: Array<{ __typename?: 'user_notification', type: string, createdAt: string, order?: string, orderHistory?: Array<{ __typename?: 'trading_order_versionlog_view', additional?: Record<string, any>, assigned_status?: string, created_by_user_id?: string, expires_at?: string, ship_port_id?: string, shipowner_request_order_id?: string, short_id?: string, trading_order_id?: string, version_is_deleted?: boolean, version_updated_at?: string }>, productHistory?: Array<{ __typename?: 'trading_order_product_versionlog_view', volume_from?: number, volume_to?: number, price?: number, version_updated_at?: string, version_is_deleted?: boolean, meta?: { __typename?: 'product_meta', full_name: string, short_name: string } }> }> };

export type ReadNotificationsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type ReadNotificationsMutation = { __typename?: 'mutation_root', read_notifications: { __typename?: 'DummyOutput', ok: boolean } };

export type SearchVesselsFragment = { __typename?: 'VesselFromSearch', ship_name: string, mmsi: string, imo?: string, country_code?: string, additional: Record<string, any> };

export type SearchVesselsQueryVariables = Types.Exact<{
  data: Types.SearchVesselsInput;
}>;


export type SearchVesselsQuery = { __typename?: 'query_root', search_vessels: { __typename?: 'SearchVesselsOutput', vessels: Array<{ __typename?: 'VesselFromSearch', ship_name: string, mmsi: string, imo?: string, country_code?: string, additional: Record<string, any> }> } };

export type CompanyVesselsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CompanyVesselsQuery = { __typename?: 'query_root', vessel: Array<{ __typename?: 'company_vessel', id: string, meta: { __typename?: 'vessel_meta', mmsi: string, id: string, name: string } }> };

export type AddVesselMutationVariables = Types.Exact<{
  data: Types.AddVesselInput;
}>;


export type AddVesselMutation = { __typename?: 'mutation_root', add_vessel: { __typename?: 'DummyOutput', ok: boolean } };

export type UpdateCompanyVesselMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  data: Types.CompanyVesselSetInput;
}>;


export type UpdateCompanyVesselMutation = { __typename?: 'mutation_root', update_company_vessel_by_pk?: { __typename?: 'company_vessel', id: string } };

export type VesselInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type VesselInfoQuery = { __typename?: 'query_root', vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', mmsi: string, imo?: string, additional: Record<string, any>, countryCode?: string, name: string } } };

export type CompanyBlocklistQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CompanyBlocklistQuery = { __typename?: 'query_root', blocklist: Array<{ __typename?: 'company_blocklist', reason?: string, id: string, name: string }> };

export type DeleteFromBlocklistMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteFromBlocklistMutation = { __typename?: 'mutation_root', delete_company_blocklist_by_pk?: { __typename?: 'company_blocklist', id: string } };

export type AddToCompanyBlocklistMutationVariables = Types.Exact<{
  data: Types.CompanyBlocklistInsertInput;
}>;


export type AddToCompanyBlocklistMutation = { __typename?: 'mutation_root', insert_company_blocklist_one?: { __typename?: 'company_blocklist', id: string } };

export type CompanyEmployeesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CompanyEmployeesQuery = { __typename?: 'query_root', company?: { __typename?: 'company', employees: Array<{ __typename?: 'user', role: string, email: string, phone_number?: string, additional: Record<string, any>, first_name?: string, last_name?: string, id: string, fullName?: string, blocked: boolean, verifyStatus?: string }> } };

export type CompanyUpdateEmployeeMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  data: Types.UserSetInput;
}>;


export type CompanyUpdateEmployeeMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'user', id: string } };

export type CompanyEmployeeSetIsBlockedMutationVariables = Types.Exact<{
  data: Types.UpdateEmployeeIsBlockedInput;
}>;


export type CompanyEmployeeSetIsBlockedMutation = { __typename?: 'mutation_root', update_employee_is_blocked?: { __typename?: 'DummyOutput', ok: boolean } };

export const ProfilePageUserFragmentDoc = gql`
    fragment ProfilePageUser on user {
  user_id
  email
  phone_number
  session_timeout
  jwtAuths: jwt_auths_rel(order_by: {created_at: desc}, limit: 5) {
    chatwoot_identifier_hash
    createdAt: created_at
    additional
  }
  company: company_rel {
    name: company_name
    additional
  }
  role
  additional
  first_name
  last_name
}
    `;
export const SearchVesselsFragmentDoc = gql`
    fragment SearchVessels on VesselFromSearch {
  ship_name
  mmsi
  imo
  country_code
  additional
}
    `;
export const RegisterDocument = gql`
    mutation Register($data: RegisterInput!) {
  register(data: $data) {
    user_id
  }
}
    `;

export function useRegisterMutation() {
  return Urql.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument);
};
export const SendConfirmationCodeDocument = gql`
    mutation SendConfirmationCode($data: SendConfirmationCodeInput!) {
  send_confirmation_code(data: $data) {
    ok
  }
}
    `;

export function useSendConfirmationCodeMutation() {
  return Urql.useMutation<SendConfirmationCodeMutation, SendConfirmationCodeMutationVariables>(SendConfirmationCodeDocument);
};
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($data: RegisterConfirmInput!) {
  register_confirm(data: $data) {
    ok
  }
}
    `;

export function useConfirmEmailMutation() {
  return Urql.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument);
};
export const CheckAuthDocument = gql`
    subscription CheckAuth {
  user: my_user_fun {
    id: user_id
    jwtAuths: jwt_auths_rel(limit: 1) {
      chatwoot_identifier_hash
    }
    role
    verifyStatus: verify_status_com
    email
    company: company_rel {
      id: company_id
    }
    additional
    show_review_modal
  }
}
    `;

export function useCheckAuthSubscription<TData = CheckAuthSubscription>(options: Omit<Urql.UseSubscriptionArgs<CheckAuthSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CheckAuthSubscription, TData>) {
  return Urql.useSubscription<CheckAuthSubscription, TData, CheckAuthSubscriptionVariables>({ query: CheckAuthDocument, ...options }, handler);
};
export const GetProfileInfoDocument = gql`
    query GetProfileInfo {
  user: my_user_fun {
    ...ProfilePageUser
  }
}
    ${ProfilePageUserFragmentDoc}`;

export function useGetProfileInfoQuery(options?: Omit<Urql.UseQueryArgs<GetProfileInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProfileInfoQuery>({ query: GetProfileInfoDocument, ...options });
};
export const UpdateProfileInfoDocument = gql`
    mutation UpdateProfileInfo($userId: uuid!, $newUser: user_set_input!) {
  update_user_by_pk(pk_columns: {user_id: $userId}, _set: $newUser) {
    id: user_id
  }
}
    `;

export function useUpdateProfileInfoMutation() {
  return Urql.useMutation<UpdateProfileInfoMutation, UpdateProfileInfoMutationVariables>(UpdateProfileInfoDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  reset_password(data: $data) {
    ok
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const ResetPasswordConfirmDocument = gql`
    mutation ResetPasswordConfirm($data: ResetPasswordConfirmInput!) {
  reset_password_confirm(data: $data) {
    ok
  }
}
    `;

export function useResetPasswordConfirmMutation() {
  return Urql.useMutation<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>(ResetPasswordConfirmDocument);
};
export const RegisterEmployeeDocument = gql`
    mutation RegisterEmployee($data: RegisterEmployeeInExistingCompanyInput!) {
  register_employee_in_existing_company(data: $data) {
    ok
  }
}
    `;

export function useRegisterEmployeeMutation() {
  return Urql.useMutation<RegisterEmployeeMutation, RegisterEmployeeMutationVariables>(RegisterEmployeeDocument);
};
export const SubscribeToProfileDocument = gql`
    subscription SubscribeToProfile {
  user {
    email
    session_timeout
  }
}
    `;

export function useSubscribeToProfileSubscription<TData = SubscribeToProfileSubscription>(options: Omit<Urql.UseSubscriptionArgs<SubscribeToProfileSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<SubscribeToProfileSubscription, TData>) {
  return Urql.useSubscription<SubscribeToProfileSubscription, TData, SubscribeToProfileSubscriptionVariables>({ query: SubscribeToProfileDocument, ...options }, handler);
};
export const ShipPortsDocument = gql`
    query ShipPorts {
  ports: ship_port {
    id: ship_port_id
    name
    countryCode: country_code
    active
  }
}
    `;

export function useShipPortsQuery(options?: Omit<Urql.UseQueryArgs<ShipPortsQueryVariables>, 'query'>) {
  return Urql.useQuery<ShipPortsQuery>({ query: ShipPortsDocument, ...options });
};
export const ConfirmEmailCodeDocument = gql`
    query ConfirmEmailCode($id: uuid!) {
  confirmation_code(where: {confirmation_code_id: {_eq: $id}}) {
    code
  }
}
    `;

export function useConfirmEmailCodeQuery(options: Omit<Urql.UseQueryArgs<ConfirmEmailCodeQueryVariables>, 'query'>) {
  return Urql.useQuery<ConfirmEmailCodeQuery>({ query: ConfirmEmailCodeDocument, ...options });
};
export const ConfirmCodeFormFillDocument = gql`
    query ConfirmCodeFormFill($data: ConfirmCodeFormFillInput!) {
  confirm_code_form_fill(data: $data) {
    confirmation_code
    email
  }
}
    `;

export function useConfirmCodeFormFillQuery(options: Omit<Urql.UseQueryArgs<ConfirmCodeFormFillQueryVariables>, 'query'>) {
  return Urql.useQuery<ConfirmCodeFormFillQuery>({ query: ConfirmCodeFormFillDocument, ...options });
};
export const UpdateCompanyInfoDocument = gql`
    mutation UpdateCompanyInfo($data: company_set_input!) {
  update_company(where: {}, _set: $data) {
    returning {
      company_id
    }
  }
}
    `;

export function useUpdateCompanyInfoMutation() {
  return Urql.useMutation<UpdateCompanyInfoMutation, UpdateCompanyInfoMutationVariables>(UpdateCompanyInfoDocument);
};
export const OnBoardingDocument = gql`
    mutation OnBoarding($data: SubmitKycInput!) {
  submit_kyc(data: $data) {
    ok
  }
}
    `;

export function useOnBoardingMutation() {
  return Urql.useMutation<OnBoardingMutation, OnBoardingMutationVariables>(OnBoardingDocument);
};
export const RequestAnotherActivityDocument = gql`
    mutation RequestAnotherActivity {
  request_another_activity {
    ok
  }
}
    `;

export function useRequestAnotherActivityMutation() {
  return Urql.useMutation<RequestAnotherActivityMutation, RequestAnotherActivityMutationVariables>(RequestAnotherActivityDocument);
};
export const CurrenciesDocument = gql`
    query Currencies {
  currency {
    id: currency_id
    code: currency_code
    active
  }
}
    `;

export function useCurrenciesQuery(options?: Omit<Urql.UseQueryArgs<CurrenciesQueryVariables>, 'query'>) {
  return Urql.useQuery<CurrenciesQuery>({ query: CurrenciesDocument, ...options });
};
export const NotificationsDocument = gql`
    subscription Notifications {
  user_notification {
    type: notification_type
    createdAt: created_at
    order: linked_trading_order
    orderHistory: trading_order_history_com {
      additional
      assigned_status
      created_by_user_id
      expires_at
      ship_port_id
      shipowner_request_order_id
      short_id
      trading_order_id
      version_is_deleted
      version_updated_at
    }
    productHistory: product_history_com {
      meta: product_meta_rel {
        full_name
        short_name
      }
      volume_from
      volume_to
      price
      version_updated_at
      version_is_deleted
    }
  }
}
    `;

export function useNotificationsSubscription<TData = NotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<NotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<NotificationsSubscription, TData>) {
  return Urql.useSubscription<NotificationsSubscription, TData, NotificationsSubscriptionVariables>({ query: NotificationsDocument, ...options }, handler);
};
export const ReadNotificationsDocument = gql`
    mutation ReadNotifications($ids: [uuid!]!) {
  read_notifications(data: {ids: $ids}) {
    ok
  }
}
    `;

export function useReadNotificationsMutation() {
  return Urql.useMutation<ReadNotificationsMutation, ReadNotificationsMutationVariables>(ReadNotificationsDocument);
};
export const SearchVesselsDocument = gql`
    query SearchVessels($data: SearchVesselsInput!) {
  search_vessels(data: $data) {
    vessels {
      ...SearchVessels
    }
  }
}
    ${SearchVesselsFragmentDoc}`;

export function useSearchVesselsQuery(options: Omit<Urql.UseQueryArgs<SearchVesselsQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchVesselsQuery>({ query: SearchVesselsDocument, ...options });
};
export const CompanyVesselsDocument = gql`
    query CompanyVessels($companyId: uuid!) {
  vessel: company_vessel(
    where: {company_id: {_eq: $companyId}, active: {_eq: true}}
  ) {
    id: company_vessel_id
    meta: meta_rel {
      id: vessel_meta_id
      mmsi
      name: ship_name
    }
  }
}
    `;

export function useCompanyVesselsQuery(options: Omit<Urql.UseQueryArgs<CompanyVesselsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyVesselsQuery>({ query: CompanyVesselsDocument, ...options });
};
export const AddVesselDocument = gql`
    mutation AddVessel($data: AddVesselInput!) {
  add_vessel(data: $data) {
    ok
  }
}
    `;

export function useAddVesselMutation() {
  return Urql.useMutation<AddVesselMutation, AddVesselMutationVariables>(AddVesselDocument);
};
export const UpdateCompanyVesselDocument = gql`
    mutation UpdateCompanyVessel($id: uuid!, $data: company_vessel_set_input!) {
  update_company_vessel_by_pk(pk_columns: {company_vessel_id: $id}, _set: $data) {
    id: company_vessel_id
  }
}
    `;

export function useUpdateCompanyVesselMutation() {
  return Urql.useMutation<UpdateCompanyVesselMutation, UpdateCompanyVesselMutationVariables>(UpdateCompanyVesselDocument);
};
export const VesselInfoDocument = gql`
    query VesselInfo($id: uuid!) {
  vessel: company_vessel_by_pk(company_vessel_id: $id) {
    meta: meta_rel {
      countryCode: country_code
      mmsi
      name: ship_name
      imo
      additional
    }
  }
}
    `;

export function useVesselInfoQuery(options: Omit<Urql.UseQueryArgs<VesselInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<VesselInfoQuery>({ query: VesselInfoDocument, ...options });
};
export const CompanyBlocklistDocument = gql`
    query CompanyBlocklist($companyId: uuid!) {
  blocklist: company_blocklist(where: {company_id: {_eq: $companyId}}) {
    id: company_blocklist_id
    name: blocked_company_name
    reason
  }
}
    `;

export function useCompanyBlocklistQuery(options: Omit<Urql.UseQueryArgs<CompanyBlocklistQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyBlocklistQuery>({ query: CompanyBlocklistDocument, ...options });
};
export const DeleteFromBlocklistDocument = gql`
    mutation DeleteFromBlocklist($id: uuid!) {
  delete_company_blocklist_by_pk(company_blocklist_id: $id) {
    id: company_blocklist_id
  }
}
    `;

export function useDeleteFromBlocklistMutation() {
  return Urql.useMutation<DeleteFromBlocklistMutation, DeleteFromBlocklistMutationVariables>(DeleteFromBlocklistDocument);
};
export const AddToCompanyBlocklistDocument = gql`
    mutation AddToCompanyBlocklist($data: company_blocklist_insert_input!) {
  insert_company_blocklist_one(object: $data) {
    id: company_blocklist_id
  }
}
    `;

export function useAddToCompanyBlocklistMutation() {
  return Urql.useMutation<AddToCompanyBlocklistMutation, AddToCompanyBlocklistMutationVariables>(AddToCompanyBlocklistDocument);
};
export const CompanyEmployeesDocument = gql`
    query CompanyEmployees($companyId: uuid!) {
  company: company_by_pk(company_id: $companyId) {
    employees: employees_rel {
      id: user_id
      role
      email
      phone_number
      additional
      fullName: user_full_name_com
      blocked: is_blocked
      verifyStatus: verify_status_com
      first_name
      last_name
    }
  }
}
    `;

export function useCompanyEmployeesQuery(options: Omit<Urql.UseQueryArgs<CompanyEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyEmployeesQuery>({ query: CompanyEmployeesDocument, ...options });
};
export const CompanyUpdateEmployeeDocument = gql`
    mutation CompanyUpdateEmployee($userId: uuid!, $data: user_set_input!) {
  updateUser: update_user_by_pk(pk_columns: {user_id: $userId}, _set: $data) {
    id: user_id
  }
}
    `;

export function useCompanyUpdateEmployeeMutation() {
  return Urql.useMutation<CompanyUpdateEmployeeMutation, CompanyUpdateEmployeeMutationVariables>(CompanyUpdateEmployeeDocument);
};
export const CompanyEmployeeSetIsBlockedDocument = gql`
    mutation CompanyEmployeeSetIsBlocked($data: UpdateEmployeeIsBlockedInput!) {
  update_employee_is_blocked(data: $data) {
    ok
  }
}
    `;

export function useCompanyEmployeeSetIsBlockedMutation() {
  return Urql.useMutation<CompanyEmployeeSetIsBlockedMutation, CompanyEmployeeSetIsBlockedMutationVariables>(CompanyEmployeeSetIsBlockedDocument);
};