import { Button, ButtonProps, Menu } from 'antd';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ACCESS_DENIED } from 'src/access-checker/access-types';
import { AccessContext } from 'src/access-checker/provider';
import { AccessKey } from 'src/access-checker/types';

type Tab = {
  title: string;
  mobileTitle?: string;
  url: string;
  key: string;
  accessKey?: AccessKey;
};

type ButtonItem = ButtonProps & {
  buttonText: React.ReactNode;
  buttonTextMobile?: React.ReactNode;
};

type Props = {
  tabs: Tab[];
  button?: string;
  buttonUrl?: string;
  onButtonClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  offset?: boolean;
  buttons?: ButtonItem[];
  customActions?: React.ReactNode;
};

export const TabNavigation: React.FC<Props> = ({
  tabs,
  button,
  buttonUrl,
  onButtonClick,
  className = '',
  offset = true,
  buttons,
  customActions,
}) => {
  const history = useHistory();
  const activeTab = tabs.find(
    (tab) => history.location.pathname.search(tab.key) !== -1,
  );
  const activeKey = activeTab ? activeTab.key : undefined;
  const accessState = useContext(AccessContext);

  return (
    <div className={className}>
      <div
        className={`wrapper container tab-navigation__container ${
          offset && 'offset-bottom'
        }`}
      >
        <div className="tab-navigation__nav">
          <Menu mode="horizontal" activeKey={activeKey}>
            {tabs
              .filter(
                ({ accessKey }) =>
                  !(
                    accessKey &&
                    accessState &&
                    accessState[accessKey] === ACCESS_DENIED
                  ),
              )
              .map(({ key, url, title, mobileTitle }) => (
                <Menu.Item key={key}>
                  <Link to={url}>
                    <span className="hide--sm">{title}</span>
                    <span className="hide-swap">{mobileTitle ?? title}</span>
                  </Link>
                </Menu.Item>
              ))}
          </Menu>
        </div>
        {button && (
          <div>
            <div className="container tab-navigation__button">
              <Button onClick={onButtonClick} href={buttonUrl} type="primary">
                {button}
              </Button>
            </div>
          </div>
        )}
        {buttons && (
          <div>
            <div className="container tab-navigation__button">
              {buttons.map(
                ({ buttonText, buttonTextMobile, ...buttonProps }, key) => (
                  <Button {...buttonProps} key={key}>
                    <span>
                      <span className="hide--md">{buttonText}</span>
                      <span className="hide-swap">
                        {buttonTextMobile ?? buttonText}
                      </span>
                    </span>
                  </Button>
                ),
              )}
            </div>
          </div>
        )}
        {customActions && (
          <div>
            <div className="container tab-navigation__button">
              {customActions}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabNavigation;
