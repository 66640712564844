import { ACCESS_DENIED } from './access-types';
import { AccessAction, SET_ACCESS, SET_ACCESS_KEY } from './actions';
import { keys } from './keys';
import { AccessState } from './types';

export const defaultState = keys.reduce((acc, elem) => {
  acc[elem] = ACCESS_DENIED;
  return acc;
}, {}) as AccessState;

export const accessContextReducer = (
  state: AccessState = defaultState,
  action: AccessAction,
): AccessState => {
  switch (action.type) {
    case SET_ACCESS:
      console.log('access state: ', action.payload);
      return action.payload;
    case SET_ACCESS_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

export default accessContextReducer;
