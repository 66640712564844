import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FormItem from 'src/components/form-item';

type InputProps = { name?: string; label?: string; disabled?: boolean };

export const SwiftInput: React.FC<InputProps> = ({
  name = 'swift',
  label,
  disabled,
}) => {
  const [t] = useTranslation();
  return (
    <FormItem
      label={label ?? t('banking.form.swift')}
      name={name}
      rules={[
        {
          required: !disabled,
          message: t('banking.form.errors.swift_required'),
        },
      ]}
    >
      <Input disabled={disabled} />
    </FormItem>
  );
};
export const BankNameInput: React.FC<InputProps> = ({
  name = 'bank_name',
  label,
  disabled,
}) => {
  const [t] = useTranslation();
  return (
    <FormItem
      label={label ?? t('banking.form.bank_name')}
      name={name}
      rules={[
        {
          required: !disabled,
          message: t('banking.form.errors.bank_name_required'),
        },
      ]}
    >
      <Input disabled={disabled} />
    </FormItem>
  );
};
export const BankAddressInput: React.FC<InputProps> = ({
  name = 'bank_address',
  label,
  disabled,
}) => {
  const [t] = useTranslation();
  return (
    <FormItem
      label={label ?? t('banking.form.bank_address')}
      name={name}
      rules={[
        {
          required: !disabled,
          message: t('banking.form.errors.bank_address_required'),
        },
      ]}
    >
      <Input disabled={disabled} placeholder={''} />
    </FormItem>
  );
};
export const IbanInput: React.FC<InputProps> = ({
  name = 'iban',
  label,
  disabled,
}) => {
  const [t] = useTranslation();
  return (
    <FormItem
      label={label ?? t('banking.form.iban')}
      name={name}
      rules={[
        {
          required: !disabled,
          message: t('banking.form.errors.iban_required'),
        },
      ]}
    >
      <Input disabled={disabled} />
    </FormItem>
  );
};
