import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOME_URL } from '@common/routes';
import logo from 'src/images/logo/logo.svg';

const { Title } = Typography;

export const GuestLogo: React.FC = () => {
  const [t] = useTranslation();
  return (
    <Space
      className="full-width layout-guest-logo"
      direction="vertical"
      align="center"
      size={18}
    >
      <Link to={HOME_URL}>
        <img src={logo} alt="Logo" />
      </Link>
      <Link to={HOME_URL}>
        <Title level={2} className="layout-guest-logo-text">
          {t('header_navigation.logo')}
        </Title>
      </Link>
    </Space>
  );
};

export default GuestLogo;
