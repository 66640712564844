import { Descriptions, Spin } from 'antd';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PROFILE } from '@common/routes';
import {
  useVesselInfoQuery,
  useUpdateCompanyVesselMutation,
} from '@gql/auth.urql';
import Access from 'src/access-checker/access';
import { AccessKey } from 'src/access-checker/types';
import ConfirmationModal from 'src/components/confirmation-modal';
import FormTitle from 'src/components/form-title';

type Props = {
  deleteAccessKey?: AccessKey;
};

type Params = { vessel_id: string };
type DescriptionItem = { [key: string]: string | undefined };
export type VesselDescription = DescriptionItem & {
  meta: DescriptionItem & {
    additional: DescriptionItem;
  };
};

export const renderDescription = (
  vessel: VesselDescription,
  shortMode?: boolean,
) => {
  const { additional } = vessel.meta;
  if (shortMode) {
    return (
      <Descriptions layout="horizontal" column={1}>
        <Descriptions.Item label={t('profile.imo')}>
          {vessel.meta.imo ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('profile.mmsi')}>
          {vessel.meta.mmsi ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('profile.flag')}>
          {vessel.meta.countryCode ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('profile.gross_tonnage')}>
          {additional.gross_tonnage ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('profile.owner')}>
          {additional.owner ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('profile.manager')}>
          {additional.manager ?? '-'}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions layout="horizontal" column={1}>
      <Descriptions.Item label={t('profile.vessel_type_generic')}>
        {additional.vessel_type_generic}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.vessel_type_detailed')}>
        {additional.vessel_type}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.status')}>
        {additional.status}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.imo')}>
        {vessel.meta.imo}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.mmsi')}>
        {vessel.meta.mmsi}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.call_sign')}>
        {additional.callsign}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.flag')}>
        {vessel.meta.countryCode}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.gross_tonnage')}>
        {additional.gross_tonnage}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.summer_dwt')}>
        {additional.summer_dwt}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.length_averall')}>
        {additional.length_overall}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.year_built')}>
        {additional.build}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.home_port')}>
        {additional.place_of_build}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.classification_society')}>
        {additional.classification_society}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.builder')}>
        {additional.builder}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.owner')}>
        {additional.owner}
      </Descriptions.Item>
      <Descriptions.Item label={t('profile.manager')}>
        {additional.manager}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const CompaniesVesselDetails: React.FC<Props> = ({
  deleteAccessKey = 'companies.vessels.details.button-delete-choosed-vessel',
}) => {
  const [t] = useTranslation();
  const { vessel_id: id } = useParams<Params>();
  const [, updateVessel] = useUpdateCompanyVesselMutation();
  const history = useHistory();
  const [{ data, fetching }] = useVesselInfoQuery({
    variables: { id },
  });
  const vessel = data?.vessel;

  const handlDelete = async () => {
    const { data } = await updateVessel(
      { id, data: { active: false } },
      { additionalTypenames: ['company_vessel'] },
    );
    if (data) {
      history.push(PROFILE.COMPANY_PROFILE_VESSELS);
    }
    return { status: !!data };
  };

  if (fetching || !vessel) {
    return <Spin />;
  }

  return (
    <div className="container">
      <div className="profile-bordered-view">
        <div className="profile-bordered-view__action">
          <Access accessKey={deleteAccessKey}>
            <ConfirmationModal
              onSubmit={handlDelete}
              button={t('profile.delete_choosed_vessel_button')}
            >
              {t('profile.delete_choosed_vessel')}
            </ConfirmationModal>
          </Access>
        </div>
        <div className="container">
          <FormTitle>{t('profile.vessel_details')}</FormTitle>
          {renderDescription(vessel as unknown as VesselDescription)}
        </div>
      </div>
    </div>
  );
};

export default CompaniesVesselDetails;
