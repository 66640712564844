import { Form, FormProps } from 'antd';
import {
  useState,
  createContext,
  useEffect,
  useReducer,
  ReactElement,
  PropsWithChildren,
} from 'react';

import {
  actionSetChangeHistory,
  actionSetInitialValues,
  actionSubmitBlock,
  actionSubmitError,
  actionSubmitFinish,
  actionSubmitStart,
} from './form-main-actions';
import {
  defaultState,
  ErrorMessage,
  formContextReducer,
} from './form-main-reducer';
import { DeepPartial } from '@common/types/util';

export type OnSubmitReturn = { status: boolean; error?: ErrorMessage };
export type PromiseOnSubmit<T> = (values: T) => Promise<OnSubmitReturn>;
export type FunctionOnSubmit<T> = (values: T) => OnSubmitReturn;
export type OnSubmitType<T> = (
  values: T,
) => Promise<OnSubmitReturn> | OnSubmitReturn;
export type FormMainProps<T> = Omit<FormProps<T>, 'initialValues'> & {
  onSubmit: OnSubmitType<T>;
  initialValues?: DeepPartial<T>;
  changeHistory?: Partial<T>[];
};

const isPromise = <T,>(p: T | Promise<T>): p is Promise<T> =>
  p && typeof p === 'object' && 'then' in p;

export const FormLoadingContext = createContext(defaultState);

export const FormMain = <T,>({
  onSubmit,
  changeHistory,
  ...rest
}: PropsWithChildren<FormMainProps<T>>): ReactElement => {
  const [state, dispatch] = useReducer(formContextReducer, {
    ...defaultState,
    initialValues: rest.initialValues,
    changeHistory,
  });
  const [res, setRes] = useState<OnSubmitReturn | unknown>();

  useEffect(() => {
    if (typeof res !== 'undefined') {
      if (typeof res === 'object' && res && 'status' in res) {
        dispatch(actionSubmitFinish(res as OnSubmitReturn));
      } else {
        dispatch(actionSubmitError(res));
      }
    }
  }, [res]);

  useEffect(() => {
    if (typeof changeHistory !== 'undefined')
      dispatch(actionSetChangeHistory(changeHistory));
  }, [changeHistory]);

  useEffect(() => {
    if (
      JSON.stringify(rest.initialValues) !== JSON.stringify(state.initialValues)
    ) {
      dispatch(actionSetInitialValues(rest.initialValues));
    }
  }, [rest.initialValues]);

  const handleFinish = (values: T) => {
    if (state.isLoading) {
      dispatch(actionSubmitBlock());
      return;
    }
    const ret = onSubmit(values);
    const pRet = isPromise(ret)
      ? ret
      : new Promise<OnSubmitReturn>((resolve) =>
          setTimeout(resolve, 1000, ret),
        );
    dispatch(actionSubmitStart(!isPromise(onSubmit)));
    pRet.then(setRes).catch(setRes);
  };

  return (
    <FormLoadingContext.Provider value={state}>
      <Form {...rest} onFinish={handleFinish} />
    </FormLoadingContext.Provider>
  );
};

export default FormMain;
