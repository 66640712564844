import { t } from 'i18next';

import type { Templates } from './types';
import { NotificationType } from '@common/notifications';
import { SUPPLIERS } from '@common/routes';
import TemplateLayout from 'src/layouts/notifications/notification';

export const templates: Templates = {
  [NotificationType.ShipownerRfqExpirationRemind]: (notification) => (
    <TemplateLayout
      href={SUPPLIERS.RFQ.replace(':id', notification.order)}
      title={t('notifications.shipowner_rfq_expiration_remind')}
      description={t(
        'notifications.shipowner_rfq_expiration_remind_description',
      )}
    ></TemplateLayout>
  ),
  [NotificationType.OrderChanged]: (notification) => (
    <TemplateLayout
      href={SUPPLIERS.RFQ.replace(':id', notification.order)}
      title={t('notifications.order_changed')}
      description={t('notifications.order_changed_description')}
    ></TemplateLayout>
  ),
  [NotificationType.ShipownerNewOffer]: (notification) => (
    <TemplateLayout
      title={t('notifications.shipowner_new_offer')}
      href={SUPPLIERS.RFQ.replace(':id', notification.order)}
      description={t('notifications.shipowner_new_offer_description', {
        rfq: notification.order,
      })}
    ></TemplateLayout>
  ),
  [NotificationType.SupplierNewRfq]: (notification) => (
    <TemplateLayout
      title={t('notifications.supplier_new_rfq')}
      href={SUPPLIERS.RFQ.replace(':id', notification.order)}
      description={t('notifications.supplier_new_rfq_description', {
        rfq: notification.order,
      })}
    ></TemplateLayout>
  ),
  [NotificationType.TEXT]: ({ title, href, text }) => (
    <TemplateLayout title={title} href={href}>
      {text}
    </TemplateLayout>
  ),
  default: (notification) => (
    <TemplateLayout
      title={t('notifications.unknown')}
      description={JSON.stringify(notification)}
    ></TemplateLayout>
  ),
};

export default templates;
