import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  Route,
} from 'react-router-dom';

import 'src/i18n';
import AccessProvider from './access-checker/provider';
import { PrivateRoute } from './components/private-route';
import { PublicRoute } from './components/public-route';
import { GraphqlProvider } from './graphql/provider';
import NotoficationsProvider from './notifications/provider';
import AccountApp from './pages/account/account-app';
import AdminApp from './pages/admin/admin-app';
import BankingApp from './pages/banking/banking-app';
import KycApp from './pages/kyc/kyc-app';
import MarketWatchApp from './pages/market-watch/market-watch-app';
import ProfileApp from './pages/profile/profile-app';
import RequestRoleApp from './pages/request-role/request-role-app';
import RoutePlanApp from './pages/route-plan/route-plan-app';
import ShipownersApp from './pages/shipowners/shipowners-app';
import SuppliersApp from './pages/suppliers/suppliers-app';
import {
  ACCOUNT,
  ADMIN,
  BANKING,
  ERROR_FALLBACK,
  KYC,
  MARKET_WATCH,
  PROFILE,
  REQUEST_FOR_ROLE,
  ROUTE_PLAN,
  SHIPOWNERS,
  SUPPLIERS,
} from '@common/routes';
import { ReCaptchaV2Provider } from 'src/components/recaptcha-v2';

export const App: React.FC = () => (
  <GraphqlProvider>
    <ReCaptchaV2Provider sitekey={import.meta.env.VITE_RECAPTCHA_SITEKEY}>
      <AccessProvider cpanel>
        <NotoficationsProvider>
          <Router>
            <Switch>
              <PublicRoute path={ACCOUNT.APP} component={AccountApp} />
              <PrivateRoute
                accessProps={{ accessKey: 'banking.app', show403: true }}
                path={BANKING.APP}
                component={BankingApp}
              />
              <PrivateRoute
                accessProps={{ accessKey: 'kyc.app', show403: true }}
                path={KYC.APP}
                component={KycApp}
              />
              <PrivateRoute
                accessProps={{
                  accessKey: 'request-role.app',
                  show403: true,
                }}
                path={REQUEST_FOR_ROLE.APP}
                component={RequestRoleApp}
              />
              <PrivateRoute
                accessProps={{ accessKey: 'profile.app', show403: true }}
                path={PROFILE.APP}
                component={ProfileApp}
              />
              <PrivateRoute
                accessProps={{ accessKey: 'shipowners.app', show403: true }}
                path={SHIPOWNERS.APP}
                component={ShipownersApp}
              />
              <PrivateRoute
                accessProps={{ accessKey: 'suppliers.app', show403: true }}
                path={SUPPLIERS.APP}
                component={SuppliersApp}
              />
              <Route path={ROUTE_PLAN.APP} component={RoutePlanApp} />
              <Route path={MARKET_WATCH.APP} component={MarketWatchApp} />
              <PrivateRoute
                accessProps={{ accessKey: 'admin.app', show403: true }}
                path={ADMIN.APP}
                component={AdminApp}
              />
              <Redirect to={ERROR_FALLBACK} />
            </Switch>
          </Router>
        </NotoficationsProvider>
      </AccessProvider>
    </ReCaptchaV2Provider>
  </GraphqlProvider>
);
