import { WarningOutlined } from '@ant-design/icons';
import { Menu, Space, Typography, Avatar, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Md5 } from 'ts-md5';

import { CONTACT_US_EMAIL, CONTACT_US_PHONE } from '@common/contacts';
import { KYC, PROFILE } from '@common/routes';
import Access from 'src/access-checker/access';
import { useAuth } from 'src/hooks/auth';

const { Text } = Typography;

export const ProfileMenu: React.FC = () => {
  const [t] = useTranslation();
  const { logout, profileInfo, role } = useAuth();
  const history = useHistory();
  const menu = (
    <Menu>
      {profileInfo && (
        <>
          <Menu.Item
            key="profile-menu:company"
            className="header-profile-menu__item"
          >
            <Typography.Title className="offset-bottom__none-i" level={5}>
              Company
            </Typography.Title>
            <Typography.Link
              href={`mailto:${profileInfo.email}`}
              className="header-profile-menu__item--small"
            >
              {profileInfo.email}
            </Typography.Link>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item
        key="contact-menu:contact-support"
        className="header-profile-menu__item header-profile-menu__item--small header-profile-menu__mobile-item"
      >
        <div>
          <div>
            <Typography.Text>
              {t('header_navigation.contact_support')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Link href={`mailto:${CONTACT_US_EMAIL}`}>
              {CONTACT_US_EMAIL}
            </Typography.Link>
          </div>
          <div>
            <Typography.Link href={`tel:${CONTACT_US_PHONE}`}>
              {CONTACT_US_PHONE}
            </Typography.Link>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item
        key="contact-menu:live-chat"
        className="header-profile-menu__item  header-profile-menu__mobile-item"
        onClick={() => {
          window.$chatwoot?.toggle();
        }}
      >
        {t('header_navigation.live_chat')}{' '}
      </Menu.Item>
      <Menu.Divider />
      <Access accessKey="kyc.app.menu">
        <Menu.Item
          className="header-profile-menu__item"
          onClick={() => history.push(KYC.APP)}
          key="profile-menu:kyc-registration"
        >
          {t('header_navigation.kyc_registration')}
        </Menu.Item>
      </Access>
      <Menu.Item
        className="header-profile-menu__item"
        key="profile-menu:settings"
      >
        {t('header_navigation.settings')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        className="header-profile-menu__item"
        onClick={() => history.push(PROFILE.APP)}
        key="profile-menu:profile"
      >
        <Space className="full-width space-beetween">
          <span>{t('header_navigation.profile')}</span>
          {role === 'regular' && (
            <Typography.Text type="danger">
              {t('header_navigation.not_verified')} <WarningOutlined />
            </Typography.Text>
          )}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        className="header-profile-menu__item cy-profile-menu-sign-out"
        key="profile-menu:sign-out"
        onClick={logout}
      >
        {t('header_navigation.sign_out')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomLeft"
      arrow
      className="header-profile-menu"
    >
      <Space>
        {profileInfo && (
          <Avatar
            src={
              'https://www.gravatar.com/avatar/' +
              Md5.hashStr(profileInfo.email) +
              '.jpg?d=retro&r=g&s=50'
            }
          />
        )}
        <Space
          size={5}
          direction="vertical"
          className="header-profile-menu-username"
        >
          <Text className="header-profile-menu-email">
            {profileInfo?.email}
          </Text>
          <Text className="header-profile-menu-role">
            {t(`role_name.${role}`)}
          </Text>
        </Space>
      </Space>
    </Dropdown>
  );
};

export default ProfileMenu;
