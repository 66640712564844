// Every time a new email template is created, a new interface must be
// written below. It's fields must have the same shape as dynamic data inside
// mjml template. If the template doesn't contain any dynamic data,
// just provide `{}`
//

import { BankInfo } from '../bank-info';

export enum TemplateName {
  TEST = 'test',
  ACCOUNT_EXISTING = 'account-existing',
  ACCOUNT_FINISH_SETUP_REMINDER = 'account-finish-setup-reminder',
  ACCOUNT_ACCOUNT_IS_NOW_BLCOKED = 'account-is-now-blocked',
  ACCOUNT_REGISTRATION_CODE = 'account-registration-code',
  ACCOUNT_REGISTRATION_INCORRECT_PIN_CODE = 'account-registration-incorrect-pin-code',
  ACCOUNT_WELCOME = 'account-welcome',
  CHANGE_REQUEST = 'change-request',
  CONFIRMATION_CODE = 'confirmation-code',
  EMPLOYEE_REGISTRATION = 'employee-registration',
  HOME_PAGE = 'home-page',
  PASSWORD_CHANGE_CODE = 'password-change-request', // pragma: allowlist secret
  PASSWORD_INCORRECT_PIN = 'password-incorrect-pin', // pragma: allowlist secret
  PASSWORD_RECOVERY_CODE = 'password-recovery-code', // pragma: allowlist secret
  PASSWORD_RESET_SUCCESSFULLY = 'password-reset-successfully', // pragma: allowlist secret
  RFQ_BDN_UPLOAD_REMINDER = 'rfq-bdn-upload-reminder',
  RFQ_BUNKER_DELIVERY_COMLETED_TO_SHIPOWNER = 'rfq-bunker-delivery-completed-to-shipowner',
  RFQ_BUNKER_DELIVERY_COMLETED_TO_SUPPLIER = 'rfq-bunker-delivery-completed-to-supplier',
  RFQ_CONFIRMATION_REQUEST_PIN = 'rfq-confirmation-request-pin',
  RFQ_NEW_REQUEST_CREATED = 'rfq-created',
  RFQ_EXPIRING_SOON = 'rfq-expiring-soon',
  RFQ_FINAL_AMOUNT_REMINDER = 'rfq-final-amount-confirm-reminder',
  RFQ_OFFER_HAS_BEEN_ACCEPTED = 'rfq-offer-has-been-accepted',
  TRANSACTIONS_FUNDS_DEPOSITED_TO_ACCOUNT = 'transactions-funds-deposited-to-account',
  TRANSACTIONS_REQUEST_TO_WITHDRAW_FUNDS = 'transactions-request-to-withdraw-funds',
  TRANSACTIONS_DEPOSIT_BANK_INFO = 'transactions-deposit-bank-info',
}

type RfqProps = {
  rfqId: string;
  ship: string;
  port: string;
  products: {
    product: string;
    amount: string;
    estimatedQuantity: string;
    order: number;
  }[];
};

type ConfirmationTemplate = { code: string };

export type TemplateData = {
  [TemplateName.TEST]: Record<string, never>;
  [TemplateName.ACCOUNT_EXISTING]: {
    linkForgot: string;
    linkLogin: string;
    email: string;
  };
  [TemplateName.ACCOUNT_FINISH_SETUP_REMINDER]: {
    signInLink: string;
    signUpLink: string;
  };
  [TemplateName.ACCOUNT_ACCOUNT_IS_NOW_BLCOKED]: {
    link: string;
  };
  [TemplateName.ACCOUNT_REGISTRATION_CODE]: {
    activationLink: string;
  } & ConfirmationTemplate;
  [TemplateName.ACCOUNT_REGISTRATION_INCORRECT_PIN_CODE]: {
    link: string;
  };
  [TemplateName.ACCOUNT_WELCOME]: {
    guideUrl: string;
  };
  [TemplateName.CHANGE_REQUEST]: {
    message: string;
  } & ConfirmationTemplate;
  [TemplateName.CONFIRMATION_CODE]: ConfirmationTemplate;
  [TemplateName.EMPLOYEE_REGISTRATION]: {
    link: string;
  };
  [TemplateName.HOME_PAGE]: Record<string, never>;
  [TemplateName.PASSWORD_RECOVERY_CODE]: ConfirmationTemplate;
  [TemplateName.PASSWORD_INCORRECT_PIN]: Record<string, never>;
  [TemplateName.PASSWORD_CHANGE_CODE]: ConfirmationTemplate;
  [TemplateName.PASSWORD_RESET_SUCCESSFULLY]: Record<string, never>;
  [TemplateName.RFQ_BDN_UPLOAD_REMINDER]: {
    supplier: string;
    link: string;
  } & RfqProps;
  [TemplateName.RFQ_BUNKER_DELIVERY_COMLETED_TO_SHIPOWNER]: {
    supplier: string;
    date: string;
    link: string;
  } & RfqProps;
  [TemplateName.RFQ_BUNKER_DELIVERY_COMLETED_TO_SUPPLIER]: {
    supplier: string;
    date: string;
    link: string;
  } & RfqProps;
  [TemplateName.RFQ_CONFIRMATION_REQUEST_PIN]: {
    eta: string;
    link: string;
    supplier: string;
  } & RfqProps &
    ConfirmationTemplate;
  [TemplateName.RFQ_NEW_REQUEST_CREATED]: {
    eta: string;
  } & RfqProps;
  [TemplateName.RFQ_EXPIRING_SOON]: {
    eta: string;
    link: string;
  } & RfqProps;
  [TemplateName.RFQ_FINAL_AMOUNT_REMINDER]: {
    eta: string;
    link: string;
  } & RfqProps;
  [TemplateName.RFQ_OFFER_HAS_BEEN_ACCEPTED]: {
    eta: string;
    link: string;
  } & RfqProps;
  [TemplateName.TRANSACTIONS_FUNDS_DEPOSITED_TO_ACCOUNT]: {
    transactionId: string;
    amount: string;
    sendingName: string;
    sendingBank: string;
    referenceNumber: string;
  };
  [TemplateName.TRANSACTIONS_REQUEST_TO_WITHDRAW_FUNDS]: {
    transactionId: string;
    requestDate: string;
    amount: string;
    receivingName: string;
    receivingBank: string;
    receivingAccount: string;
  } & ConfirmationTemplate;
  [TemplateName.TRANSACTIONS_DEPOSIT_BANK_INFO]: BankInfo;
  // 'example-no-data': {};
  // 'example-with-data': { user: string };
};

export type ConfirmationCodeTemplateName = keyof {
  [K in keyof TemplateData as TemplateData[K] extends ConfirmationTemplate
    ? K
    : never]: unknown;
};
