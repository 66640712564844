import { InputNumber, InputNumberProps } from 'antd';

export type TimeUnit = 'seconds' | 'minutes' | 'hours' | 'days';

type Props = {
  unit: TimeUnit;
  // outputUnit?: 'seconds';
} & InputNumberProps<number>;

const secondsInUnit: Record<TimeUnit, number> = {
  seconds: 1,
  minutes: 60,
  hours: 60 * 60,
  days: 60 * 60 * 24,
};

export const daysToInputValue = (d: number) => d * secondsInUnit['days'];
export const hoursToInputValue = (d: number) => d * secondsInUnit['hours'];
export const minutesToInputValue = (d: number) => d * secondsInUnit['minutes'];
export const convert = (value: number | undefined, unit: TimeUnit) =>
  value ? value / secondsInUnit[unit] : undefined;

export const PeriodInput: React.FC<Props> = ({
  value,
  unit,
  onChange,
  ...rest
}) => (
  <InputNumber
    value={convert(value, unit)}
    onChange={(v) => onChange?.(v * secondsInUnit[unit])}
    {...rest}
  />
);
