import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PROFILE } from '@common/routes';
import { PrivateRoute } from 'src/components/private-route';
import { LayoutMain } from 'src/layouts/main/main';
import LayoutProfile from 'src/layouts/profile/profile';
const ProfilePage = lazy(() => import('./profile-page'));
const CompaniesPage = lazy(() => import('./companies-page'));

export const ProfileApp: React.FC = () => (
  <LayoutMain>
    <LayoutProfile>
      <Switch>
        <Route path={PROFILE.USER_PROFILE} component={ProfilePage} />
        <PrivateRoute
          accessProps={{
            accessKey: 'profile.company-profile',
            show403: true,
          }}
          path={PROFILE.COMPANY_PROFILE}
          component={CompaniesPage}
        />
        <Redirect to={PROFILE.USER_PROFILE} />
      </Switch>
    </LayoutProfile>
  </LayoutMain>
);

export default ProfileApp;
