/* eslint-disable import/order */
import i18next, { ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

export const i18nextInstance = i18next
  .use(
    resourcesToBackend((language, _namespace, callback) => {
      let mod: Promise<ResourceKey>;
      switch (language) {
        case 'uk':
          mod = import(`@common/locales/uk/translation.json`);
          break;
        case 'ru':
          mod = import(`@common/locales/ru/translation.json`);
          break;
        default:
          mod = import(`@common/locales/en/translation.json`);
          break;
      }
      mod.then((mod) => callback(null, mod));
    }),
  )
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: import.meta.env.MODE === 'development',
  });
