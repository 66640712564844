import { FormProps } from 'antd';

import { OnSubmitReturn } from './form-main';
export const FORM_MAIN_ON_SUBMIT_START = 'FORM_MAIN_ON_SUBMIT_START';
export const FORM_MAIN_ON_SUBMIT_FINISH = 'FORM_MAIN_ON_SUBMIT_FINISH';
export const FORM_MAIN_ON_SUBMIT_ERROR = 'FORM_MAIN_ON_SUBMIT_ERROR';
export const FORM_MAIN_ON_SUBMIT_BLOCK = 'FORM_MAIN_ON_SUBMIT_BLOCK';
export const FORM_SET_INITIAL_VALUES = 'FORM_SET_INITIAL_STATE';
export const FORM_SET_CHANGE_HISTORY = 'FORM_SET_CHANGE_HISTORY';

type ActionStart = {
  type: typeof FORM_MAIN_ON_SUBMIT_START;
  payload: boolean;
};
type ActionFinish = {
  type: typeof FORM_MAIN_ON_SUBMIT_FINISH;
  payload: OnSubmitReturn;
};
type ActionError = {
  type: typeof FORM_MAIN_ON_SUBMIT_ERROR;
  payload: OnSubmitReturn;
};
type ActionBlock = {
  type: typeof FORM_MAIN_ON_SUBMIT_BLOCK;
};
type ActionSetInitialValues = {
  type: typeof FORM_SET_INITIAL_VALUES;
  payload: FormProps['initialValues'];
};
type ActionSetChangeHistory = {
  type: typeof FORM_SET_CHANGE_HISTORY;
  payload: FormProps['initialValues'][];
};

export type FormAction =
  | ActionStart
  | ActionFinish
  | ActionError
  | ActionBlock
  | ActionSetChangeHistory
  | ActionSetInitialValues;

export const actionSubmitStart = (isFakeLoading: boolean): FormAction => ({
  type: FORM_MAIN_ON_SUBMIT_START,
  payload: isFakeLoading,
});

export const actionSetInitialValues = (
  initialValues: FormProps['initialValues'],
): FormAction => ({
  type: FORM_SET_INITIAL_VALUES,
  payload: initialValues,
});
export const actionSetChangeHistory = (
  changeHistory: FormProps['initialValues'][],
): FormAction => ({
  type: FORM_SET_CHANGE_HISTORY,
  payload: changeHistory,
});

export const actionSubmitFinish = (result: OnSubmitReturn): FormAction => ({
  type: FORM_MAIN_ON_SUBMIT_FINISH,
  payload: result,
});

export const actionSubmitError = (error: any): FormAction => {
  let errorMessage = '';
  if (typeof error === 'string') errorMessage = error;
  if (typeof error === 'object') errorMessage = JSON.stringify(error);
  if (Array.isArray(error)) errorMessage = error.join();

  return {
    type: FORM_MAIN_ON_SUBMIT_ERROR,
    payload: { status: false, error: { msg: errorMessage } },
  };
};
export const actionSubmitBlock = (): FormAction => ({
  type: FORM_MAIN_ON_SUBMIT_BLOCK,
});
