import { Spin } from 'antd';
import { Suspense } from 'react';

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Loading: React.FC = () => (
  <div className="layout-loader">
    {/* <Spin indicator={antIcon} /> */}
    <Spin />
  </div>
);

const LayoutLoader: React.FC = ({ children }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

export default LayoutLoader;
