import { notification } from 'antd';
import { createClient as createWsClient } from 'graphql-ws';
import i18next from 'i18next';
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  Exchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql';

import { additionalTypenamesExchange } from './additional-typenames-exchange';
import { cookieAuth, UnauthorizedError } from './cookie-auth';

const {
  VITE_HASURA_SERVICE_SCHEMA,
  VITE_HASURA_SERVICE_PORT,
  VITE_HASURA_SERVICE_NAME,
  MODE,
} = import.meta.env;

const GQL_URL = '/v1/graphql';
const WS_SCHEMA = VITE_HASURA_SERVICE_SCHEMA === 'https' ? 'wss' : 'ws';
let WS_URL = `${WS_SCHEMA}://${VITE_HASURA_SERVICE_NAME}:${VITE_HASURA_SERVICE_PORT}${GQL_URL}`;
let HTTP_BASE_URL = `${VITE_HASURA_SERVICE_SCHEMA}://${VITE_HASURA_SERVICE_NAME}:${VITE_HASURA_SERVICE_PORT}`;

if (MODE === 'development') {
  // Handled by vite proxy
  HTTP_BASE_URL = '';
  WS_URL = `ws://${location.host}${GQL_URL}`;
}

export { HTTP_BASE_URL, WS_URL };

export function createFetchOptions(opts: RequestInit = {}): RequestInit {
  return {
    ...opts,
    credentials: 'include',
  };
}

const restOnlyOperations = ['ResetPassword', 'Register', 'ConfirmEmail'];

export function initializeClient() {
  let wsInstance: WebSocket | undefined;

  const closeWebsocket = () => {
    if (wsInstance) {
      wsInstance.close(1000, 'New JWT needs to be used by the connection');
    }
  };

  const wsClient = createWsClient({
    url: WS_URL,
    retryAttempts: 0,
    lazyCloseTimeout: 2000,
    on: {
      connected(data) {
        wsInstance = data as WebSocket;
      },
      closed() {
        wsInstance = undefined;
      },
    },
  });

  const { exchange: cookieAuthExchange, authStateSource } = cookieAuth();

  const exchanges: Exchange[] = [
    additionalTypenamesExchange(),
    dedupExchange,
    errorExchange({
      onError(error) {
        if (error.networkError) {
          if (error.networkError instanceof UnauthorizedError) return;
          if (Array.isArray(error.networkError)) {
            // NOTE: error array goes to the networkError instead of
            // graphqlErrors when using websocket. Probably a bug in graphql-ws
            // or Hasura.
            const errors = error.networkError as typeof error.graphQLErrors;
            for (const err of errors) {
              notification.error({
                message: i18next.t('error_notification'),
                description: i18next.t(err.message, err.extensions.t),
              });
            }
          } else {
            notification.error({
              message: i18next.t('error_notification'),
              description: error.networkError.message,
            });
          }
        } else {
          for (const err of error.graphQLErrors) {
            notification.error({
              message: i18next.t('error_notification'),
              description: i18next.t(err.message, err.extensions.t),
            });
          }
        }
        console.error('error', error);
      },
    }),
    cookieAuthExchange,
    cacheExchange,
    subscriptionExchange({
      // Run query/mutation/subscription over websocket
      // enableAllOperations: true,
      isSubscriptionOperation(operation) {
        const [def] = operation.query.definitions;
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
          def.kind === 'OperationDefinition' &&
          def.name &&
          restOnlyOperations.includes(def.name.value)
        ) {
          return false;
        }
        return true;
      },
      forwardSubscription: (operation) => ({
        subscribe(sink) {
          const unsubscribe = wsClient.subscribe(operation, sink);
          return { unsubscribe };
        },
      }),
    }),
    fetchExchange,
  ];

  const fetchOptions = () => {
    const queryParams = new URLSearchParams(location.search);
    const ciHcaptchaSecret = queryParams.get('ci');
    const opts: RequestInit = {
      credentials: 'include',
      headers: {
        // X-Forwared-Origin still is not in stable Hasura, so we explicitly
        // pass the Origin in a separate header
        'client-origin': location.origin,
      },
    };
    if (ciHcaptchaSecret && opts.headers) {
      opts.headers['ci-hcaptcha-secret'] = ciHcaptchaSecret;
    }
    return opts;
  };

  const client = createClient({
    url: `${HTTP_BASE_URL}${GQL_URL}`,
    fetchOptions,
    exchanges,
  });

  return { client, authStateSource, closeWebsocket };
}
