import { Form, notification } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateRequestForm from './create-request-form';
import { initialDefaultValues } from './trading-order-details';
import { useShipPortsQuery } from '@common/__generated__/auth.urql';
import { useCreateShipownerRequestMutation } from '@common/__generated__/trading.urql';
import { Uuid } from '@common/db-types';
import { OrderFormValues } from '@common/trading/frontend';
import { DeepPartial } from '@common/types/util';
import FormModal, { FormProps } from 'src/components/form-modal';
import { getRequestFormValues } from 'src/components/helpers';
import { CREATE_REQUEST_MODAL_WIDTH } from 'src/config/styles';

const accessKeys = {
  add_product: 'shipowners.rfq.add-product',
  additional_info: 'shipowners.rfq.f.additional_info',
  attachments: 'shipowners.rfq.attachments',
  cia: 'shipowners.rfq.f.cia',
  country: 'shipowners.rfq.f.country',
  currency: 'shipowners.rfq.f.currency',
  eta_etd: 'shipowners.rfq.f.eta_etd',
  measure: 'shipowners.rfq.f.product.measure',
  port: 'shipowners.rfq.f.port',
  price: 'shipowners.rfq.f.product.price',
  final_qty: 'shipowners.rfq.f.product.final_qty',
  product_meta_id: 'shipowners.rfq.f.product.product_meta_id',
  remove_product: 'shipowners.rfq.f.remove-product',
  time_for_quality_claim: 'shipowners.rfq.f.time_for_quality_claim',
  time_for_quantity_claim: 'shipowners.rfq.f.time_for_quantity_claim',
  vessel_name: 'shipowners.rfq.f.vessel_name',
  volume_from_to: 'shipowners.rfq.f.product.volume_from_to',
  offer_validity_period: 'shipowners.rfq.f.offer_validity_period',
  order_validity_period: 'shipowners.rfq.f.order_validity_period',
} as const;

export const CreateRequestModal: React.FC = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm<OrderFormValues>();
  const [, createShipownerRequest] = useCreateShipownerRequestMutation();
  const [filterByCountry, setFilterByCountry] = useState<string | undefined>();
  const [disableCountry, setDisableCountry] = useState(false);
  const [{ data: shipPortData }] = useShipPortsQuery();
  const [usedProducts, setUsedProducts] = useState<Uuid[]>([]);

  return (
    <FormModal
      title={t('shipowner.modal_title')}
      width={CREATE_REQUEST_MODAL_WIDTH}
      formProps={
        {
          requiredMark: false,
          layout: 'vertical',
          form,
          initialValues: initialDefaultValues,
          onReset() {
            setDisableCountry(false);
            setUsedProducts([]);
            setFilterByCountry(undefined);
          },
          onValuesChange(changedValues: DeepPartial<OrderFormValues>, values) {
            if ('country' in changedValues) {
              setFilterByCountry(changedValues.country);
            }
            if ('port' in changedValues) {
              if (changedValues.port) {
                setDisableCountry(true);
                const port = shipPortData?.ports.find(
                  (p) => p.id === changedValues.port,
                );
                if (port) {
                  form.setFieldsValue({ country: port.countryCode });
                  setFilterByCountry(port.countryCode);
                }
              } else {
                setDisableCountry(false);
              }
            }
            setUsedProducts(
              values.products.map((p) => p?.product_meta_id).filter(Boolean),
            );
          },
          onSubmit: async ({ setVisibility }) => {
            const formValues = await getRequestFormValues(form);
            const { data } = await createShipownerRequest({ data: formValues });
            if (data) {
              form.resetFields();
              setDisableCountry(false);
              notification.success({ message: 'Submitted a new request' });
              setVisibility(false);
              return { status: true };
            }
            return { status: false };
          },
        } as FormProps<OrderFormValues>
      }
      buttonProps={{
        type: 'primary',
        buttonText: t('shipowner.navigation.button'),
      }}
      okButtonText={t('shipowner.form.submit_request')}
      cancelButtonText={t('shipowner.modal_cancel')}
    >
      <div className="container">
        <CreateRequestForm
          accessKeys={accessKeys}
          filterByCountry={filterByCountry}
          disabledFields={{
            country: disableCountry,
          }}
          usedProducts={usedProducts}
        />
      </div>
    </FormModal>
  );
};

export default CreateRequestModal;
