import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ACCOUNT } from '@common/routes';
import LayoutAccount from 'src/layouts/account/account';
import { LayoutMain } from 'src/layouts/main/main';
const LoginPage = lazy(() => import('./login'));
const RegisterPage = lazy(() => import('./register'));
const ForgotPasswordPage = lazy(() => import('./forgot-password'));
const EmailConfirmation = lazy(() => import('./email-confirmation'));
const ExpiredPage = lazy(() => import('./expired'));

export const AccountApp: React.FC = () => (
  <LayoutMain mode="guest">
    <LayoutAccount>
      <Switch>
        <Route path={ACCOUNT.APP} component={LoginPage} exact />
        <Route path={ACCOUNT.LOGIN} component={LoginPage} />
        <Route path={ACCOUNT.REGISTRATION} component={RegisterPage} />
        <Route path={ACCOUNT.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <Route
          path={ACCOUNT.EMAIL_CONFIRMATION}
          component={EmailConfirmation}
        />
        <Route path={ACCOUNT.EXPIRED} component={ExpiredPage} />
      </Switch>
    </LayoutAccount>
  </LayoutMain>
);

export default AccountApp;
