import { Dayjs } from 'dayjs';

import {
  OrderProductFragment,
  ShipownerOrderTableFragment,
} from '@common/__generated__/trading.urql';

export enum NotificationType {
  TEXT = 'text',
  ShipownerRfqExpirationRemind = 'shipowner:rfq_expiration_remind',
  /** Order was changed, for both supplier (offer) and shipowner (RFQ) */
  OrderChanged = 'order_change',
  /** Shipowner received a new offer */
  ShipownerNewOffer = 'shipowner:new_offer',
  SupplierNewRfq = 'supplier:new_rfq',
}

type VersionFields = {
  version_updated_at: string | Dayjs;
  version_is_deleted: boolean;
  total_price_com?: number;
};
export type RFQHistory = VersionFields & Partial<ShipownerOrderTableFragment>;
export type RFQProdcutsHistory = VersionFields & Partial<OrderProductFragment>;

type NotificationRFQBody = {
  createdAt: string;
  order: string;
  orderHistory: RFQHistory[];
  productHistory: RFQProdcutsHistory[];
};

export type NotificationSupplierNewRfq = {
  type: typeof NotificationType.SupplierNewRfq;
} & NotificationRFQBody;
export type NotificationShipownerNewOffer = {
  type: typeof NotificationType.ShipownerNewOffer;
} & NotificationRFQBody;
export type NotificationOrderChanged = {
  type: typeof NotificationType.OrderChanged;
} & NotificationRFQBody;
export type NotificationShipownerRfqExpirationRemind = {
  type: typeof NotificationType.ShipownerRfqExpirationRemind;
} & NotificationRFQBody;
export type NotificationText = {
  title: string;
  text: string | React.ReactNode;
  href?: string;
  type: NotificationType.TEXT;
};

export type NotificationRFQ =
  | NotificationSupplierNewRfq
  | NotificationShipownerNewOffer
  | NotificationOrderChanged
  | NotificationShipownerRfqExpirationRemind;

export type Notification = NotificationRFQ | NotificationText;

export type NotificationData = {
  [NotificationType.TEXT]: {
    text: string;
  };
  // [NotificationType.ShipownerRfqExpirationRemind]: {
  //   order_id: Uuid;
  //   expires_in: string;
  // };
  // [NotificationType.OrderChanged]: { order_id: Uuid };
  // [NotificationType.ShipownerNewOffer]: { order_id: Uuid };
  // [NotificationType.SupplierNewRfq]: { order_id: Uuid };
};
