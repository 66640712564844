import { Transfer, Form } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CurrenciesQuery,
  useCurrenciesQuery,
} from '@common/__generated__/auth.urql';
import {
  useCreateUserWalletMutation,
  useUserWalletsSubscription,
} from '@common/__generated__/banking.urql';
import { Uuid } from '@common/db-types';
import FormItem from 'src/components/form-item';
import FormModal, { FormProps } from 'src/components/form-modal';
import { SMALL_MODAL_WIDTH } from 'src/config/styles';
import { useAuth } from 'src/hooks';

type Props = {
  value?: Uuid[];
  onChange?(values: Uuid[]): void;
  currencies: CurrenciesQuery['currency'];
  disabled?: string[];
};

const CurrencySelect: React.FC<Props> = ({
  value,
  onChange,
  currencies,
  disabled = [],
}) => {
  const [t] = useTranslation();
  const filter = (inputValue: string, { title }) =>
    title.search(inputValue) !== -1;

  const dataSource = useMemo(
    () =>
      currencies.map((data) => ({
        ...data,
        title: data.code,
        key: data.id,
        disabled: disabled.includes(data.id),
      })),
    [currencies, disabled],
  );

  return (
    <Transfer
      selectedKeys={value}
      titles={[
        t('banking.form.add_new_currency_select', {
          count: value?.length ?? 0,
          total: currencies.length,
        }),
      ]}
      dataSource={dataSource}
      showSearch
      filterOption={filter}
      targetKeys={[]}
      render={(item) => item.code}
      onSelectChange={onChange}
      className="transfer-show-only-source transfer-hide-dropdown"
    />
  );
};

type FormValues = {
  currencies: Uuid[];
};

export const AddNewCurrencyModal = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [, createWallet] = useCreateUserWalletMutation();
  const [{ data: currencyData }] = useCurrenciesQuery();
  const { userId } = useAuth();
  const [{ data }] = useUserWalletsSubscription({
    variables: { userId },
    pause: !userId,
  });
  const usedWalletList: string[] = useMemo(
    () =>
      (data?.user?.company?.wallets ?? []).map(
        ({ currency = { id: '' } }) => currency.id,
      ),
    [data],
  );

  const dataSource = currencyData?.currency ?? [];

  return (
    <FormModal
      title={t('banking.add_new_currency_modal')}
      width={SMALL_MODAL_WIDTH}
      formProps={
        {
          form,
          requiredMark: false,
          layout: 'vertical',
          onSubmit: async ({ setVisibility, currencies }) => {
            const { data } = await createWallet({
              data: { currency: currencies },
            });
            if (data) setVisibility(false);
            return { status: !!data };
          },
        } as FormProps<FormValues>
      }
      buttonProps={{
        type: 'primary',
        buttonText: t('banking.navigation.add_new_currency_button'),
      }}
      okButtonText={t('banking.form.add_new_currency_submit')}
    >
      <div className="container padding-bottom">
        <FormItem name="currencies">
          <CurrencySelect currencies={dataSource} disabled={usedWalletList} />
        </FormItem>
      </div>
    </FormModal>
  );
};

export default AddNewCurrencyModal;
