export const keys = [
  // USER PAGES

  // navigation
  'nav.admin',
  'nav.shipowners',
  'nav.suppliers',
  'nav.banking',
  //'nav.route-plan',
  //'nav.market-watch',
  'nav.company',
  // navigation end

  // Banking app
  'banking.nav.your-balance',
  'banking.nav.bank-accounts',
  'banking.nav.internal',
  'banking.nav.external',

  'banking.app',
  'banking.app.transaction-filters',

  'banking.your-balance',
  'banking.your-balance.add-new-currency',
  'banking.your-balance.dont-show-empty-wallets',
  'banking.your-balance.table',

  'banking.bank-accounts',
  'banking.bank-accounts.table',
  'banking.bank-accounts.add-bank-accounts',
  'banking.bank-accounts.table.edit',
  'banking.bank-accounts.table.remove',

  'banking.internal',
  'banking.internal.table',
  'banking.internal.table-details',

  'banking.external',
  'banking.external.table',
  'banking.external.table-details',
  // Banking app end

  // KYC app
  'kyc.app',
  'kyc.app.menu',
  // KYC app end

  // Rquest Role App
  'request-role.app',
  // Rquest Role App end

  // Profile app
  'profile.nav.user-profile',
  'profile.nav.company-profile',
  'profile.app',
  'profile.company-profile',
  'profile.app.last-logins',
  'profile.app.session_timeout',
  'profile.app.delete-vessel',

  // Profile app end

  // Shipowners app
  'shipowners.app',
  'shipowners.app.request-for-quote',

  // - Shipowners Navigation
  'shipowners.nav.all-offers',
  'shipowners.nav.manage-request',
  // - Shipowners Navigation end

  // - Shipowners All Offers
  'shipowners.all-offers',
  'shipowners.all-offers.table',
  'shipowners.all-offers.table.show-only-valid',
  'shipowners.all-offers.table.modify-column-list',
  'shipowners.all-offers.table.filter',
  'shipowners.all-offers.details',
  'shipowners.all-offers.details.add-product',
  'shipowners.all-offers.details.edit-offer',
  'shipowners.all-offers.details.attachments',
  'shipowners.all-offers.details.f.remove-product',
  'shipowners.all-offers.details.f.product.product_meta_id',
  'shipowners.all-offers.details.f.product.volume_from_to',
  'shipowners.all-offers.details.f.product.measure',
  'shipowners.all-offers.details.f.product.price',
  'shipowners.all-offers.details.f.product.final_qty', // for admin
  'shipowners.all-offers.details.f.vessel_name',
  'shipowners.all-offers.details.f.country',
  'shipowners.all-offers.details.f.port',
  'shipowners.all-offers.details.f.eta_etd',
  'shipowners.all-offers.details.f.cia',
  'shipowners.all-offers.details.f.currency',
  'shipowners.all-offers.details.f.time_for_quantity_claim',
  'shipowners.all-offers.details.f.time_for_quality_claim',
  'shipowners.all-offers.details.f.offer_validity_period',
  'shipowners.all-offers.details.f.order_validity_period',
  'shipowners.all-offers.details.f.additional_info',
  'shipowners.all-offers.details.f.save',
  // - Shipowners All Offers end

  // - Manage Request Offers
  'shipowners.manage-request',
  'shipowners.manage-request.tab.rfq-offers',
  'shipowners.manage-request.tab.show-only-valid',
  'shipowners.manage-request.tab.modify-column-list',
  'shipowners.manage-request.tab.filter',
  'shipowners.manage-request.details',
  'shipowners.manage-request.details.add-product',
  'shipowners.manage-request.details.edit-offer',
  'shipowners.manage-request.details.attachments',
  'shipowners.manage-request.details.f.remove-product',
  'shipowners.manage-request.details.f.product.product_meta_id',
  'shipowners.manage-request.details.f.product.volume_from_to',
  'shipowners.manage-request.details.f.product.measure',
  'shipowners.manage-request.details.f.product.price',
  'shipowners.manage-request.details.f.product.final_qty', // for admin
  'shipowners.manage-request.details.f.vessel_name',
  'shipowners.manage-request.details.f.country',
  'shipowners.manage-request.details.f.port',
  'shipowners.manage-request.details.f.eta_etd',
  'shipowners.manage-request.details.f.cia',
  'shipowners.manage-request.details.f.currency',
  'shipowners.manage-request.details.f.time_for_quantity_claim',
  'shipowners.manage-request.details.f.time_for_quality_claim',
  'shipowners.manage-request.details.f.offer_validity_period',
  'shipowners.manage-request.details.f.order_validity_period',
  'shipowners.manage-request.details.f.additional_info',
  'shipowners.manage-request.details.f.save',
  // - Manage Request Offers end

  // - Manage Request Offers
  'shipowners.rfq',
  'shipowners.rfq.add-product',
  'shipowners.rfq.edit-offer',
  'shipowners.rfq.attachments',
  'shipowners.rfq.f.remove-product',
  'shipowners.rfq.f.product.product_meta_id',
  'shipowners.rfq.f.product.volume_from_to',
  'shipowners.rfq.f.product.measure',
  'shipowners.rfq.f.product.price',
  'shipowners.rfq.f.product.final_qty', // for admin
  'shipowners.rfq.f.vessel_name',
  'shipowners.rfq.f.country',
  'shipowners.rfq.f.port',
  'shipowners.rfq.f.eta_etd',
  'shipowners.rfq.f.cia',
  'shipowners.rfq.f.currency',
  'shipowners.rfq.f.time_for_quantity_claim',
  'shipowners.rfq.f.time_for_quality_claim',
  'shipowners.rfq.f.offer_validity_period',
  'shipowners.rfq.f.order_validity_period',
  'shipowners.rfq.f.additional_info',
  'shipowners.rfq.f.save',
  // - Manage Request Offers end

  // Suppliers app
  'suppliers.app',
  'suppliers.app.table',
  'suppliers.app.table.show-only-valid',
  'suppliers.app.table.modify-column-list',
  'suppliers.app.table.filter',
  'suppliers.rfq',
  'suppliers.rfq.add-product',
  'suppliers.rfq.edit-offer',
  'suppliers.rfq.attachments',
  'suppliers.rfq.f.remove-product',
  'suppliers.rfq.f.product.product_meta_id',
  'suppliers.rfq.f.product.volume_from_to',
  'suppliers.rfq.f.product.measure',
  'suppliers.rfq.f.product.price',
  'suppliers.rfq.f.product.final_qty', // for admin
  'suppliers.rfq.f.vessel_name',
  'suppliers.rfq.f.country',
  'suppliers.rfq.f.port',
  'suppliers.rfq.f.eta_etd',
  'suppliers.rfq.f.cia',
  'suppliers.rfq.f.currency',
  'suppliers.rfq.f.time_for_quantity_claim',
  'suppliers.rfq.f.time_for_quality_claim',
  'suppliers.rfq.f.offer_validity_period',
  'suppliers.rfq.f.order_validity_period',
  'suppliers.rfq.f.additional_info',
  'suppliers.rfq.f.save',
  // Suppliers app end

  // Route plan app
  // 'route-plan.app', // Public
  // Route plan app end

  // Market watch app
  // 'market-watch.app', // Public
  // Market watch app end

  // ADMIN PAGES
  'admin.app',
  // navigation items
  'admin-tabs.users',
  'admin-tabs.companies',
  'admin-tabs.vessels',
  'admin-tabs.offers',
  'admin-tabs.transactions',
  'admin-tabs.documents',
  'admin-tabs.master-data',
  'admin-tabs.logs',
  'admin-tabs.mailer',
  // navigation items end

  // companies nav items
  'companies-tabs.main-info',
  'companies-tabs.black-list',
  'companies-tabs.vessels',
  'companies-tabs.employees',
  'companies-tabs.documents',
  'companies-tabs.financial-field',
  'companies-tabs.logs',
  // companies nav items end

  // users app
  'users.app',
  'users.list',
  'users.list.add-new-user',
  'users.list.table',
  'users.profile',
  'users.profile.personal-info',
  'users.profile.personal-info-button-edit',
  'users.profile.notifications',
  'users.profile.notifications.f.activities',
  'users.profile.notifications.f.news',
  'users.profile.notifications.f.promotion',
  'users.profile.status',
  'users.profile.status.f.disable-enable',
  'users.profile.sessions',
  'users.profile.sessions.last-logins',
  'users.profile.sessions.session-timeout',
  'users.profile.sessions.f.session_timeout',
  // users app end
  // - vessels page
  'vessels.app',
  'vessels.list',
  'vessels.list.table',
  'vessels.list.button-add-new-vessel',
  'vessels.details',
  'vessels.details.button-delete-choosed-vessel',
  // - vessels page end
  // - offers page
  'offers.app',
  'offers.view',
  'offers.view.request-details',
  'offers.view.add-product',
  'offers.view.edit-offer',
  'offers.view.attachments',
  'offers.view.f.remove-product',
  'offers.view.f.product.product_meta_id',
  'offers.view.f.product.volume_from_to',
  'offers.view.f.product.measure',
  'offers.view.f.product.price',
  'offers.view.f.product.final_qty',
  'offers.view.f.vessel_name',
  'offers.view.f.country',
  'offers.view.f.port',
  'offers.view.f.eta_etd',
  'offers.view.f.cia',
  'offers.view.f.currency',
  'offers.view.f.time_for_quantity_claim',
  'offers.view.f.time_for_quality_claim',
  'offers.view.f.offer_validity_period',
  'offers.view.f.order_validity_period',
  'offers.view.f.additional_info',
  'offers.view.f.save',

  'offers.list',
  'offers.list.table',
  'offers.list.table-filter',
  'offers.list.table-actions',
  'offers.list.table-action-view',
  'offers.list.table-action-cancel',
  'offers.list.table-action-confirm-bdn',
  // - offers page end
  // - transactions page
  'transactions.app',
  'transactions.internal',
  'transactions.internal.table',
  'transactions.internal.table-filter',
  'transactions.internal.table-action-details',
  'transactions.external',
  'transactions.external.table',
  'transactions.external.table-filter',
  'transactions.external.table-action-withdrawal',
  'transactions.external.table-action-details',
  'transactions.external.table-action-cancel',
  'transactions.tabs',
  'transactions.tabs.internal',
  'transactions.tabs.external',
  'transactions.tabs.period',
  'transactions.tabs.currency',
  'transactions.tabs.export',
  'transactions.tabs.deposit',
  'transactions.tabs.withdraw',
  // - transactions page end
  // - documents page
  'documents.app',
  'documents.list',
  'documents.list.table',
  'documents.list.table-filter',
  // - documents page end
  // - master page
  'master.app',
  'master.ports.table',
  'master.ports.add-port',
  'master.ports.table.action-status',
  'master.products.table',
  'master.products.add-product',
  'master.products.table.action-status',
  'master.measures.table',
  'master.measures.add-measure',
  'master.measures.table.action-status',
  // - master page end
  // - logs page
  'logs.app',
  // - logs page end

  // companies app
  'companies.app',
  // - companies list page
  'companies.list',
  'companies.list-table',
  'companies.list-table-filter',
  // - companies list page end
  // - company main info
  'companies.main-info',
  'companies.main-info-view',
  'companies.main-info-button-change-details',
  'companies.main-info-button-block',
  'companies.main-info-button-cancel',
  'companies.main-info-edit',
  // -- company main info form fields access
  'companies.main-info.f.submit-button',
  'companies.main-info.f.company_name',
  'companies.main-info.f.registration_number',
  'companies.main-info.f.country_of_incorporation',
  'companies.main-info.f.date_of_incorporation',
  'companies.main-info.f.annual_turnover',
  'companies.main-info.f.website',
  'companies.main-info.f.phone_number',
  'companies.main-info.f.company_tin_vat_number',
  'companies.main-info.f.country_of_tax_residency',
  'companies.main-info.f.your_personal_manager',
  'companies.main-info.f.personal_managers_phone_number',
  'companies.main-info.f.contact_person_for_kyc',
  'companies.main-info.f.company_registered_address',
  'companies.main-info.f.actual_operating_address',
  // -- company main info form fields access end
  // - company main info end
  // - companies black list page
  'companies.black-list',
  'companies.black-list-table',
  'companies.black-list-table-remove',
  'companies.black-list-button-add-to-blacklist',
  // - companies black list page end
  // - companies vessels page
  'companies.vessels',
  'companies.vessels.table',
  'companies.vessels.details.button-delete-choosed-vessel',
  'companies.vessels.details',
  'companies.vessels.list.button-add-new-vessel',
  // - companies vessels page end
  // - companies company employees page
  'companies.emloyees',
  'companies.emloyees.table',
  'companies.emloyees.button-add-employee',
  // - companies company employees page end
  // - companies documents page
  'companies.documents',
  'companies.documents.table',
  // - companies documents page end
  // - companies financial field page
  'companies.financial-field',
  'companies.financial-field.info',
  'companies.financial-field.form',
  'companies.financial-field.change-balance',
  'companies.financial-field.f.number_of_outstanding_orders',
  'companies.financial-field.f.credit_limit_amount',
  'companies.financial-field.f.margin_percentage',
  'companies.financial-field.f.platform_fee',
  'companies.financial-field.f.save',
  // - companies financial field page end
  // - companies logs page
  'companies.logs',
  'companies.logs.table',
  'companies.logs.table-filter',
  // - companies logs page end
  // companies app end
] as const;

export default keys;
