import * as Types from './types';

import gql from 'graphql-tag';
export type UserInfoFragment = { __typename?: 'user', user_id: string, email: string, password?: string, registered_at: string, login_attempts: number, is_blocked: boolean, role: string, session_timeout: number, jwtAuths: Array<{ __typename?: 'jwt_auth', refresh_jwt: string, chatwoot_identifier_hash?: string }> };

export type KycDocumentFragment = { __typename?: 'file', id: string, fileName?: string, mime?: string };

export type ConfirmationCodeFragment = { __typename?: 'confirmation_code', confirmation_code_id: string, user_id: string, code: string, created_at: string, failed_attempts: number, confirm_action: string, confirmed_at?: string, user: { __typename?: 'user', email: string } };

export const UserInfoFragmentDoc = gql`
    fragment UserInfo on user {
  user_id
  email
  password
  registered_at
  login_attempts
  is_blocked
  jwtAuths: jwt_auths_rel(order_by: {created_at: desc}) {
    refresh_jwt
    chatwoot_identifier_hash
  }
  role
  session_timeout
}
    `;
export const KycDocumentFragmentDoc = gql`
    fragment KycDocument on file {
  id: file_id
  fileName: file_name
  mime: mime_type
}
    `;
export const ConfirmationCodeFragmentDoc = gql`
    fragment ConfirmationCode on confirmation_code {
  confirmation_code_id
  user_id
  code
  created_at
  failed_attempts
  confirm_action
  confirmed_at
  user: user_rel {
    email
  }
}
    `;