import { useEffect, useState } from 'react';

import { useTradingId } from './use-trading-id';
import {
  TradingOrderInfoSubscription,
  useTradingOrderInfoSubscription,
} from '@gql/trading.urql';
import { useMemoCompare } from 'src/components/helpers';

export const useTradingInfo = () => {
  const { tradingId } = useTradingId();
  const [orderInfo, setOrderInfo] =
    useState<TradingOrderInfoSubscription['trading_order']>();
  const [{ data, fetching }] = useTradingOrderInfoSubscription({
    variables: { id: tradingId },
    pause: !tradingId,
  });

  const orderInfoLocale = useMemoCompare(data?.trading_order, (prev, next) => {
    if (!prev) return false;
    if (!next) return true;
    return prev.updated_at === next.updated_at;
  });

  useEffect(() => {
    setOrderInfo(orderInfoLocale);
  }, [orderInfoLocale]);

  return { fetching, orderInfo, tradingId };
};
