import { TemplateName } from '@email-templates';
import { Input, Form, Select, AutoComplete, notification } from 'antd';
import { Rule } from 'antd/lib/form';

import {
  useAdminAllUsersQuery,
  useAdminSendEmailMutation,
} from '@common/__generated__/admin.urql';
import FormItem from 'src/components/form-item';
import FormMain from 'src/components/form-main';
import ButtonSubmit from 'src/components/form-main-submit';

const jsonCodeRule: Rule = {
  async validator(_ruleObj, value) {
    try {
      JSON.parse(value);
    } catch {
      throw new Error('The JSON is not valid');
    }
  },
};

const initialValues = {
  data: `{
    "email": "test@te.st",
    "linkLogin": "https://login-url.test",
    "linkForgot": "https://forgot-url.test",
    "signInLink": "https://login-url.test",
    "signUpLink": "https://reg-url.test",
    "activationLink": "https://activation-url.test",
    "link": "https://link-url.test",
    "rfqId": "45625",
    "ship": "Bluestar",
    "port": "Port 1",
    "eta": "16.04.2022 - 16.05.2022",
    "date": "20.04.2022",
    "supplier": "Lukoil",
    "products": [
      {
        "product": "PRODUCT NAME",
        "amount": "20.000 USD",
        "estimatedQuantity": "45 - 60 M3",
        "order": 1
      },
      {
        "product": "PRODUCT NAME 2",
        "amount": "50.000 USD",
        "estimatedQuantity": "100 - 160 M3",
        "order": 2
      }
    ],
    "message": "New USD Account added",
    "code": "1324"
  }`,
};

type FormValues = {
  to: string;
  subject: string;
  template_name: TemplateName;
  data: string;
};

const EmailPlayground: React.FC = () => {
  const [form] = Form.useForm();
  const [{ data: userData }] = useAdminAllUsersQuery({
    variables: { lastId: new Date(0).toISOString() },
  });
  const users = userData?.user ?? [];
  const [, sendEmail] = useAdminSendEmailMutation();
  const handleDataBlur = () => {
    const rawJson = form.getFieldValue('data');
    try {
      const fmt = JSON.stringify(JSON.parse(rawJson), null, 2);
      form.setFieldsValue({ data: fmt });
    } catch {
      // Ignore
    }
  };
  const handleDataKeydown = (e: React.KeyboardEvent) => {
    if (e.code === 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <FormMain<FormValues>
      form={form}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const { data } = await sendEmail({
          data: { ...values, data: JSON.parse(values.data) },
        });
        if (data) {
          notification.success({ message: 'Email sent successfully' });
          return { status: true };
        }
        return { status: false };
      }}
    >
      <FormItem name="to">
        <AutoComplete options={users.map((u) => ({ value: u.email }))} />
      </FormItem>
      <FormItem name="subject">
        <Input placeholder="Subject" />
      </FormItem>
      <FormItem name="template_name">
        <Select>
          {Object.values(TemplateName).map((name, index) => (
            <Select.Option key={index} value={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="data" rules={[jsonCodeRule]}>
        <Input.TextArea
          rows={10}
          onBlur={handleDataBlur}
          onKeyDown={handleDataKeydown}
          style={{ fontFamily: 'monospace' }}
        />
      </FormItem>
      <ButtonSubmit>Send email</ButtonSubmit>
    </FormMain>
  );
};

export default EmailPlayground;
