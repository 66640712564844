import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SHIPOWNERS } from '@common/routes';
import { PrivateRoute } from 'src/components/private-route';
import { LayoutMain } from 'src/layouts/main/main';
import LayoutShipowners from 'src/layouts/shipowners/shipowners';

const AllOffersPage = lazy(() => import('./all-offers'));
const ManageRequestPage = lazy(() => import('./manage-request'));

export const ShipownersApp: React.FC = () => (
  <LayoutMain>
    <LayoutShipowners>
      <Switch>
        <PrivateRoute
          accessProps={{ accessKey: 'shipowners.all-offers', show403: true }}
          path={SHIPOWNERS.ALL_OFFERS}
          component={AllOffersPage}
        />
        <PrivateRoute
          accessProps={{
            accessKey: 'shipowners.manage-request',
            show403: true,
          }}
          path={SHIPOWNERS.MANAGE_REQUEST}
          component={ManageRequestPage}
        />
        <Redirect to={SHIPOWNERS.ALL_OFFERS} />
      </Switch>
    </LayoutShipowners>
  </LayoutMain>
);

export default ShipownersApp;
