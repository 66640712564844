import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Provider as UrqlProvider } from 'urql';
import { subscribe } from 'wonka';

import { initializeClient } from './client';
import { AuthState } from './cookie-auth';

type Auth = {
  authState: AuthState;
  closeWebsocket(): void;
};

// @ts-expect-error always initialized
const authContext = createContext<Auth>();

export const useAuthState = () => useContext(authContext).authState;
export const useCloseWebsocket = () => useContext(authContext).closeWebsocket;

export const GraphqlProvider: React.FC = ({ children }) => {
  const client = useMemo(() => initializeClient(), []);
  const [authState, setAuthState] = useState(AuthState.UNAUTHORIZED);

  useEffect(
    () => subscribe(setAuthState)(client.authStateSource).unsubscribe,
    [client.authStateSource],
  );

  const authContextValue = useMemo(
    () => ({ authState, closeWebsocket: client.closeWebsocket }),
    [client],
  );

  return (
    <UrqlProvider value={client.client}>
      <authContext.Provider value={authContextValue}>
        {children}
      </authContext.Provider>
    </UrqlProvider>
  );
};
