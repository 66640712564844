import {
  ClockCircleOutlined,
  SafetyOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { t } from 'i18next';

import { VerifyStatus } from '@common/roles';

type Props = {
  status: VerifyStatus;
};

export const StatusBox: React.FC<Props> = ({ status }) => (
  <div className={`profile-account-status profile-account-status--${status}`}>
    {status === VerifyStatus.VERIFIED && (
      <>
        <span className="hide--md">
          {t('profile.navigation.status_verified')}
        </span>
        <SafetyOutlined />
      </>
    )}
    {status === VerifyStatus.PENDING && (
      <>
        {t('profile.navigation.status_pending')}
        <ClockCircleOutlined />
      </>
    )}
    {status === VerifyStatus.UNVERIFIED && (
      <>
        {t('profile.navigation.status_not_verified')}
        <WarningOutlined />
      </>
    )}
  </div>
);

export default StatusBox;
