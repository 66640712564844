import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TabNavigation from '../layout-elements/tab-navigation';
import { SHIPOWNERS } from '@common/routes';
import Access from 'src/access-checker/access';
import LayoutLoader from 'src/components/layout-loader';
import { CreateRequestModal } from 'src/pages/shipowners/create-request-modal';

export const LayoutShipowners: React.FC = ({ children }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const showMobileNavOnPages = [
    SHIPOWNERS.ALL_OFFERS,
    SHIPOWNERS.MANAGE_REQUEST,
    SHIPOWNERS.APP,
  ];

  const hideClassname = (showMobileNavOnPages as string[]).includes(
    history.location.pathname,
  )
    ? ''
    : 'hide--lg';

  return (
    <>
      <TabNavigation
        className={hideClassname}
        tabs={[
          {
            title: t('shipowner.navigation.tab_all_offers'),
            accessKey: 'shipowners.nav.all-offers',
            url: SHIPOWNERS.ALL_OFFERS,
            key: SHIPOWNERS.ALL_OFFERS,
          },
          {
            title: t('shipowner.navigation.tab_manage_request'),
            accessKey: 'shipowners.nav.manage-request',
            url: SHIPOWNERS.MANAGE_REQUEST,
            key: SHIPOWNERS.MANAGE_REQUEST,
          },
        ]}
        customActions={
          <Access accessKey="shipowners.rfq">
            <CreateRequestModal />
          </Access>
        }
      />
      <div className="wrapper wrapper--offset-bottom">
        <LayoutLoader>{children}</LayoutLoader>
      </div>
    </>
  );
};

export default LayoutShipowners;
