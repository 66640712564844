import { Spin } from 'antd';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

export const ModalSpin: React.FC<Props> = ({ size = 'sm' }) => (
  <div className={'modal-spin modal-spin__' + size}>
    <div className="modal-spin__body">
      <Spin />
    </div>
  </div>
);

export default ModalSpin;
