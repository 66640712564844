import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import FormItem, { FItemProps } from './form-item';

type FormItemWithLinkProps<T> = {
  linkClick?: React.MouseEventHandler<HTMLAnchorElement>;
  linkTo?: string;
  linkTitle?: string;
} & FItemProps<T>;

const defaultColSize = { xs: 24, sm: 12 };

export const FormItemWithLink = <T,>(props: FormItemWithLinkProps<T>) => {
  const {
    linkClick,
    linkTo,
    linkTitle,
    label,
    labelCol = defaultColSize,
    wrapperCol = defaultColSize,
    ...rest
  } = props;

  return (
    <Row>
      <Col {...labelCol}>
        <div className="ant-form-item-label">
          <label htmlFor={props.name as string}>{label}</label>
        </div>
        {typeof linkTo === 'undefined' ? (
          <Typography.Link onClick={linkClick}>{linkTitle}</Typography.Link>
        ) : (
          <Link onClick={linkClick} to={linkTo}>
            {linkTitle}
          </Link>
        )}
      </Col>
      <Col {...wrapperCol}>
        <FormItem {...rest} label="" wrapperCol={{ span: 24 }} />
      </Col>
    </Row>
  );
};

export default FormItemWithLink;
