import { SwapRightOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Select,
  Upload,
  Checkbox,
  Input,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ProductField } from './create-request-form-product-field';
import { useTradingFormMetaQuery } from '@common/__generated__/trading.urql';
import { Uuid } from '@common/db-types';
import { PROFILE } from '@common/routes';
import { OrderFormValues } from '@common/trading/frontend';
import { DeepPartial } from '@common/types/util';
import Access from 'src/access-checker/access';
import { AccessKey } from 'src/access-checker/types';
import BaseRow from 'src/components/base-row';
import { CountrySelect } from 'src/components/country-select';
import DatePicker from 'src/components/date-picker';
import FormItem from 'src/components/form-item';
import {
  FORMAT_BOOLEAN,
  FORMAT_DATE,
  FORMAT_PERIOD,
  FORMAT_SELECT,
} from 'src/components/form-item-changes';
import FormTitle from 'src/components/form-title';
import { PeriodInput } from 'src/components/period-input';
import VesselInfoTooltip from 'src/components/vessel-info-tooltip';
import { useAuth } from 'src/hooks';

export type RequestFormInitialValues = OrderFormValues & {
  // products: ProductsInitial;
  products: OrderFormValues['products'];
};

export type DisabledFields = {
  vessel_name?: boolean;
  country?: boolean;
  port?: boolean;
  product_name?: boolean;
  order_validity_period?: boolean;
  display_add_product?: boolean;
  display_offer_validity_period?: boolean;
  volume_from_to?: boolean;
  measure?: boolean;
  currency?: boolean;
  time_for_quality_claim?: boolean;
  time_for_quantity_claim?: boolean;
  additional_info?: boolean;
  attachments?: boolean;
  payment_calculation?: boolean;
  product_price?: boolean;
  product_delete?: boolean;
};

export const CREATE_REQUEST_FORM_ID = 'form_shipowner_create_request';

export type AccessKeys = {
  vessel_name?: AccessKey;
  country?: AccessKey;
  port?: AccessKey;
  eta_etd?: AccessKey;
  cia?: AccessKey;
  currency?: AccessKey;
  time_for_quantity_claim?: AccessKey;
  time_for_quality_claim?: AccessKey;
  attachments?: AccessKey;
  additional_info?: AccessKey;
  product_meta_id?: AccessKey;
  volume_from_to?: AccessKey;
  measure?: AccessKey;
  price?: AccessKey;
  remove_product?: AccessKey;
  add_product?: AccessKey;
  final_qty?: AccessKey;
  order_validity_period?: AccessKey;
  offer_validity_period?: AccessKey;
  product_final_qty?: AccessKey;
  product_admin_adjusted_volume?: AccessKey;
};

type Props = {
  disabled?: boolean;
  initialValues?: DeepPartial<OrderFormValues>;
  isSupplier?: boolean;
  isAdmin?: boolean;
  showAllVolumes?: boolean;
  isSendCounterOffer?: boolean;
  disabledFields?: DisabledFields;
  accessKeys?: AccessKeys;
  filterByCountry?: string;
  usedProducts?: Uuid[];
};

// FIXME: This component needs heavy refactoring, which involves separating
// shipowner & supplier logic, order actions etc.
/* eslint-disable sonarjs/cognitive-complexity */
export const CreateRequestForm: React.FC<Props> = ({
  disabled,
  initialValues,
  isSupplier,
  isAdmin,
  isSendCounterOffer,
  disabledFields = {},
  accessKeys = {},
  filterByCountry,
  usedProducts,
  showAllVolumes = false,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { companyId } = useAuth();
  const variables = useMemo(
    () =>
      isSupplier ? {} : { vessel_filter: { company_id: { _eq: companyId } } },
    [isSupplier, companyId],
  );
  const [{ data, fetching: metaLoading }] = useTradingFormMetaQuery({
    requestPolicy: 'network-only',
    variables,
  });
  const currencyOptions = data?.currency ?? [];
  const vessels = data?.vessel ?? [];
  let shipPorts = data?.ports ?? [];

  const portCountries = useMemo(
    () =>
      Array.from(
        shipPorts.reduce(
          (acc, port) => acc.add(port.countryCode),
          new Set<string>(),
        ),
      ),
    [data?.ports],
  );

  if (filterByCountry) {
    shipPorts = shipPorts.filter(
      (port) => port.countryCode === filterByCountry,
    );
  }

  const productFieldRules: ValidatorRule[] = [
    {
      validator: async (_, names) => {
        if (!names || names.length < 1) {
          const message = t('shipowner.form.errors.at_least_one_product');
          throw new Error(message);
        }
      },
    },
  ];

  const [product] = initialValues?.products ?? [];
  const firstPrice = product && product?.price;
  const enablePrice = isSupplier || isSendCounterOffer || isAdmin;
  const showPrice = enablePrice || !!firstPrice;
  if (!enablePrice) {
    disabledFields.product_price = true;
  }

  const showAdminAdjustedVolume =
    !!product?.admin_adjusted_volume || showAllVolumes;
  const showFinalQty =
    (!showAdminAdjustedVolume && !!product?.final_qty) || showAllVolumes;
  const showVolumeFromTo =
    (!showFinalQty && !showAdminAdjustedVolume) || showAllVolumes;

  return (
    <>
      <FormTitle>{t('shipowner.form.section.delivery_info')}</FormTitle>
      <BaseRow>
        <Col xs={8}>
          <VesselInfoTooltip id={initialValues?.vessel_name}>
            <FormItem
              className="form-tem-label-full-width"
              label={t('shipowner.form.vessel_name')}
              name="vessel_name"
              accessKey={accessKeys['vessel_name']}
              historyFormat={{
                type: FORMAT_SELECT,
                options: vessels,
                key: 'id',
                value: 'name',
              }}
              rules={[
                {
                  required: true,
                  message: t('shipowner.form.errors.vessel_name_required'),
                },
              ]}
            >
              <Select
                disabled={disabled || disabledFields.vessel_name}
                onChange={(value) => {
                  if (value === 'add-new-vessel')
                    history.push(PROFILE.COMPANY_PROFILE_VESSELS);
                }}
                loading={metaLoading}
              >
                {vessels.map((vessel) => (
                  <Select.Option key={vessel.id} value={vessel.id}>
                    {vessel.meta.name} ({vessel.meta.mmsi})
                  </Select.Option>
                ))}
                <Select.Option key="add-new-vessel" value="add-new-vessel">
                  <Typography.Link>
                    {t('shipowner.form.add_new_vessel')}
                  </Typography.Link>
                </Select.Option>
              </Select>
            </FormItem>
          </VesselInfoTooltip>
        </Col>
        <Col xs={8}>
          <FormItem
            className="form-tem-label-full-width"
            label={t('form.country')}
            name="country"
            accessKey={accessKeys['country']}
            rules={[
              {
                required: true,
                message: t('shipowner.form.errors.country_required'),
              },
            ]}
          >
            <CountrySelect
              allowClear
              disabled={disabled || disabledFields.country}
              countryCodes={portCountries}
            />
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            className="form-tem-label-full-width"
            label={t('shipowner.form.port')}
            name="port"
            accessKey={accessKeys['port']}
            historyFormat={{
              type: FORMAT_SELECT,
              options: shipPorts,
              key: 'id',
              value: 'name',
            }}
            rules={[
              {
                required: true,
                message: t('shipowner.form.errors.port_required'),
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              disabled={disabled || disabledFields.port}
              loading={metaLoading}
              options={shipPorts.map((port) => ({
                label: port.name,
                value: port.id,
                disabled: !port.active,
              }))}
            />
          </FormItem>
        </Col>
      </BaseRow>

      <BaseRow>
        <Col xs={24} sm={16}>
          <FormItem
            className="form-tem-label-full-width"
            label={t('shipowner.form.eta_etd')}
            name="eta_etd"
            historyNames={['eta', 'etd']}
            historySeparator={<SwapRightOutlined />}
            accessKey={accessKeys['eta_etd']}
            rules={[
              {
                required: true,
                message: t('shipowner.form.errors.eta_required'),
              },
            ]}
            historyFormat={{
              type: FORMAT_DATE,
            }}
          >
            <DatePicker.RangePicker
              disabledDate={(currentDate) =>
                dayjs().isAfter(currentDate, 'day')
              }
              disabled={disabled}
              className="full-width"
            />
          </FormItem>
        </Col>
      </BaseRow>

      <FormTitle>{t('shipowner.form.section.products')}</FormTitle>
      <Form.List name="products" rules={productFieldRules}>
        {(fields, formOperations, { errors }) => (
          <>
            {fields.map((field, index) => (
              <ProductField
                key={field.key}
                accessKeys={accessKeys}
                disabledFields={disabledFields}
                showTitle={!index}
                field={field}
                disabled={disabled}
                showPrice={showPrice}
                showFinalQty={showFinalQty}
                showVolumeFromTo={showVolumeFromTo}
                showAdminAdjustedVolume={showAdminAdjustedVolume}
                usedProducts={usedProducts}
                {...formOperations}
              />
            ))}
            <Access
              accessKey={accessKeys['add_product'] || 'offers.view.add-product'}
            >
              {!(disabled || disabledFields.display_add_product) && (
                <FormItem>
                  <Button
                    className="add-product-button"
                    onClick={() => formOperations.add()}
                    type="primary"
                  >
                    {t('shipowner.form.product.add')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </FormItem>
              )}
            </Access>
          </>
        )}
      </Form.List>
      <FormTitle>{t('shipowner.form.section.payment')}</FormTitle>
      <BaseRow>
        <Col xs={11} sm={12}>
          <FormItem
            label={t('shipowner.form.cia')}
            name="cia"
            accessKey={accessKeys['cia']}
            historyFormat={{ type: FORMAT_BOOLEAN }}
            className="horizontal-form-item"
            valuePropName="checked"
          >
            <Checkbox disabled={true} />
          </FormItem>
        </Col>
        <Col xs={13} sm={12}>
          <FormItem
            label={t('form.currency')}
            name="currency"
            historyFormat={{
              type: FORMAT_SELECT,
              options: currencyOptions,
              key: 'id',
              value: 'code',
            }}
            accessKey={accessKeys['currency']}
            className="horizontal-form-item"
            rules={[
              {
                required: true,
                message: t('shipowner.form.errors.currency_required'),
              },
            ]}
          >
            <Select
              disabled={disabled || disabledFields.currency}
              className="input-sm"
              loading={metaLoading}
            >
              {currencyOptions.map((currency) => (
                <Select.Option
                  key={currency.id}
                  value={currency.id}
                  disabled={!currency.active}
                >
                  {currency.code}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </BaseRow>

      <FormTitle>{t('shipowner.form.section.other_fields')}</FormTitle>
      <BaseRow>
        <Col xs={24} sm={12}>
          <FormItem
            historyFormat={{
              type: FORMAT_PERIOD,
              unit: 'days',
            }}
            label={t('shipowner.form.time_for_quality_claim')}
            name="time_for_quality_claim"
            accessKey={accessKeys['time_for_quality_claim']}
            className="horizontal-form-item horizontal-form-item--fw-on-xs"
            rules={[
              {
                required: true,
                message: t(
                  'shipowner.form.errors.time_for_quality_claim_required',
                ),
              },
            ]}
          >
            <PeriodInput
              unit="days"
              disabled={disabled || disabledFields.time_for_quality_claim}
              className="input-xs"
              min={1}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem
            label={t('shipowner.form.time_for_quantity_claim')}
            name="time_for_quantity_claim"
            accessKey={accessKeys['time_for_quantity_claim']}
            className="horizontal-form-item horizontal-form-item--fw-on-xs"
            rules={[
              {
                required: true,
                message: t(
                  'shipowner.form.errors.time_for_quantity_claim_required',
                ),
              },
            ]}
            historyFormat={{
              type: FORMAT_PERIOD,
              unit: 'days',
            }}
          >
            <PeriodInput
              unit="days"
              disabled={disabled || disabledFields.time_for_quantity_claim}
              className="input-xs"
              min={1}
            />
          </FormItem>
        </Col>
      </BaseRow>
      <BaseRow>
        <Col xs={24} sm={12}>
          <FormItem
            className="horizontal-form-item horizontal-form-item--fw-on-xs"
            label={t('shipowner.form.order_validity_period_hours')}
            name="order_validity_period"
            accessKey={accessKeys['order_validity_period']}
            rules={[
              {
                required: true,
                message: t(
                  'shipowner.form.errors.order_validity_period_required',
                ),
              },
            ]}
            historyFormat={{
              type: FORMAT_PERIOD,
              unit: 'hours',
            }}
          >
            <PeriodInput
              unit="hours"
              disabled={disabled || disabledFields.order_validity_period}
              className="input-xs"
              min={0}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          {!disabledFields.display_offer_validity_period && (
            <FormItem
              className="horizontal-form-item horizontal-form-item--fw-on-xs"
              label={t('shipowner.form.offer_validity_period_minutes')}
              name="offer_validity_period"
              accessKey={accessKeys['offer_validity_period']}
              rules={[
                {
                  required: true,
                  message: t(
                    'shipowner.form.errors.offer_validity_period_required',
                  ),
                },
              ]}
              historyFormat={{
                type: FORMAT_PERIOD,
                unit: 'minutes',
              }}
            >
              <PeriodInput
                unit="minutes"
                disabled={disabled}
                className="input-xs"
                min={0}
              />
            </FormItem>
          )}
        </Col>
      </BaseRow>
      <FormTitle>{t('shipowner.form.section.additional_info')}</FormTitle>
      <FormItem
        name="additional_info"
        accessKey={accessKeys['additional_info']}
      >
        <Input.TextArea disabled={disabled || disabledFields.additional_info} />
      </FormItem>
      <Access
        accessKey={accessKeys['attachments'] || 'offers.view.attachments'}
      >
        {!disabled && (
          <FormTitle>{t('shipowner.form.section.attachments')}</FormTitle>
        )}
        {!(disabled || disabledFields.attachments) && (
          <BaseRow>
            <Col>
              <FormItem
                name="attachments"
                getValueFromEvent={(e) => e?.fileList}
                valuePropName="fileList"
              >
                <Upload beforeUpload={() => false}>
                  <Button type="primary">
                    {t('shipowner.form.attachments')}
                  </Button>
                </Upload>
              </FormItem>
            </Col>
          </BaseRow>
        )}
      </Access>
    </>
  );
};

/* eslint-enable sonarjs/cognitive-complexity */

export default CreateRequestForm;
