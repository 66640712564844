import { Alert, Checkbox, Col, Input, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BankAddressInput,
  BankNameInput,
  IbanInput,
  SwiftInput,
} from './form-elements';
import { useCurrenciesQuery } from '@common/__generated__/auth.urql';
import BaseRow from 'src/components/base-row';
import FormItem from 'src/components/form-item';

type Props = {
  beneficiary_disabled?: boolean;
};

export type BankAccountFormFields = {
  account_name: string;
  currency_id: string;
  account_holder: string;
  iban: string;
  bank_name: string;
  bank_address: string;
  swift: string;
  transfer_details: string;
  correspondent_bank_name: string;
  correspondent_swift: string;
};

export const BankAccountForm: React.FC<Props> = ({ beneficiary_disabled }) => {
  const [t] = useTranslation();
  const [correspondentBank, setCorrespondentBank] = useState(false);
  const [{ data: currencyData }] = useCurrenciesQuery();
  const currencies = currencyData?.currency ?? [];

  return (
    <div className="container padding-bottom">
      <BaseRow>
        <Col xs={24} md={8}>
          <FormItem
            name="account_name"
            label={t('banking.form.account_name')}
            rules={[
              {
                required: true,
                message: t('banking.form.errors.account_name_required'),
              },
            ]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name="currency_id"
            label={t('banking.form.currency')}
            rules={[
              {
                required: true,
                message: t('banking.form.errors.currency_required'),
              },
            ]}
          >
            <Select>
              {currencies.map(({ id, code }) => (
                <Select.Option key={id} id={id}>
                  {code}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name="account_holder"
            label={t('banking.form.beneficiary')}
            rules={[
              {
                required: true,
                message: t('banking.form.errors.beneficiary_required'),
              },
            ]}
          >
            <Input disabled={beneficiary_disabled} />
          </FormItem>
        </Col>
      </BaseRow>
      <BaseRow>
        <Col xs={24} md={12}>
          <IbanInput />
        </Col>
        <Col xs={24} md={12}>
          <SwiftInput />
        </Col>
      </BaseRow>
      <BaseRow>
        <Col xs={24} md={12}>
          <BankNameInput />
        </Col>
        <Col xs={24} md={12}>
          <BankAddressInput />
        </Col>
      </BaseRow>
      <FormItem
        name="transfer_details"
        label={t('banking.form.transfer_details')}
        rules={[
          {
            max: 30,
            message: t('banking.form.errors.transfer_details_len'),
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem>
        <Checkbox
          onChange={(e) => {
            setCorrespondentBank(e.target.checked);
          }}
        >
          {t('banking.form.correspondent_bank')}
        </Checkbox>
      </FormItem>
      {correspondentBank && (
        <BaseRow>
          <Col xs={24} md={12}>
            <BankNameInput
              name="correspondent_bank_name"
              label={t('banking.form.correspondent_bank_name')}
            />
          </Col>
          <Col xs={24} md={12}>
            <BankAddressInput
              name="correspondent_swift"
              label={t('banking.form.correspondent_bank_address')}
            />
          </Col>
        </BaseRow>
      )}
      <Alert
        message={t('banking.add_bank_account_modal_alert')}
        type="info"
        showIcon
      />
    </div>
  );
};

export default BankAccountForm;
