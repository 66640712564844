import { FormProps } from 'antd';
import dayjs from 'dayjs';

import {
  FormAction,
  FORM_MAIN_ON_SUBMIT_BLOCK,
  FORM_MAIN_ON_SUBMIT_ERROR,
  FORM_MAIN_ON_SUBMIT_FINISH,
  FORM_MAIN_ON_SUBMIT_START,
  FORM_SET_CHANGE_HISTORY,
  FORM_SET_INITIAL_VALUES,
} from './form-main-actions';

export type ErrorMessage = { msg: string; field?: string };

type Log = {
  time: Date;
  isBlocked: boolean;
  delay: number;
};

export type FormState = {
  isLoading: boolean;
  isFakeLoading: boolean;
  errorMessages: ErrorMessage[];
  submitCount: number;
  submitBlockedCount: number;
  lastSubmitStart: Date;
  submitLog: Log[];
  initialValues?: FormProps['initialValues'];
  changeHistory?: FormProps['initialValues'][];
};

export const defaultState: FormState = {
  isLoading: false,
  isFakeLoading: false,
  errorMessages: [],
  submitCount: 0,
  submitBlockedCount: 0,
  lastSubmitStart: new Date(),
  submitLog: [],
};

export const formContextReducer = (
  state: FormState = defaultState,
  action: FormAction,
): FormState => {
  switch (action.type) {
    case FORM_MAIN_ON_SUBMIT_START:
      return {
        ...state,
        isLoading: true,
        isFakeLoading: action.payload,
        submitCount: state.submitCount + 1,
        lastSubmitStart: new Date(),
      };
    case FORM_MAIN_ON_SUBMIT_ERROR:
    case FORM_MAIN_ON_SUBMIT_FINISH: {
      const nextState = { ...state, isLoading: false };
      nextState.submitLog.push({
        time: state.lastSubmitStart,
        isBlocked: false,
        delay: dayjs().diff(dayjs(state.lastSubmitStart)),
      });
      if (action.payload.error)
        nextState.errorMessages.push(action.payload.error);

      return { ...state, isLoading: false };
    }
    case FORM_MAIN_ON_SUBMIT_BLOCK: {
      return {
        ...state,
        submitBlockedCount: state.submitBlockedCount + 1,
        submitLog: [
          ...state.submitLog,
          { delay: 0, time: new Date(), isBlocked: true },
        ],
      };
    }
    case FORM_SET_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          ...action.payload,
        },
      };
    }
    case FORM_SET_CHANGE_HISTORY: {
      return {
        ...state,
        changeHistory: action.payload,
      };
    }
    default:
      return state;
  }
};

export default formContextReducer;
