import { FormItemProps, FormProps } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useRef } from 'react';
import { ExtractRouteParams } from 'react-router';

import {
  TradingHistoryQuery,
  TradingOrderInfoSubscription,
} from '@common/__generated__/trading.urql';
import { Uuid } from '@common/db-types';
import {
  Notification,
  NotificationOrderChanged,
  NotificationType,
  RFQHistory,
  RFQProdcutsHistory,
} from '@common/notifications';
import { OrderFormValues } from '@common/trading/frontend';
import { FileAttachment, SupplierOfferInput } from '@gql/types';
import { antdFilesToGraphQL } from 'src/form-utils';

type SearchObject = { [property: string]: string };
type SearchInputObject = { [property: string]: any };

export const getSearchParams = (search): SearchObject => {
  const res = {};
  if (!search) return res;
  const searchArr = search.substring(1).split('&');
  for (let index = 0; index < searchArr.length; index++) {
    const searchElement = searchArr[index];
    const [key, value] = searchElement.split('=');
    res[key] = value;
  }
  return res;
};

const isValid = (value: any): boolean =>
  (Array.isArray(value) && value.length > 0) ||
  (typeof value === 'string' && value !== '') ||
  typeof value === 'number';

export const setSearchParams = (searchObject: SearchInputObject): string => {
  const keys = Object.keys(searchObject);
  if (!keys.length) return '';

  const searchParams = Object.keys(searchObject).reduce<string[]>(
    (nextParams, key) => {
      if (isValid(searchObject[key]))
        nextParams.push(`${key}=${searchObject[key]}`);
      return nextParams;
    },
    [],
  );

  if (!searchParams.length) return '';

  return '?' + searchParams.join('&');
};

export const colSpecial = {
  dataIndex: 'css-line',
  className: 'collapse-table__line',
};

export const getInitialValueByName = (
  initialValues: FormProps['initialValues'],
  name: FormItemProps['name'],
  defaultValue: any = undefined,
) => {
  if (!initialValues || typeof initialValues !== 'object') return undefined;
  if (typeof name === 'string' || typeof name === 'number') {
    return Object.prototype.hasOwnProperty.call(initialValues, name)
      ? initialValues[name]
      : defaultValue;
  }
  if (!Array.isArray(name)) return defaultValue;
  let lastObj = { ...initialValues };
  for (const n of name) {
    lastObj = lastObj[n];
    if (!lastObj) return defaultValue;
  }
  return lastObj ?? defaultValue;
};

// type SplitBySlash<T extends string> = T extends `${infer Head}/${infer Tail}`
//   ? (Head extends '' ? never : Head) | SplitBySlash<Tail>
//   : T;

// type RouteParams<R extends string> = {
//   [K in SplitBySlash<R> as K extends `:${infer P}` ? P : never]:
//     | string
//     | number
//     | symbol;
// };

export function routeWithParams<R extends string>(
  route: R,
  params: ExtractRouteParams<R>,
): string;

export function routeWithParams(route: string, params: object) {
  return Object.entries(params).reduce(
    (acc, [key, val]) => acc.replace(`:${key}`, val),
    route,
  );
}

export const convertNotoficationToChanges = (
  orderInfo: TradingOrderInfoSubscription['trading_order'],
  notifications: Notification[],
  // eslint-disable-next-line sonarjs/cognitive-complexity
): NotificationOrderChanged['orderHistory'] => {
  if (!orderInfo) return [];
  const currentChanges: NotificationOrderChanged = notifications.find(
    (n) => n.type === NotificationType.OrderChanged && n.order === orderInfo.id,
  ) as NotificationOrderChanged;
  if (!currentChanges) return [];
  return currentChanges.orderHistory.map((history) => {
    const products: {
      [prop: string]: RFQProdcutsHistory;
    } = {};
    for (const product_history of currentChanges.productHistory) {
      const { version_updated_at, meta } = product_history;
      if (!meta || version_updated_at !== history.version_updated_at) continue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!products[meta.short_name])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        products[meta.short_name] = product_history;
    }

    const product_history_grouped = orderInfo.products.map(({ meta }) => {
      if (!meta || !products[meta.shortName]) return {};
      return products[meta.shortName];
    }) as NotificationOrderChanged['orderHistory'][number]['products'];
    return {
      ...history,
      ...history.additional,
      products: product_history_grouped,
    };
  });
};

export const randomString = () => (Math.random() + 1).toString(36).substring(7);

export const genUniqNames = (names: string[]) => {
  const randId = randomString();
  return names.map((name) => `${name}_${randId}`);
};

export const copyElementContents = (elementId: string) => {
  const el = document.getElementById(elementId);
  if (!el) {
    console.warn('Copy Failed, element not found');
    return null;
  }

  const { body } = document;
  let range, sel;
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel.addRange(range);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } else if (body.createTextRange) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    range = body.createTextRange();
    range.moveToElementText(el);
    range.select();
  }
  document.execCommand('Copy');
  sel.removeAllRanges();
};

export const groupChangesByRfqFormFields = (
  orderInfo: TradingOrderInfoSubscription['trading_order'],
  tradingHistory: TradingHistoryQuery['trading_order'],
): RFQHistory[] => {
  if (!orderInfo || !tradingHistory) return [];
  const { orderHistory = [], productHistory = [] } = tradingHistory;

  return orderHistory.map((history) => {
    const products: {
      [prop: string]: RFQProdcutsHistory;
    } = {};

    for (const product_history of productHistory) {
      const { version_updated_at, product_meta_id } = product_history;
      if (!product_meta_id) continue;
      if (version_updated_at !== history.version_updated_at) continue;
      products[product_meta_id] = product_history as RFQProdcutsHistory;
    }

    const product_history_grouped = orderInfo.products.map(({ id }) => {
      if (!products[id]) return {};
      return products[id];
    }) as NotificationOrderChanged['orderHistory'][number]['products'];
    return {
      ...history,
      ...history.additional,
      products: product_history_grouped,
    };
  }) as RFQHistory[];
};

const expiringLimit = 1000 * 60 * 60 * 24 * 168; // 182 haylf year - 14days

export const passwordExpiringCheck = (lastChange: string) => {
  const res = {
    expiring: false,
    daysLeft: 0,
  };

  const changed = dayjs(lastChange);
  if (!changed.isValid()) return res;
  const diff = dayjs().diff(changed);
  res.expiring = diff > expiringLimit;
  res.daysLeft =
    14 - Math.floor((diff - expiringLimit) / (1000 * 60 * 60 * 24));

  return res;
};

export const getBackendError = (message: string | undefined) => {
  if (typeof message === 'undefined') return '';
  return t(message.replace('[GraphQL] ', ''));
};

export const getValue = (value?: string | number | undefined | null) => {
  if (typeof value === 'number') return value;
  return value ?? '-';
};

export function useMemoCompare<T>(
  next: T,
  compare: (previous: T | undefined, next: T) => boolean,
) {
  const previousRef = useRef<T>();
  const previous = previousRef.current;
  const isEqual = compare(previous, next);
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  return isEqual ? previous : next;
}

export type RequestFormValues = OrderFormValues & {
  attachments: FileAttachment[];
};

export const getRequestFormValues = async (
  form: FormInstance<OrderFormValues>,
  isAdmin?: boolean,
) => {
  const { eta_etd, attachments, products, ...rest } = form.getFieldsValue();
  const [eta, etd] = eta_etd;
  const nextProducts = isAdmin
    ? products.map(({ final_qty, ...product }) => ({
        adjusted_volume: final_qty,
        ...product,
      }))
    : products.map(({ admin_adjusted_volume, ...product }) => product);
  return {
    ...rest,
    eta: eta.toString(),
    etd: etd.toString(),
    products: nextProducts,
    attachments: await antdFilesToGraphQL(attachments),
  };
};

export async function formValuesToSupplierInput(
  form: FormInstance<OrderFormValues>,
  parentRfqId?: Uuid,
): Promise<SupplierOfferInput> {
  const {
    offer_validity_period,
    order_validity_period,
    vessel_name,
    country,
    port,
    products,
    ...rest
  } = await getRequestFormValues(form);
  return {
    ...rest,
    shipowner_request_order_id: parentRfqId,
    offer_validity_period: offer_validity_period ?? 60 * 60,
    // @ts-expect-error price field is always there
    products: products.map(({ price, final_qty, ...rest }) => ({
      ...rest,
      price: price as number,
    })),
  };
}

type SortItem = {
  updated_at?: string;
};

export const sortOffers = <T extends SortItem[]>(offers: T): T => {
  const offersCopy = [...offers] as T;
  offersCopy.sort(({ updated_at: a }, { updated_at: b }) => {
    if (!a || !b) return 0;
    return dayjs(a).isAfter(dayjs(b)) ? -1 : 1;
  });
  return offersCopy;
};
