import { ACCESS_DENIED, ACCESS_READ, ACCESS_WRITE } from './access-types';
import { defaultState } from './reducer';
import { AccessSet, AccessState } from './types';

const action_allow_supplier_actions: AccessSet = {
  'nav.suppliers': ACCESS_READ,
  'suppliers.app': ACCESS_READ,
  'suppliers.app.table': ACCESS_READ,
  'suppliers.app.table.show-only-valid': ACCESS_READ,
  'suppliers.app.table.modify-column-list': ACCESS_READ,
  'suppliers.app.table.filter': ACCESS_READ,
  'suppliers.rfq': ACCESS_READ,
  'suppliers.rfq.add-product': ACCESS_DENIED,
  'suppliers.rfq.edit-offer': ACCESS_READ,
  'suppliers.rfq.attachments': ACCESS_READ,
  // 'suppliers.rfq.f.remove-product': ACCESS_WRITE,
  'suppliers.rfq.f.product.product_meta_id': ACCESS_READ,
  'suppliers.rfq.f.product.volume_from_to': ACCESS_WRITE,
  'suppliers.rfq.f.product.measure': ACCESS_WRITE,
  'suppliers.rfq.f.product.price': ACCESS_WRITE,
  'suppliers.rfq.f.vessel_name': ACCESS_READ,
  'suppliers.rfq.f.country': ACCESS_READ,
  'suppliers.rfq.f.port': ACCESS_READ,
  'suppliers.rfq.f.eta_etd': ACCESS_WRITE,
  'suppliers.rfq.f.cia': ACCESS_WRITE,
  'suppliers.rfq.f.currency': ACCESS_WRITE,
  'suppliers.rfq.f.time_for_quantity_claim': ACCESS_WRITE,
  'suppliers.rfq.f.time_for_quality_claim': ACCESS_WRITE,
  'suppliers.rfq.f.offer_validity_period': ACCESS_WRITE,
  'suppliers.rfq.f.order_validity_period': ACCESS_READ,
  'suppliers.rfq.f.additional_info': ACCESS_WRITE,
  'suppliers.rfq.f.save': ACCESS_WRITE,
};

const action_allow_shipowner_actions: AccessSet = {
  'nav.shipowners': ACCESS_READ,

  'shipowners.app': ACCESS_READ,
  'shipowners.app.request-for-quote': ACCESS_READ,

  'shipowners.nav.all-offers': ACCESS_READ,
  'shipowners.nav.manage-request': ACCESS_READ,
  'shipowners.all-offers': ACCESS_WRITE,
  'shipowners.all-offers.table': ACCESS_WRITE,
  'shipowners.all-offers.table.show-only-valid': ACCESS_WRITE,
  'shipowners.all-offers.table.modify-column-list': ACCESS_WRITE,
  'shipowners.all-offers.table.filter': ACCESS_WRITE,
  'shipowners.all-offers.details': ACCESS_WRITE,

  'shipowners.all-offers.details.edit-offer': ACCESS_WRITE,
  'shipowners.all-offers.details.attachments': ACCESS_READ,
  // 'shipowners.all-offers.details.f.remove-product': ACCESS_WRITE,
  'shipowners.all-offers.details.f.product.product_meta_id': ACCESS_READ,
  'shipowners.all-offers.details.f.product.volume_from_to': ACCESS_READ,
  'shipowners.all-offers.details.f.product.measure': ACCESS_READ,
  'shipowners.all-offers.details.f.product.price': ACCESS_WRITE,
  'shipowners.all-offers.details.f.vessel_name': ACCESS_READ,
  'shipowners.all-offers.details.f.country': ACCESS_READ,
  'shipowners.all-offers.details.f.port': ACCESS_READ,
  'shipowners.all-offers.details.f.eta_etd': ACCESS_WRITE,
  'shipowners.all-offers.details.f.cia': ACCESS_WRITE,
  'shipowners.all-offers.details.f.currency': ACCESS_READ,
  'shipowners.all-offers.details.f.time_for_quantity_claim': ACCESS_READ,
  'shipowners.all-offers.details.f.time_for_quality_claim': ACCESS_READ,
  'shipowners.all-offers.details.f.offer_validity_period': ACCESS_READ,
  'shipowners.all-offers.details.f.order_validity_period': ACCESS_READ,
  'shipowners.all-offers.details.f.additional_info': ACCESS_READ,
  'shipowners.all-offers.details.f.save': ACCESS_WRITE,

  'shipowners.manage-request': ACCESS_READ,
  'shipowners.manage-request.tab.rfq-offers': ACCESS_READ,
  'shipowners.manage-request.tab.show-only-valid': ACCESS_READ,
  'shipowners.manage-request.tab.modify-column-list': ACCESS_READ,
  'shipowners.manage-request.tab.filter': ACCESS_READ,
  'shipowners.manage-request.details': ACCESS_READ,

  'shipowners.manage-request.details.edit-offer': ACCESS_WRITE,
  'shipowners.manage-request.details.attachments': ACCESS_READ,
  // 'shipowners.manage-request.details.f.remove-product': ACCESS_WRITE,
  'shipowners.manage-request.details.f.product.product_meta_id': ACCESS_READ,
  'shipowners.manage-request.details.f.product.volume_from_to': ACCESS_READ,
  'shipowners.manage-request.details.f.product.measure': ACCESS_READ,
  'shipowners.manage-request.details.f.product.price': ACCESS_WRITE,
  'shipowners.manage-request.details.f.vessel_name': ACCESS_READ,
  'shipowners.manage-request.details.f.country': ACCESS_READ,
  'shipowners.manage-request.details.f.port': ACCESS_READ,
  'shipowners.manage-request.details.f.eta_etd': ACCESS_WRITE,
  'shipowners.manage-request.details.f.cia': ACCESS_WRITE,
  'shipowners.manage-request.details.f.currency': ACCESS_READ,
  'shipowners.manage-request.details.f.time_for_quantity_claim': ACCESS_READ,
  'shipowners.manage-request.details.f.time_for_quality_claim': ACCESS_READ,
  'shipowners.manage-request.details.f.offer_validity_period': ACCESS_WRITE,
  'shipowners.manage-request.details.f.order_validity_period': ACCESS_READ,
  'shipowners.manage-request.details.f.additional_info': ACCESS_READ,
  'shipowners.manage-request.details.f.save': ACCESS_WRITE,

  'shipowners.rfq': ACCESS_READ,
  'shipowners.rfq.add-product': ACCESS_WRITE,
  'shipowners.rfq.edit-offer': ACCESS_WRITE,
  'shipowners.rfq.attachments': ACCESS_WRITE,
  'shipowners.rfq.f.remove-product': ACCESS_WRITE,
  'shipowners.rfq.f.product.product_meta_id': ACCESS_WRITE,
  'shipowners.rfq.f.product.volume_from_to': ACCESS_WRITE,
  'shipowners.rfq.f.product.measure': ACCESS_WRITE,
  'shipowners.rfq.f.product.price': ACCESS_WRITE,
  'shipowners.rfq.f.vessel_name': ACCESS_WRITE,
  'shipowners.rfq.f.country': ACCESS_WRITE,
  'shipowners.rfq.f.port': ACCESS_WRITE,
  'shipowners.rfq.f.eta_etd': ACCESS_WRITE,
  'shipowners.rfq.f.cia': ACCESS_WRITE,
  'shipowners.rfq.f.currency': ACCESS_WRITE,
  'shipowners.rfq.f.time_for_quantity_claim': ACCESS_WRITE,
  'shipowners.rfq.f.time_for_quality_claim': ACCESS_WRITE,
  'shipowners.rfq.f.offer_validity_period': ACCESS_DENIED,
  'shipowners.rfq.f.order_validity_period': ACCESS_WRITE,
  'shipowners.rfq.f.additional_info': ACCESS_WRITE,
  'shipowners.rfq.f.save': ACCESS_WRITE,
};
const action_allow_create_company_bank_account: AccessSet = {
  'banking.app': ACCESS_READ,
  'banking.bank-accounts': ACCESS_READ,
  'banking.bank-accounts.add-bank-accounts': ACCESS_READ,
};
const action_allow_create_wallet: AccessSet = {
  'banking.app': ACCESS_READ,
  'banking.your-balance': ACCESS_READ,
  'banking.your-balance.add-new-currency': ACCESS_READ,
};
const action_allow_wallet_operation: AccessSet = {
  'banking.nav.your-balance': ACCESS_READ,
  'banking.nav.bank-accounts': ACCESS_READ,
  'banking.nav.internal': ACCESS_READ,
  'banking.nav.external': ACCESS_READ,

  'banking.app': ACCESS_READ,
  'banking.app.transaction-filters': ACCESS_READ,

  'banking.internal': ACCESS_READ,
  'banking.internal.table': ACCESS_READ,
  'banking.internal.table-details': ACCESS_READ,

  'banking.external': ACCESS_READ,
  'banking.external.table': ACCESS_READ,
  'banking.external.table-details': ACCESS_READ,

  'transactions.tabs.deposit': ACCESS_READ,
  'transactions.tabs.withdraw': ACCESS_READ,
};

// "action_allow_register_employee_in_existing_company"
// 1: "data_allow_all_product_meta_s"
// 2: "data_allow_own_shipowner_trading_order_s"
// 3: "data_allow_own_trading_order_product_s"
// 4: "action_allow_wallet_operation"
// 5: "data_allow_own_supplier_trading_order_s" ?
// 6: "data_allow_own_user_su" ?
// 9: "action_allow_submit_onboarding" ?
// 10: "data_allow_currency_s" ?
// 11: "data_allow_own_wallet_sud" ?
// 12: "data_allow_own_company_s" ?

const isudList: {
  [propery: string]: {
    _: AccessSet; // keys for all
    i?: AccessSet;
    s?: AccessSet;
    u?: AccessSet;
    d?: AccessSet;
  };
} = {
  action_allow_shipowner_actions: {
    _: action_allow_shipowner_actions,
  },
  action_allow_supplier_actions: {
    _: action_allow_supplier_actions,
  },
  action_allow_all_auth: {
    _: {
      'profile.app': ACCESS_READ,
      'profile.app.last-logins': ACCESS_READ,
      'profile.app.session_timeout': ACCESS_WRITE,
      'profile.app.delete-vessel': ACCESS_READ,
    },
  },
  data_allow_own_company: {
    _: {
      'profile.app': ACCESS_READ,
      'profile.nav.user-profile': ACCESS_READ,
      'profile.nav.company-profile': ACCESS_READ,
    },
    s: {
      'profile.company-profile': ACCESS_READ,
    },
  },
  action_allow_wallet_operation: {
    _: action_allow_wallet_operation,
  },
  action_allow_create_company_bank_account: {
    _: action_allow_create_company_bank_account,
  },
  action_allow_create_wallet: {
    _: action_allow_create_wallet,
  },
  action_allow_submit_kyc: {
    _: {
      'kyc.app': ACCESS_READ,
      'kyc.app.menu': ACCESS_READ,
    },
  },
  action_allow_request_another_activity: {
    _: {
      'request-role.app': ACCESS_READ,
    },
  },
  data_allow_own_company_bank_account: {
    _: {
      'banking.app': ACCESS_READ,
      'banking.bank-accounts': ACCESS_READ,
    },
    i: {
      'banking.bank-accounts.add-bank-accounts': ACCESS_READ,
    },
    s: {
      'banking.bank-accounts.table': ACCESS_READ,
    },
    u: {
      'banking.bank-accounts.table.edit': ACCESS_READ,
    },
    d: {
      'banking.bank-accounts.table.remove': ACCESS_READ,
    },
  },
  data_allow_own_wallet: {
    _: {
      'banking.app': ACCESS_READ,
      'banking.your-balance': ACCESS_READ,
    },
    s: {
      'banking.your-balance.dont-show-empty-wallets': ACCESS_READ,
      'banking.your-balance.table': ACCESS_READ,
    },
    u: {
      'transactions.tabs.deposit': ACCESS_READ,
      'transactions.tabs.withdraw': ACCESS_READ,
    },
  },
  data_allow_all_trading_order: {
    _: {
      'nav.admin': ACCESS_READ,
      'admin.app': ACCESS_READ,
      'admin-tabs.offers': ACCESS_READ,
      'offers.app': ACCESS_READ,
    },
    s: {
      'offers.view': ACCESS_READ,
      'offers.view.request-details': ACCESS_READ,
      // 'offers.view.add-product': ACCESS_READ,
      // 'offers.view.edit-offer': ACCESS_READ,
      'offers.view.attachments': ACCESS_READ,
      // 'offers.view.f.remove-product': ACCESS_READ,
      'offers.view.f.product.product_meta_id': ACCESS_READ,
      'offers.view.f.product.volume_from_to': ACCESS_READ,
      'offers.view.f.product.measure': ACCESS_READ,
      'offers.view.f.product.price': ACCESS_READ,
      'offers.view.f.product.final_qty': ACCESS_READ,
      'offers.view.f.vessel_name': ACCESS_READ,
      'offers.view.f.country': ACCESS_READ,
      'offers.view.f.port': ACCESS_READ,
      'offers.view.f.eta_etd': ACCESS_READ,
      'offers.view.f.cia': ACCESS_READ,
      'offers.view.f.currency': ACCESS_READ,
      'offers.view.f.time_for_quantity_claim': ACCESS_READ,
      'offers.view.f.time_for_quality_claim': ACCESS_READ,
      'offers.view.f.offer_validity_period': ACCESS_READ,
      'offers.view.f.order_validity_period': ACCESS_READ,
      'offers.view.f.additional_info': ACCESS_READ,
      'offers.view.f.save': ACCESS_READ,
      'offers.list': ACCESS_READ,
      'offers.list.table': ACCESS_READ,
      'offers.list.table-filter': ACCESS_READ,
      'offers.list.table-actions': ACCESS_READ,
      'offers.list.table-action-view': ACCESS_READ,
    },
    u: {
      'offers.view': ACCESS_READ,
      'offers.view.request-details': ACCESS_READ,
      'offers.view.add-product': ACCESS_READ,
      'offers.view.edit-offer': ACCESS_WRITE,
      'offers.view.attachments': ACCESS_WRITE,
      'offers.view.f.remove-product': ACCESS_WRITE,
      'offers.view.f.product.product_meta_id': ACCESS_WRITE,
      'offers.view.f.product.volume_from_to': ACCESS_WRITE,
      'offers.view.f.product.measure': ACCESS_WRITE,
      'offers.view.f.product.price': ACCESS_WRITE,
      'offers.view.f.product.final_qty': ACCESS_WRITE,
      'offers.view.f.vessel_name': ACCESS_WRITE,
      'offers.view.f.country': ACCESS_WRITE,
      'offers.view.f.port': ACCESS_WRITE,
      'offers.view.f.eta_etd': ACCESS_WRITE,
      'offers.view.f.cia': ACCESS_WRITE,
      'offers.view.f.currency': ACCESS_WRITE,
      'offers.view.f.time_for_quantity_claim': ACCESS_WRITE,
      'offers.view.f.time_for_quality_claim': ACCESS_WRITE,
      'offers.view.f.offer_validity_period': ACCESS_WRITE,
      'offers.view.f.order_validity_period': ACCESS_WRITE,
      'offers.view.f.additional_info': ACCESS_WRITE,
      'offers.view.f.save': ACCESS_WRITE,

      'offers.list': ACCESS_READ,
      'offers.list.table': ACCESS_READ,
      'offers.list.table-actions': ACCESS_READ,
      'offers.list.table-action-confirm-bdn': ACCESS_READ,
      'offers.list.table-action-cancel': ACCESS_READ,
    },
    d: {
      'offers.view.f.remove-product': ACCESS_WRITE,
    },
  },
  action_allow_admin_add_user: {
    _: {
      'nav.admin': ACCESS_READ,
      'admin.app': ACCESS_READ,
      'admin-tabs.users': ACCESS_READ,
      'users.app': ACCESS_READ,
      'users.list': ACCESS_READ,
      'users.list.add-new-user': ACCESS_READ,
    },
  },
  data_allow_all_user: {
    _: {
      'nav.admin': ACCESS_READ,
      'admin.app': ACCESS_READ,
      'admin-tabs.users': ACCESS_READ,
      'users.app': ACCESS_READ,
    },
    s: {
      'users.list': ACCESS_READ,
      'users.list.table': ACCESS_READ,
      'users.profile': ACCESS_READ,
      'users.profile.personal-info': ACCESS_READ,
      'users.profile.notifications': ACCESS_READ,
      'users.profile.notifications.f.activities': ACCESS_READ,
      'users.profile.notifications.f.news': ACCESS_READ,
      'users.profile.notifications.f.promotion': ACCESS_READ,
      'users.profile.status': ACCESS_READ,
      'users.profile.sessions': ACCESS_READ,
      'users.profile.sessions.last-logins': ACCESS_READ,
      'users.profile.sessions.session-timeout': ACCESS_READ,
      'users.profile.sessions.f.session_timeout': ACCESS_READ,
    },
    u: {
      'users.profile.notifications.f.activities': ACCESS_WRITE,
      'users.profile.notifications.f.news': ACCESS_WRITE,
      'users.profile.notifications.f.promotion': ACCESS_WRITE,
      'users.profile.sessions.f.session_timeout': ACCESS_WRITE,
      'users.profile.personal-info-button-edit': ACCESS_READ,
    },
    d: {
      'users.profile.status.f.disable-enable': ACCESS_WRITE,
    },
    i: {
      'users.list.add-new-user': ACCESS_READ,
    },
  },
  data_allow_all_company: {
    _: {
      'nav.admin': ACCESS_READ,
      'admin.app': ACCESS_READ,
      'admin-tabs.companies': ACCESS_READ,
      'companies.app': ACCESS_READ,
    },
    s: {
      'companies-tabs.main-info': ACCESS_READ,
      'companies-tabs.black-list': ACCESS_READ,
      'companies-tabs.vessels': ACCESS_READ,
      'companies-tabs.employees': ACCESS_READ,
      'companies-tabs.documents': ACCESS_READ,
      'companies-tabs.financial-field': ACCESS_READ,

      'companies.list': ACCESS_READ,
      'companies.list-table': ACCESS_READ,
      'companies.list-table-filter': ACCESS_READ,

      'companies.main-info': ACCESS_READ,
      'companies.main-info-view': ACCESS_READ,
      'companies.main-info.f.company_name': ACCESS_READ,
      'companies.main-info.f.registration_number': ACCESS_READ,
      'companies.main-info.f.contact_person_for_kyc': ACCESS_READ,
      'companies.main-info.f.company_registered_address': ACCESS_READ,
      'companies.main-info.f.actual_operating_address': ACCESS_READ,
      'companies.main-info.f.country_of_tax_residency': ACCESS_READ,
      'companies.main-info.f.country_of_incorporation': ACCESS_READ,
      'companies.main-info.f.date_of_incorporation': ACCESS_READ,
      'companies.main-info.f.annual_turnover': ACCESS_READ,
      'companies.main-info.f.phone_number': ACCESS_READ,
      'companies.main-info.f.company_tin_vat_number': ACCESS_READ,
      'companies.main-info.f.website': ACCESS_READ,
      'companies.main-info.f.your_personal_manager': ACCESS_READ,
      'companies.main-info.f.personal_managers_phone_number': ACCESS_READ,
      'companies.black-list': ACCESS_READ,
      'companies.black-list-table': ACCESS_READ,
      'companies.vessels': ACCESS_READ,
      'companies.vessels.table': ACCESS_READ,
      'companies.vessels.details': ACCESS_READ,
      'companies.emloyees': ACCESS_READ,
      'companies.emloyees.table': ACCESS_READ,
      'companies.documents': ACCESS_READ,
      'companies.documents.table': ACCESS_READ,
      'companies.financial-field': ACCESS_READ,
      'companies.financial-field.info': ACCESS_READ,
      'companies.financial-field.form': ACCESS_READ,
      'companies.financial-field.f.number_of_outstanding_orders': ACCESS_READ,
      'companies.financial-field.f.credit_limit_amount': ACCESS_READ,
      'companies.financial-field.f.margin_percentage': ACCESS_READ,
      'companies.financial-field.f.platform_fee': ACCESS_READ,
      'companies.logs': ACCESS_READ,
      'companies.logs.table': ACCESS_READ,
      'companies.logs.table-filter': ACCESS_READ,
    },
    u: {
      'companies.main-info-button-change-details': ACCESS_READ,
      'companies.main-info-button-cancel': ACCESS_READ,
      'companies.main-info-edit': ACCESS_READ,
      'companies.main-info.f.submit-button': ACCESS_WRITE,
      'companies.main-info.f.company_name': ACCESS_WRITE,
      'companies.main-info.f.registration_number': ACCESS_WRITE,
      'companies.main-info.f.contact_person_for_kyc': ACCESS_WRITE,
      'companies.main-info.f.company_registered_address': ACCESS_WRITE,
      'companies.main-info.f.actual_operating_address': ACCESS_WRITE,
      'companies.main-info.f.country_of_tax_residency': ACCESS_WRITE,
      'companies.main-info.f.country_of_incorporation': ACCESS_WRITE,
      'companies.main-info.f.date_of_incorporation': ACCESS_WRITE,
      'companies.main-info.f.annual_turnover': ACCESS_WRITE,
      'companies.main-info.f.phone_number': ACCESS_WRITE,
      'companies.main-info.f.company_tin_vat_number': ACCESS_WRITE,
      'companies.main-info.f.website': ACCESS_WRITE,
      'companies.main-info.f.your_personal_manager': ACCESS_WRITE,
      'companies.main-info.f.personal_managers_phone_number': ACCESS_WRITE,

      'companies.financial-field': ACCESS_READ,
      'companies.financial-field.info': ACCESS_READ,
      'companies.financial-field.form': ACCESS_READ,
      'companies.financial-field.change-balance': ACCESS_READ,
      'companies.financial-field.f.number_of_outstanding_orders': ACCESS_WRITE,
      'companies.financial-field.f.credit_limit_amount': ACCESS_WRITE,
      'companies.financial-field.f.margin_percentage': ACCESS_WRITE,
      'companies.financial-field.f.platform_fee': ACCESS_WRITE,
      'companies.financial-field.f.save': ACCESS_WRITE,
    },
    i: {
      'companies.black-list-button-add-to-blacklist': ACCESS_READ,
      'companies.vessels.list.button-add-new-vessel': ACCESS_READ,
      'companies.emloyees.button-add-employee': ACCESS_READ,
    },
    d: {
      'companies.main-info-button-block': ACCESS_READ,
      'companies.black-list-table-remove': ACCESS_READ,
      'companies.vessels.details.button-delete-choosed-vessel': ACCESS_READ,
    },
  },
  data_allow_own_money_transaction: {
    _: {
      'nav.banking': ACCESS_READ,

      'banking.nav.your-balance': ACCESS_READ,
      'banking.nav.bank-accounts': ACCESS_READ,
      'banking.nav.internal': ACCESS_READ,
      'banking.nav.external': ACCESS_READ,
    },
    s: {
      'banking.app': ACCESS_READ,
      'banking.app.transaction-filters': ACCESS_READ,

      'banking.your-balance': ACCESS_READ,
      'banking.your-balance.dont-show-empty-wallets': ACCESS_READ,
      'banking.your-balance.table': ACCESS_READ,

      'banking.bank-accounts': ACCESS_READ,
      'banking.bank-accounts.table': ACCESS_READ,

      'banking.internal': ACCESS_READ,
      'banking.internal.table': ACCESS_READ,
      'banking.internal.table-details': ACCESS_READ,

      'banking.external': ACCESS_READ,
      'banking.external.table': ACCESS_READ,
      'banking.external.table-details': ACCESS_READ,
    },
  },
  data_allow_all_money_transactions: {
    _: {
      'admin.app': ACCESS_READ,
      'admin-tabs.transactions': ACCESS_READ,
      'transactions.app': ACCESS_READ,
    },
    s: {
      'transactions.internal': ACCESS_READ,
      'transactions.internal.table': ACCESS_READ,
      'transactions.internal.table-filter': ACCESS_READ,
      'transactions.internal.table-action-details': ACCESS_READ,
      'transactions.external': ACCESS_READ,
      'transactions.external.table': ACCESS_READ,
      'transactions.external.table-filter': ACCESS_READ,
      'transactions.external.table-action-details': ACCESS_READ,
      'transactions.tabs': ACCESS_READ,
      'transactions.tabs.internal': ACCESS_READ,
      'transactions.tabs.external': ACCESS_READ,
      'transactions.tabs.period': ACCESS_READ,
      'transactions.tabs.currency': ACCESS_READ,
      'transactions.tabs.export': ACCESS_READ,
    },
    i: {
      'transactions.tabs.deposit': ACCESS_READ,
      'transactions.tabs.withdraw': ACCESS_READ,
    },
    u: {
      'transactions.external.table-action-withdrawal': ACCESS_READ,
      'transactions.external.table-action-cancel': ACCESS_READ,
    },
  },
  data_allow_all_file: {
    _: {
      'admin.app': ACCESS_READ,
      'admin-tabs.documents': ACCESS_READ,
      'documents.app': ACCESS_READ,
    },
    s: {
      'documents.list': ACCESS_READ,
      'documents.list.table': ACCESS_READ,
      'documents.list.table-filter': ACCESS_READ,
    },
  },
  data_allow_master_data: {
    _: {
      'admin.app': ACCESS_READ,
      'admin-tabs.master-data': ACCESS_READ,
      'master.app': ACCESS_READ,
    },
    s: {
      'master.ports.table': ACCESS_READ,
      'master.measures.table': ACCESS_READ,
      'master.products.table': ACCESS_READ,
    },
    i: {
      'master.products.add-product': ACCESS_READ,
      'master.measures.add-measure': ACCESS_READ,
      'master.ports.add-port': ACCESS_READ,
    },
    u: {
      'master.ports.table.action-status': ACCESS_READ,
      'master.products.table.action-status': ACCESS_READ,
      'master.measures.table.action-status': ACCESS_READ,
    },
  },
  data_allow_all_version_log: {
    _: {
      'admin.app': ACCESS_READ,
      'admin-tabs.logs': ACCESS_READ,
      'admin-tabs.mailer': ACCESS_READ,
      'logs.app': ACCESS_READ,
    },
  },
  data_allow_all_product_meta: {
    _: {
      'nav.admin': ACCESS_READ,
      'admin.app': ACCESS_READ,
      'admin-tabs.master-data': ACCESS_READ,
      'master.app': ACCESS_READ,
    },
    i: {},
  },
};

export const getStateByRoleSet = (roleSet: string[]): AccessState => {
  console.log('Creating access list for', roleSet);
  const partialState: AccessSet = {};
  roleSet.forEach((setName) => {
    const dataKeys = Object.keys(isudList);
    const setKey = dataKeys.find((key) => setName.search(key) === 0);

    if (!setKey) return;
    const setSubkeys = (setName.replace(setKey, '') || '_').split('');

    const setItem = isudList[setKey];
    for (const subKey of setSubkeys) {
      const set = setItem[subKey];
      if (typeof set === 'undefined') continue;

      for (const accessKey in set) {
        if (
          typeof partialState[accessKey] === 'undefined' ||
          partialState[accessKey] < set[accessKey]
        ) {
          partialState[accessKey] = set[accessKey];
        }
      }
    }
  });

  return {
    ...defaultState,
    ...partialState,
  };
};
