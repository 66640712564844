import { NotificationsState } from './types';
import { NotificationsSubscription } from '@common/__generated__/auth.urql';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

type ActionSetNotifications = {
  type: typeof SET_NOTIFICATIONS;
  payload: NotificationsState['notifications'];
};

export type NotificationAction = ActionSetNotifications;

export const actionSetNotifications = (
  notifications: NotificationsSubscription['user_notification'],
): NotificationAction => ({
  type: SET_NOTIFICATIONS,
  payload: notifications as NotificationsState['notifications'],
});
