import { Button, Col, Form, Popconfirm, Row, Spin, Steps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { InvoicedForm } from '../suppliers/invoiced-form';
import AcceptModal from './accept-modal';
import CreateRequestForm, { AccessKeys } from './create-request-form';
import ExtendRFQModal from './extend-rfq-modal';
import FinalAmountModal from './final-amount-modal';
import { PaymentFee } from './payment-fee';
import {
  TradingOrderInfoSubscription,
  useShipownerOrderActionMutation,
} from '@common/__generated__/trading.urql';
import { SHIPOWNERS } from '@common/routes';
import {
  getOfferDetailsProgressIndex,
  offerDetailsProgressLabels,
  OrderFormValues,
} from '@common/trading/frontend';
import { shipownerManageRequestStatusRequest } from '@common/trading/frontend-shipowner-manage-request';
import { OrderStatus, ShipownerAction } from '@common/trading/status';
import { DeepPartial } from '@common/types/util';
import Attachments from 'src/components/attachments';
import { stringToDateSaveForInput } from 'src/components/date-format';
import FormFooter from 'src/components/form-footer';
import FormMain from 'src/components/form-main';
import FormTitle from 'src/components/form-title';
import { formValuesToSupplierInput } from 'src/components/helpers';
import OrderHistory from 'src/components/order-history';
import {
  daysToInputValue,
  hoursToInputValue,
  minutesToInputValue,
} from 'src/components/period-input';
import { useTradingHistory } from 'src/hooks/use-trading-history';

const offerAccessKeys: AccessKeys = {
  add_product: 'shipowners.all-offers.details.add-product',
  additional_info: 'shipowners.all-offers.details.f.additional_info',
  attachments: 'shipowners.all-offers.details.attachments',
  cia: 'shipowners.all-offers.details.f.cia',
  country: 'shipowners.all-offers.details.f.country',
  currency: 'shipowners.all-offers.details.f.currency',
  eta_etd: 'shipowners.all-offers.details.f.eta_etd',
  measure: 'shipowners.all-offers.details.f.product.measure',
  port: 'shipowners.all-offers.details.f.port',
  price: 'shipowners.all-offers.details.f.product.price',
  final_qty: 'shipowners.all-offers.details.f.product.final_qty',
  product_meta_id: 'shipowners.all-offers.details.f.product.product_meta_id',
  remove_product: 'shipowners.all-offers.details.f.remove-product',
  time_for_quality_claim:
    'shipowners.all-offers.details.f.time_for_quality_claim',
  time_for_quantity_claim:
    'shipowners.all-offers.details.f.time_for_quantity_claim',
  vessel_name: 'shipowners.all-offers.details.f.vessel_name',
  volume_from_to: 'shipowners.all-offers.details.f.product.volume_from_to',
  offer_validity_period:
    'shipowners.all-offers.details.f.offer_validity_period',
  order_validity_period:
    'shipowners.all-offers.details.f.order_validity_period',
};

const manageAccessKeys: AccessKeys = {
  add_product: 'shipowners.manage-request.details.add-product',
  additional_info: 'shipowners.manage-request.details.f.additional_info',
  attachments: 'shipowners.manage-request.details.attachments',
  cia: 'shipowners.manage-request.details.f.cia',
  country: 'shipowners.manage-request.details.f.country',
  currency: 'shipowners.manage-request.details.f.currency',
  eta_etd: 'shipowners.manage-request.details.f.eta_etd',
  measure: 'shipowners.manage-request.details.f.product.measure',
  port: 'shipowners.manage-request.details.f.port',
  price: 'shipowners.manage-request.details.f.product.price',
  final_qty: 'shipowners.manage-request.details.f.product.final_qty',
  product_meta_id:
    'shipowners.manage-request.details.f.product.product_meta_id',
  remove_product: 'shipowners.manage-request.details.f.remove-product',
  time_for_quality_claim:
    'shipowners.manage-request.details.f.time_for_quality_claim',
  time_for_quantity_claim:
    'shipowners.manage-request.details.f.time_for_quantity_claim',
  vessel_name: 'shipowners.manage-request.details.f.vessel_name',
  volume_from_to: 'shipowners.manage-request.details.f.product.volume_from_to',
  offer_validity_period:
    'shipowners.manage-request.details.f.offer_validity_period',
  order_validity_period:
    'shipowners.manage-request.details.f.order_validity_period',
};

export const initialDefaultValues: DeepPartial<OrderFormValues> = {
  time_for_quality_claim: daysToInputValue(30),
  time_for_quantity_claim: daysToInputValue(7),
  order_validity_period: hoursToInputValue(24),
  offer_validity_period: minutesToInputValue(60),
  cia: true,
  products: [],
  attachments: [],
};

export function gqlOrderToFormValues<
  T extends TradingOrderInfoSubscription['trading_order'],
>(data: T): DeepPartial<OrderFormValues> {
  const {
    eta,
    etd,
    products,
    port,
    currency_id,
    company_vessel_id,
    additional,
  } = data || {};
  const mappedProducts = (products ?? []).map(({ id, meta, ...rest }) => ({
    ...rest,
    product_meta_id: id,
  }));
  return {
    ...initialDefaultValues,
    ...additional,
    products: mappedProducts,
    port: port?.id,
    currency: currency_id,
    vessel_name: company_vessel_id,
    eta_etd: [stringToDateSaveForInput(eta), stringToDateSaveForInput(etd)],
  };
}

type Props = {
  orderId?: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TradingOrderDetails: React.FC<Props> = () => {
  const { orderInfo, changeHistory, tradingId } = useTradingHistory();
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const [, sendShipownerAction] = useShipownerOrderActionMutation();
  const [isSendCounterOfferActivated, setIsSendCounterOfferActivated] =
    useState(false);
  const [form] = Form.useForm<OrderFormValues>();

  const isShipownerAllOffers = pathname.startsWith(SHIPOWNERS.ALL_OFFERS);
  const isShipownerManageRequest = pathname.startsWith(
    SHIPOWNERS.MANAGE_REQUEST,
  );

  const accessKeys = isShipownerAllOffers ? offerAccessKeys : manageAccessKeys;

  const [initialValues, setInitialValues] = useState<
    DeepPartial<OrderFormValues>
  >(gqlOrderToFormValues(orderInfo));

  useEffect(() => {
    if (orderInfo) {
      setInitialValues(gqlOrderToFormValues(orderInfo));
    }
  }, [orderInfo]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  if (!orderInfo) {
    return <Spin />;
  }

  const orderStatus = orderInfo.status as OrderStatus;

  // All offers page
  const isDeclineEnabled = orderStatus === OrderStatus.SUPPLIER_SENT_OFFER;
  const isShowFinalAmountEnabled =
    orderStatus === OrderStatus.SHIPOWNER_ACCEPTED;
  const isSendCounterOfferEnabled =
    orderStatus === OrderStatus.SUPPLIER_SENT_OFFER;
  const isAcceptEnabled =
    orderStatus === OrderStatus.SUPPLIER_SENT_OFFER ||
    orderStatus === OrderStatus.SUPPLIER_AMENDED;
  const isAcceptVisible = !isSendCounterOfferActivated;

  // Manage request page
  const isCancelEnabled = orderInfo.isCancellable;
  const isExtendRfqEnabled =
    shipownerManageRequestStatusRequest.includes(orderStatus);

  const handleDecline = async () => {
    const { data } = await sendShipownerAction({
      data: { action: ShipownerAction.DECLINE, order_id: tradingId },
    });
    if (data) {
      // Success
    }
  };

  const handleSendCounterOffer = async () => {
    if (!isSendCounterOfferActivated) {
      setIsSendCounterOfferActivated(true);
      return;
    }
    const { data } = await sendShipownerAction({
      data: {
        action: ShipownerAction.SEND_COUNTER_OFFER,
        order_id: tradingId,
        counter_offer_order: await formValuesToSupplierInput(form),
      },
    });
    if (data) {
      // Success
      setIsSendCounterOfferActivated(false);
    }
  };

  const handleCancel = async () => {
    const { data } = await sendShipownerAction({
      data: { action: ShipownerAction.CANCEL, order_id: tradingId },
    });
    if (data) {
      // Success
    }
  };

  return (
    <div className="container--white">
      <div className="steps-top-align-titles__no-left-offset steps-top-align-titles__only-titles steps-top-align-titles__top-offset steps-top-align-titles steps-top-align-titles__col-5 container">
        <Steps progressDot current={getOfferDetailsProgressIndex(orderInfo)}>
          {offerDetailsProgressLabels.map((label) => (
            <Steps.Step
              key={label}
              title={t(`order_status.offers.offer_details.${label}`)}
            />
          ))}
        </Steps>
      </div>
      <div className="container">
        {orderStatus === OrderStatus.INVOICED ? (
          <InvoicedForm disabled />
        ) : (
          <>
            <FormMain<OrderFormValues>
              form={form}
              changeHistory={changeHistory as Partial<OrderFormValues>[]}
              onSubmit={async () => ({ status: false })}
              initialValues={initialValues}
              layout="vertical"
              requiredMark={false}
            >
              <CreateRequestForm
                accessKeys={accessKeys}
                isSendCounterOffer={isSendCounterOfferActivated}
                initialValues={initialValues}
                disabled={!isSendCounterOfferActivated}
              />
            </FormMain>
            <Attachments />
            <FormTitle>{t('shipowner.payment_calculation')}</FormTitle>
            <PaymentFee
              product={orderInfo.price?.toString() ?? ''}
              platformFee="0"
              total={orderInfo.price?.toString() ?? ''}
            />
          </>
        )}
        {/* <FormItem>
          <Button type="primary" onClick={handleSendOffer}>
            {t('shipowner.form.enter_the_final_amount_of_fuel')}
          </Button>
        </FormItem> */}
        <OrderHistory changeHistory={changeHistory} />
      </div>
      <FormFooter>
        <Row gutter={12}>
          {isShipownerAllOffers && (
            <>
              <Col flex={1}>
                <Popconfirm
                  key="decline"
                  onConfirm={handleDecline}
                  title={t('shipowner.form.decline_popconfirm_title')}
                  okText={t('shipowner.form.decline_popconfirm_ok')}
                  cancelText={t('shipowner.form.decline_popconfirm_cancel')}
                  disabled={!isDeclineEnabled}
                >
                  <Button disabled={!isDeclineEnabled}>
                    {t('shipowner.form.decline')}
                  </Button>
                </Popconfirm>
              </Col>
              <Col>
                <FinalAmountModal
                  disabled={!isShowFinalAmountEnabled}
                  id={tradingId}
                />
              </Col>
              {isSendCounterOfferActivated && (
                <Col>
                  <Button
                    onClick={() => {
                      setIsSendCounterOfferActivated(false);
                      form.resetFields();
                    }}
                  >
                    {t('shipowner.form.back')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type={isSendCounterOfferActivated ? 'primary' : 'default'}
                  disabled={!isSendCounterOfferEnabled}
                  key="counter_offer"
                  onClick={handleSendCounterOffer}
                >
                  {t('shipowner.form.send_counter_offer')}
                </Button>
              </Col>
              {isAcceptVisible && (
                <Col>
                  <AcceptModal id={tradingId} disabled={!isAcceptEnabled} />
                </Col>
              )}
            </>
          )}
          {isShipownerManageRequest && (
            <>
              <Col flex={1}>
                <Popconfirm
                  key="decline"
                  onConfirm={handleCancel}
                  title={'Cancel RFQ'}
                  okText={t('shipowner.form.decline_popconfirm_ok')}
                  cancelText={t('shipowner.form.decline_popconfirm_cancel')}
                  disabled={!isCancelEnabled}
                >
                  {isCancelEnabled ? (
                    <Button>{t('shipowner.form.cancel')}</Button>
                  ) : (
                    <Tooltip title={t('shipowner.rfq_cancel_denied')}>
                      <Button disabled>{t('shipowner.form.cancel')}</Button>
                    </Tooltip>
                  )}
                </Popconfirm>
              </Col>
              <Col>
                <ExtendRFQModal id={tradingId} disabled={!isExtendRfqEnabled} />
              </Col>
            </>
          )}
        </Row>
      </FormFooter>
    </div>
  );
};
