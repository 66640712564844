import { Checkbox, Table, InputNumber, Input } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import PaymentFee from './payment-fee';
import {
  TradingOrderInfoSubscription,
  useTradingFormMetaQuery,
  useTradingOrderInfoSubscription,
} from '@common/__generated__/trading.urql';
import { currencyFormatter } from 'src/components/currency-input';
import FormItem from 'src/components/form-item';
import FormTitle from 'src/components/form-title';
import ModalSpin from 'src/components/modal-spin';
import VolumeInput, { volumeFormatter } from 'src/components/volume-input';

type Row = NonNullable<
  TradingOrderInfoSubscription['trading_order']
>['products'][number];

export const FinalAmountModalContent: React.FC<{ id: string }> = ({ id }) => {
  if (!id) return null;
  const [t] = useTranslation();
  const [{ data, fetching: loading }] = useTradingOrderInfoSubscription({
    variables: { id },
  });
  const orderInfo = data?.trading_order;
  const [{ data: metaData, fetching: loadingMeasure }] =
    useTradingFormMetaQuery();
  if (loading || !orderInfo || loadingMeasure) {
    return <ModalSpin />;
  }
  const measureUnitOptions = metaData?.measureUnit ?? [];

  const products = orderInfo.products;
  const special = {
    dataIndex: 'css-line',
    className: 'collapse-table__line',
  };
  const renderInput = (
    value: string | number,
    name: string,
    id: string,
    disabled?: boolean,
    numberInput?: boolean,
    required?: boolean,
    hidden?: boolean,
  ) => (
    <div>
      <FormItem
        name={['products', id, name]}
        initialValue={value || 0}
        className="offset-bottom__none"
        hidden={hidden}
        rules={
          required
            ? [
                {
                  required: true,
                  message: t('shipowner.errors.final_amount_required'),
                },
              ]
            : undefined
        }
      >
        {numberInput ? (
          <InputNumber min={0} disabled={disabled} />
        ) : (
          <Input disabled={disabled} />
        )}
      </FormItem>
    </div>
  );

  const productsColumns: ColumnType<Row>[] = [
    special,
    {
      width: 150,
      title: t('shipowner.table_col_name'),
      dataIndex: ['meta', 'shortName'],
      key: 'name',
      render: (name, { id }) => renderInput(name, 'name', id, true),
    },
    {
      title: t('shipowner.preliminary_volume'),
      key: 'volume',
      render: ({ volume_from, volume_to, id }) => {
        const v_from = volumeFormatter(volume_from);
        const v_to = volumeFormatter(volume_to);

        return (
          <>
            {renderInput(v_from + ' - ' + v_to, 'volume', id, true)}
            {renderInput(v_from, 'volume_from', id, true, false, false, true)}
            {renderInput(v_to, 'volume_to', id, true, false, false, true)}
          </>
        );
      },
    },
    {
      width: 100,
      title: t('shipowner.table_col_measure'),
      dataIndex: 'measure',
      key: 'measure',
      render: (measure, { id }) => {
        const measureItem = measureUnitOptions.find(({ id }) => id === measure);
        return renderInput(measureItem?.name ?? measure, 'measure', id, true);
      },
    },
    {
      title: t('shipowner.table_col_price'),
      dataIndex: 'price',
      key: 'price',
      render: (price, { id }) =>
        renderInput(currencyFormatter(price), 'price', id, true),
    },
    {
      title: t('shipowner.final_volume'),
      key: 'final_volume',
      render: ({ id, volume_from, volume_to }) => (
        <FormItem
          name={['products', id, 'final_volume']}
          className="offset-bottom__none cy-name-final_volume"
          rules={[
            {
              required: true,
              message: t('shipowner.errors.final_volume_required'),
            },
            {
              message: t('shipowner.errors.final_volume_min', {
                value: volumeFormatter(volume_from),
              }),
              validator: (_, value) => {
                if (value >= volume_from) return Promise.resolve();
                return Promise.reject();
              },
            },
            {
              message: t('shipowner.errors.final_volume_max', {
                value: volumeFormatter(volume_to),
              }),
              validator: (_, value) => {
                if (value <= volume_to) return Promise.resolve();
                return Promise.reject();
              },
            },
          ]}
        >
          <VolumeInput className="full-width" />
        </FormItem>
      ),
    },
    special,
  ];

  return (
    <>
      <div className="container">
        <FormTitle className="offset-bottom__none" suffix={false}>
          {t('shipowner.products')}
        </FormTitle>
      </div>
      <div className="collapse-table offset-bottom">
        <Table
          columns={productsColumns}
          dataSource={products}
          pagination={false}
          rowKey="id"
        />
      </div>
      <div className="container">
        <FormTitle>{t('shipowner.payment_calculation')}</FormTitle>
        <PaymentFee
          product={orderInfo.price?.toString() ?? ''}
          platformFee="0"
          total={orderInfo.price?.toString() ?? ''}
        />
      </div>
      <div className="container">
        <FormItem
          valuePropName="checked"
          name="final_amount_terms"
          className="cy-name-final_final_amount_terms"
          rules={[
            {
              required: true,
              message: t('shipowner.errors.final_amount_terms_required'),
            },
          ]}
        >
          <Checkbox>{t('shipowner.final_amount_terms')}</Checkbox>
        </FormItem>
      </div>
    </>
  );
};

export default FinalAmountModalContent;
