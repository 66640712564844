import { Layout } from 'antd';

import GuestFooter from '../layout-elements/guest-footer';
import GuestLogo from '../layout-elements/guest-logo';
import HelpContainer from '../layout-elements/help';
import Languages from '../layout-elements/languages';
import Navigation from '../layout-elements/navigation';
import { Props } from './types';
import LayoutLoader from 'src/components/layout-loader';
import ReviewModal from 'src/components/review-modal';

const { Content, Footer, Header } = Layout;

export const LAYOUT_MODE_GUEST = 'guest';
export const LAYOUT_MODE_ADMIN = 'admin';
export const LAYOUT_MODE_MIXED = 'mixed';

export const LayoutMain: React.FC<Props> = ({
  mode = LAYOUT_MODE_ADMIN,
  children,
}) => {
  let showHeader = true;
  let showFooter = false;
  let showLanguages = false;
  let mapBackground = '';
  let showGuestLogo = false;
  let showHelp = false;

  switch (mode) {
    case LAYOUT_MODE_GUEST:
      showHeader = false;
      showFooter = true;
      showHelp = true;
      showLanguages = true;
      mapBackground = 'layout-map-background';
      showGuestLogo = true;
      break;
    case LAYOUT_MODE_MIXED:
      showHeader = true;
      showFooter = false;
      // showHelp = true
      showLanguages = false;
      mapBackground = 'layout-map-background';
      showGuestLogo = false;
  }

  return (
    <div className={`layout--pre ${mapBackground}`}>
      <Layout className={`layout ${mapBackground}`}>
        {showHeader && (
          <Header className="full-width header-main-color">
            <Navigation />
          </Header>
        )}
        <Content className="full-width">
          {showGuestLogo && <GuestLogo />}
          <LayoutLoader>{children}</LayoutLoader>
        </Content>
        <ReviewModal />
        {showFooter && (
          <Footer className="full-width layout-main-footer">
            <GuestFooter />
          </Footer>
        )}
        <div className="layout-widgets-box">
          {showHelp && <HelpContainer />}
          {showLanguages && <Languages />}
        </div>
      </Layout>
    </div>
  );
};
