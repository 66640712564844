import { UploadFile } from 'antd/lib/upload/interface';
import { Dayjs } from 'dayjs';

import { OrderStatus } from './status';

export type StatusedOrder = { status?: string };

// Table column 2
export const offerDetailsProgressLabels = [
  'request',
  'ordered',
  'delivered',
  'invoiced',
  'closed',
] as const;

// Table column 5
export const rfqDetailsProgressLabels = offerDetailsProgressLabels;

// Table columns 2 and 5
const statusToOfferDetailsProgress: Record<
  OrderStatus,
  typeof offerDetailsProgressLabels[number]
> = {
  [OrderStatus.NEW]: 'request',
  [OrderStatus.SHIPOWNER_CANCEL]: 'request',
  [OrderStatus.SHIPOWNER_EXTENDED_RFQ]: 'request',
  [OrderStatus.SUPPLIER_REJECTED]: 'request',
  [OrderStatus.SUPPLIER_SENT_OFFER]: 'request',
  [OrderStatus.SUPPLIER_AMENDED]: 'request',
  [OrderStatus.SHIPOWNER_DECLINED]: 'request',
  [OrderStatus.SHIPOWNER_SENT_COUNTER_OFFER]: 'request',
  [OrderStatus.SHIPOWNER_ACCEPTED]: 'ordered',
  [OrderStatus.EXPIRED]: 'request',
  [OrderStatus.SHIPOWNER_FINAL_QTY_ENTERED]: 'ordered',
  [OrderStatus.SHIPOWNER_FINAL_QTY_MODIFIED]: 'ordered',
  [OrderStatus.SUPPLIER_BDN_UPLOADED]: 'delivered',
  [OrderStatus.INVOICED]: 'invoiced',
  [OrderStatus.PAID]: 'closed',
};

// Table column 2
export function getOfferDetailsProgressIndex(order: StatusedOrder): number {
  return offerDetailsProgressLabels.indexOf(
    statusToOfferDetailsProgress[order.status ?? OrderStatus.NEW],
  );
}

// Table column 5
export const getRfqDetailsProgressIndex = getOfferDetailsProgressIndex;

/** Type for antd forms */
export type OrderFormValuesProduct = {
  product_meta_id: string;
  volume_from: number;
  volume_to: number;
  measure: string;
  final_qty?: number;
  price?: number;
  admin_adjusted_volume?: number;
};

/** Type for antd forms */
export type OrderFormValues = {
  vessel_name: string;
  country: string;
  port: string;
  eta_etd: [string | Dayjs, string | Dayjs];
  cia: boolean;
  currency: string;
  time_for_quality_claim: number;
  time_for_quantity_claim: number;
  order_validity_period: number;
  offer_validity_period?: number;
  products: OrderFormValuesProduct[];
  attachments: UploadFile[];
  additional_info: string;
};
