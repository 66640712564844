import * as Types from './types';

import gql from 'graphql-tag';
import { UserInfoFragmentDoc, KycDocumentFragmentDoc } from './fragments.urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserInfoQueryVariables = Types.Exact<{
  user_id: Types.Scalars['uuid'];
}>;


export type UserInfoQuery = { __typename?: 'query_root', user?: { __typename?: 'user', user_id: string, email: string, password?: string, registered_at: string, login_attempts: number, is_blocked: boolean, role: string, session_timeout: number, kycDocuments?: Array<{ __typename?: 'file', id: string, fileName?: string, mime?: string }>, jwtAuths: Array<{ __typename?: 'jwt_auth', refresh_jwt: string, chatwoot_identifier_hash?: string }> } };

export type UploadFileMutationVariables = Types.Exact<{
  data: Types.FileInsertInput;
}>;


export type UploadFileMutation = { __typename?: 'mutation_root', insert_file_one?: { __typename?: 'file', id: string } };

export type AvailableRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AvailableRolesQuery = { __typename?: 'query_root', available_roles: { __typename?: 'AvailableRolesOutput', roles: Array<{ __typename?: 'AvailableRoleInfo', id: string, name: string, description?: string, inherits_from: Array<string> }> } };

export type AdminAllUsersQueryVariables = Types.Exact<{
  lastId?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  filter?: Types.InputMaybe<Types.UserBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AdminAllUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', email: string, role: string, additional: Record<string, any>, id: string, fullName?: string, companyId?: string, phoneNumber?: string, createdAt?: string }> };

export type AdminUserInfoSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type AdminUserInfoSubscription = { __typename?: 'subscription_root', user_by_pk?: { __typename?: 'user', user_id: string, email: string, phone_number?: string, session_timeout: number, role: string, additional: Record<string, any> } };

export type AdminAllCompaniesQueryVariables = Types.Exact<{
  filter?: Types.CompanyBoolExp;
  lastId?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  currency?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type AdminAllCompaniesQuery = { __typename?: 'query_root', company: Array<{ __typename?: 'company', additional: Record<string, any>, id: string, name: string, role?: string, createdAt?: string, available?: number, balance?: number, hold?: number, blocked: boolean, numUsers: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } } }> };

export type AdminUpdateCompanyInfoMutationVariables = Types.Exact<{
  company_id: Types.Scalars['uuid'];
  data: Types.CompanySetInput;
}>;


export type AdminUpdateCompanyInfoMutation = { __typename?: 'mutation_root', update_company_by_pk?: { __typename?: 'company', company_id: string } };

export type AdminCompanyInfoSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  currency?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type AdminCompanyInfoSubscription = { __typename?: 'subscription_root', company_by_pk?: { __typename?: 'company', additional: Record<string, any>, id: string, name: string, maxOutstandingOrders: number, availableAmount?: number, onHoldAmount?: number, balanceAmount?: number, creditLimit: number, outstandingOrdersWarn: number, marginalPercentage: number, platformFee: number, blocked: boolean } };

export type CompanyDocumentsSubscriptionVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CompanyDocumentsSubscription = { __typename?: 'subscription_root', file: Array<{ __typename?: 'file', document_kind: string, additional: Record<string, any>, id: string, name?: string, mimeType?: string, uploadedBy?: { __typename?: 'user', fullName?: string } }> };

export type AdminAddUserMutationVariables = Types.Exact<{
  data: Types.AdminAddUserInput;
}>;


export type AdminAddUserMutation = { __typename?: 'mutation_root', admin_add_user: { __typename?: 'DummyOutput', ok: boolean } };

export type AdminSetCompanyBlockedMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  blocked: Types.Scalars['Boolean'];
}>;


export type AdminSetCompanyBlockedMutation = { __typename?: 'mutation_root', update_company_by_pk?: { __typename?: 'company', company_id: string } };

export type AdminAllDocumentsQueryVariables = Types.Exact<{
  lastId?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  filter?: Types.FileBoolExp;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AdminAllDocumentsQuery = { __typename?: 'query_root', file: Array<{ __typename?: 'file', id: string, name?: string, mimeType?: string, kind: string, createdAt?: string, uploadedBy?: { __typename?: 'user', id: string, fullName?: string } }> };

export type AdminDeleteDocumentMutationVariables = Types.Exact<{
  fileId: Types.Scalars['uuid'];
}>;


export type AdminDeleteDocumentMutation = { __typename?: 'mutation_root', delete_file_by_pk?: { __typename?: 'file', file_id: string } };

export type FileWithContentFragment = { __typename?: 'file', content: string, name?: string, type?: string };

export type GetDocumentContentQueryVariables = Types.Exact<{
  fileId: Types.Scalars['uuid'];
}>;


export type GetDocumentContentQuery = { __typename?: 'query_root', file?: { __typename?: 'file', content: string, name?: string, type?: string } };

export type AdminTradingOrdersSubscriptionVariables = Types.Exact<{
  filter?: Types.TradingOrderBoolExp;
  limit: Types.Scalars['Int'];
}>;


export type AdminTradingOrdersSubscription = { __typename?: 'subscription_root', tradingOrder: Array<{ __typename?: 'trading_order', additional: Record<string, any>, eta: string, etd: string, id: string, shortId: string, price?: number, status?: string, expiresAt?: string, updatedAt?: string, createdAt?: string, totalPrice?: number, currency?: { __typename?: 'currency', code: string }, vessel?: { __typename?: 'company_vessel', meta: { __typename?: 'vessel_meta', name: string } }, port?: { __typename?: 'ship_port', name: string, additional: Record<string, any>, id: string }, products: Array<{ __typename?: 'trading_order_product', price?: number, additional: Record<string, any>, id: string, volumeFrom: number, volumeTo: number, meta?: { __typename?: 'product_meta', additional: Record<string, any>, fullName: string, shortName: string } }>, attachments?: Array<{ __typename?: 'file', additional: Record<string, any>, id: string, name?: string, mimeType?: string }>, bdnDocument?: Array<{ __typename?: 'file', id: string, name?: string }>, createdBy?: { __typename?: 'user', name?: string, company?: { __typename?: 'company', name: string } }, parentRfq?: { __typename?: 'trading_order', createdBy?: { __typename?: 'user', name?: string, company?: { __typename?: 'company', name: string } } }, shipowner?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }>, supplier?: Array<{ __typename?: 'user', company?: { __typename?: 'company', name: string } }> }> };

export type AdminVersionLogSubscriptionVariables = Types.Exact<{
  filter?: Types.VersionLogBoolExp;
}>;


export type AdminVersionLogSubscription = { __typename?: 'subscription_root', version_log: Array<{ __typename?: 'version_log', id: string, isDeleted: boolean, tableName: string, tableSchema: string, updatedAt: string, operation?: string, user?: { __typename?: 'user', id: string, fullName?: string } }> };

export type AdminTransferMoneyMutationVariables = Types.Exact<{
  walletId: Types.Scalars['uuid'];
  amount: Types.Scalars['bigint'];
  txType: Types.Scalars['String'];
}>;


export type AdminTransferMoneyMutation = { __typename?: 'mutation_root', insert_money_transaction_one?: { __typename?: 'money_transaction', money_transaction_id: string } };

export type AdminAllWalletsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminAllWalletsQuery = { __typename?: 'query_root', wallet: Array<{ __typename?: 'wallet', id: string, company: { __typename?: 'company', name: string, owner: { __typename?: 'user', fullName?: string } }, currency: { __typename?: 'currency', currency_code: string } }> };

export type AdminWalletOperationMutationVariables = Types.Exact<{
  data: Types.AdminWalletOperationInput;
}>;


export type AdminWalletOperationMutation = { __typename?: 'mutation_root', admin_wallet_operation: { __typename?: 'DummyOutput', ok: boolean } };

export type AdminUploadFileMutationVariables = Types.Exact<{
  data: Types.FileInsertInput;
}>;


export type AdminUploadFileMutation = { __typename?: 'mutation_root', insert_file_one?: { __typename?: 'file', file_id: string } };

export type AdminSendEmailMutationVariables = Types.Exact<{
  data: Types.AdminSendEmailInput;
}>;


export type AdminSendEmailMutation = { __typename?: 'mutation_root', admin_send_email: { __typename?: 'DummyOutput', ok: boolean } };

export type AdminOrderActionMutationVariables = Types.Exact<{
  data: Types.AdminOrderActionInput;
}>;


export type AdminOrderActionMutation = { __typename?: 'mutation_root', admin_order_action: { __typename?: 'DummyOutput', ok: boolean } };

export type MasterDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MasterDataQuery = { __typename?: 'query_root', currency: Array<{ __typename?: 'currency', active: boolean, id: string, code: string }>, shipPort: Array<{ __typename?: 'ship_port', name: string, active: boolean, id: string, countryCode: string }>, measureUnit: Array<{ __typename?: 'measure_unit_meta', active: boolean, id: string, label: string, title?: string }>, product: Array<{ __typename?: 'product_meta', additional: Record<string, any>, active: boolean, id: string, shortName: string, fullName: string }> };

export type AdminAddCurrencyMutationVariables = Types.Exact<{
  data: Types.CurrencyInsertInput;
}>;


export type AdminAddCurrencyMutation = { __typename?: 'mutation_root', insert_currency_one?: { __typename?: 'currency', currency_id: string } };

export type AdminAddPortMutationVariables = Types.Exact<{
  data: Types.ShipPortInsertInput;
}>;


export type AdminAddPortMutation = { __typename?: 'mutation_root', insert_ship_port_one?: { __typename?: 'ship_port', ship_port_id: string } };

export type AdminAddMeasureUnitMutationVariables = Types.Exact<{
  data: Types.MeasureUnitMetaInsertInput;
}>;


export type AdminAddMeasureUnitMutation = { __typename?: 'mutation_root', insert_measure_unit_meta_one?: { __typename?: 'measure_unit_meta', measure_unit_meta_id: string } };

export type AdminAddProductMutationVariables = Types.Exact<{
  data: Types.ProductMetaInsertInput;
}>;


export type AdminAddProductMutation = { __typename?: 'mutation_root', insert_product_meta_one?: { __typename?: 'product_meta', product_meta_id: string } };

export type AdminVesselsSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminVesselsSubscription = { __typename?: 'subscription_root', vessel: Array<{ __typename?: 'vessel_meta', mmsi: string, imo?: string, additional: Record<string, any>, id: string, countryCode?: string, name: string }> };

export const FileWithContentFragmentDoc = gql`
    fragment FileWithContent on file {
  content: content_base64
  name: file_name
  type: mime_type
}
    `;
export const UserInfoDocument = gql`
    query UserInfo($user_id: uuid!) {
  user: user_by_pk(user_id: $user_id) {
    ...UserInfo
    kycDocuments: latest_kyc_documents_com {
      ...KycDocument
    }
  }
}
    ${UserInfoFragmentDoc}
${KycDocumentFragmentDoc}`;

export function useUserInfoQuery(options: Omit<Urql.UseQueryArgs<UserInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<UserInfoQuery>({ query: UserInfoDocument, ...options });
};
export const UploadFileDocument = gql`
    mutation UploadFile($data: file_insert_input!) {
  insert_file_one(object: $data) {
    id: file_id
  }
}
    `;

export function useUploadFileMutation() {
  return Urql.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument);
};
export const AvailableRolesDocument = gql`
    query AvailableRoles {
  available_roles {
    roles {
      id
      name
      description
      inherits_from
    }
  }
}
    `;

export function useAvailableRolesQuery(options?: Omit<Urql.UseQueryArgs<AvailableRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<AvailableRolesQuery>({ query: AvailableRolesDocument, ...options });
};
export const AdminAllUsersDocument = gql`
    query AdminAllUsers($lastId: timestamptz, $filter: user_bool_exp = {}, $limit: Int) {
  user(
    where: {_and: [{created_at_com: {_lt: $lastId}}, $filter]}
    limit: $limit
    order_by: {created_at_com: desc}
  ) {
    id: user_id
    email
    fullName: user_full_name_com
    role
    companyId: company_id
    phoneNumber: phone_number
    additional
    createdAt: created_at_com
  }
}
    `;

export function useAdminAllUsersQuery(options?: Omit<Urql.UseQueryArgs<AdminAllUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminAllUsersQuery>({ query: AdminAllUsersDocument, ...options });
};
export const AdminUserInfoDocument = gql`
    subscription AdminUserInfo($userId: uuid!) {
  user_by_pk(user_id: $userId) {
    user_id
    email
    phone_number
    session_timeout
    role
    additional
  }
}
    `;

export function useAdminUserInfoSubscription<TData = AdminUserInfoSubscription>(options: Omit<Urql.UseSubscriptionArgs<AdminUserInfoSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AdminUserInfoSubscription, TData>) {
  return Urql.useSubscription<AdminUserInfoSubscription, TData, AdminUserInfoSubscriptionVariables>({ query: AdminUserInfoDocument, ...options }, handler);
};
export const AdminAllCompaniesDocument = gql`
    query AdminAllCompanies($filter: company_bool_exp! = {}, $lastId: timestamptz, $limit: Int, $currency: uuid) {
  company(
    where: {_and: [{created_at_com: {_lt: $lastId}}, $filter]}
    limit: $limit
    order_by: {created_at_com: desc}
  ) {
    id: company_id
    name: company_name
    role: role_com
    additional
    createdAt: created_at_com
    available: available_amount_com(args: {currency_id: $currency})
    balance: balance_amount_com(args: {currency_id: $currency})
    hold: on_hold_amount_com(args: {currency_id: $currency})
    numUsers: employees_rel_aggregate {
      aggregate {
        count
      }
    }
    blocked: is_blocked
  }
}
    `;

export function useAdminAllCompaniesQuery(options?: Omit<Urql.UseQueryArgs<AdminAllCompaniesQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminAllCompaniesQuery>({ query: AdminAllCompaniesDocument, ...options });
};
export const AdminUpdateCompanyInfoDocument = gql`
    mutation AdminUpdateCompanyInfo($company_id: uuid!, $data: company_set_input!) {
  update_company_by_pk(pk_columns: {company_id: $company_id}, _set: $data) {
    company_id
  }
}
    `;

export function useAdminUpdateCompanyInfoMutation() {
  return Urql.useMutation<AdminUpdateCompanyInfoMutation, AdminUpdateCompanyInfoMutationVariables>(AdminUpdateCompanyInfoDocument);
};
export const AdminCompanyInfoDocument = gql`
    subscription AdminCompanyInfo($id: uuid!, $currency: uuid) {
  company_by_pk(company_id: $id) {
    id: company_id
    name: company_name
    additional
    maxOutstandingOrders: max_outstanding_orders
    availableAmount: available_amount_com(args: {currency_id: $currency})
    onHoldAmount: on_hold_amount_com(args: {currency_id: $currency})
    balanceAmount: balance_amount_com(args: {currency_id: $currency})
    creditLimit: credit_limit
    outstandingOrdersWarn: outstanding_orders_warn
    marginalPercentage: marginal_percentage
    platformFee: platform_fee
    blocked: is_blocked
  }
}
    `;

export function useAdminCompanyInfoSubscription<TData = AdminCompanyInfoSubscription>(options: Omit<Urql.UseSubscriptionArgs<AdminCompanyInfoSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AdminCompanyInfoSubscription, TData>) {
  return Urql.useSubscription<AdminCompanyInfoSubscription, TData, AdminCompanyInfoSubscriptionVariables>({ query: AdminCompanyInfoDocument, ...options }, handler);
};
export const CompanyDocumentsDocument = gql`
    subscription CompanyDocuments($companyId: uuid!) {
  file(
    where: {uploaded_by_rel: {company_rel: {company_id: {_eq: $companyId}}}}
    limit: 15
  ) {
    id: file_id
    name: file_name
    mimeType: mime_type
    document_kind
    uploadedBy: uploaded_by_rel {
      fullName: user_full_name_com
    }
    additional
  }
}
    `;

export function useCompanyDocumentsSubscription<TData = CompanyDocumentsSubscription>(options: Omit<Urql.UseSubscriptionArgs<CompanyDocumentsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CompanyDocumentsSubscription, TData>) {
  return Urql.useSubscription<CompanyDocumentsSubscription, TData, CompanyDocumentsSubscriptionVariables>({ query: CompanyDocumentsDocument, ...options }, handler);
};
export const AdminAddUserDocument = gql`
    mutation AdminAddUser($data: AdminAddUserInput!) {
  admin_add_user(data: $data) {
    ok
  }
}
    `;

export function useAdminAddUserMutation() {
  return Urql.useMutation<AdminAddUserMutation, AdminAddUserMutationVariables>(AdminAddUserDocument);
};
export const AdminSetCompanyBlockedDocument = gql`
    mutation AdminSetCompanyBlocked($companyId: uuid!, $blocked: Boolean!) {
  update_company_by_pk(
    pk_columns: {company_id: $companyId}
    _set: {is_blocked: $blocked}
  ) {
    company_id
  }
}
    `;

export function useAdminSetCompanyBlockedMutation() {
  return Urql.useMutation<AdminSetCompanyBlockedMutation, AdminSetCompanyBlockedMutationVariables>(AdminSetCompanyBlockedDocument);
};
export const AdminAllDocumentsDocument = gql`
    query AdminAllDocuments($lastId: timestamptz, $filter: file_bool_exp! = {}, $limit: Int) {
  file(
    where: {_and: [{created_at_com: {_lt: $lastId}}, $filter]}
    limit: $limit
    order_by: {created_at_com: desc}
  ) {
    id: file_id
    name: file_name
    mimeType: mime_type
    kind: document_kind
    uploadedBy: uploaded_by_rel {
      id: user_id
      fullName: user_full_name_com
    }
    createdAt: created_at_com
  }
}
    `;

export function useAdminAllDocumentsQuery(options?: Omit<Urql.UseQueryArgs<AdminAllDocumentsQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminAllDocumentsQuery>({ query: AdminAllDocumentsDocument, ...options });
};
export const AdminDeleteDocumentDocument = gql`
    mutation AdminDeleteDocument($fileId: uuid!) {
  delete_file_by_pk(file_id: $fileId) {
    file_id
  }
}
    `;

export function useAdminDeleteDocumentMutation() {
  return Urql.useMutation<AdminDeleteDocumentMutation, AdminDeleteDocumentMutationVariables>(AdminDeleteDocumentDocument);
};
export const GetDocumentContentDocument = gql`
    query GetDocumentContent($fileId: uuid!) {
  file: file_by_pk(file_id: $fileId) {
    ...FileWithContent
  }
}
    ${FileWithContentFragmentDoc}`;

export function useGetDocumentContentQuery(options: Omit<Urql.UseQueryArgs<GetDocumentContentQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDocumentContentQuery>({ query: GetDocumentContentDocument, ...options });
};
export const AdminTradingOrdersDocument = gql`
    subscription AdminTradingOrders($filter: trading_order_bool_exp! = {}, $limit: Int!) {
  tradingOrder: trading_order(
    where: $filter
    limit: $limit
    order_by: [{updated_at_com: desc}, {short_id: asc}]
  ) {
    id: trading_order_id
    shortId: short_id
    price: total_price_com
    status: status_com
    expiresAt: expires_at
    updatedAt: updated_at_com
    additional
    eta
    etd
    currency: currency_rel {
      code: currency_code
    }
    vessel: company_vessel_rel {
      meta: meta_rel {
        name: ship_name
      }
    }
    port: ship_port_rel {
      id: ship_port_id
      name
      additional
    }
    products: products_rel {
      id: product_meta_id
      meta: product_meta_rel {
        fullName: full_name
        additional
        shortName: short_name
      }
      price
      volumeFrom: volume_from
      volumeTo: volume_to
      additional
    }
    attachments: attachments_com {
      id: file_id
      name: file_name
      additional
      mimeType: mime_type
    }
    bdnDocument: bdn_document_com {
      id: file_id
      name: file_name
    }
    createdAt: created_at_com
    createdBy: created_by_rel {
      name: user_full_name_com
      company: company_rel {
        name: company_name
      }
    }
    parentRfq: shipowner_request_rel {
      createdBy: created_by_rel {
        name: user_full_name_com
        company: company_rel {
          name: company_name
        }
      }
    }
    totalPrice: total_price_com
    shipowner: shipowner_com {
      company: company_rel {
        name: company_name
      }
    }
    supplier: supplier_com {
      company: company_rel {
        name: company_name
      }
    }
  }
}
    `;

export function useAdminTradingOrdersSubscription<TData = AdminTradingOrdersSubscription>(options: Omit<Urql.UseSubscriptionArgs<AdminTradingOrdersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AdminTradingOrdersSubscription, TData>) {
  return Urql.useSubscription<AdminTradingOrdersSubscription, TData, AdminTradingOrdersSubscriptionVariables>({ query: AdminTradingOrdersDocument, ...options }, handler);
};
export const AdminVersionLogDocument = gql`
    subscription AdminVersionLog($filter: version_log_bool_exp! = {}) {
  version_log(where: $filter, limit: 30, order_by: {updated_at: desc}) {
    id: version_log_id
    isDeleted: is_deleted
    tableName: table_name
    tableSchema: table_schema
    updatedAt: updated_at
    operation: operation_com
    user: user_rel {
      id: user_id
      fullName: user_full_name_com
    }
  }
}
    `;

export function useAdminVersionLogSubscription<TData = AdminVersionLogSubscription>(options: Omit<Urql.UseSubscriptionArgs<AdminVersionLogSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AdminVersionLogSubscription, TData>) {
  return Urql.useSubscription<AdminVersionLogSubscription, TData, AdminVersionLogSubscriptionVariables>({ query: AdminVersionLogDocument, ...options }, handler);
};
export const AdminTransferMoneyDocument = gql`
    mutation AdminTransferMoney($walletId: uuid!, $amount: bigint!, $txType: String!) {
  insert_money_transaction_one(
    object: {wallet_1: $walletId, amount: $amount, tx_type: $txType, tx_status: "completed"}
  ) {
    money_transaction_id
  }
}
    `;

export function useAdminTransferMoneyMutation() {
  return Urql.useMutation<AdminTransferMoneyMutation, AdminTransferMoneyMutationVariables>(AdminTransferMoneyDocument);
};
export const AdminAllWalletsDocument = gql`
    query AdminAllWallets {
  wallet {
    id: wallet_id
    company: company_rel {
      name: company_name
      owner: owner_rel {
        fullName: user_full_name_com
      }
    }
    currency: currency_rel {
      currency_code
    }
  }
}
    `;

export function useAdminAllWalletsQuery(options?: Omit<Urql.UseQueryArgs<AdminAllWalletsQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminAllWalletsQuery>({ query: AdminAllWalletsDocument, ...options });
};
export const AdminWalletOperationDocument = gql`
    mutation AdminWalletOperation($data: AdminWalletOperationInput!) {
  admin_wallet_operation(data: $data) {
    ok
  }
}
    `;

export function useAdminWalletOperationMutation() {
  return Urql.useMutation<AdminWalletOperationMutation, AdminWalletOperationMutationVariables>(AdminWalletOperationDocument);
};
export const AdminUploadFileDocument = gql`
    mutation AdminUploadFile($data: file_insert_input!) {
  insert_file_one(object: $data) {
    file_id
  }
}
    `;

export function useAdminUploadFileMutation() {
  return Urql.useMutation<AdminUploadFileMutation, AdminUploadFileMutationVariables>(AdminUploadFileDocument);
};
export const AdminSendEmailDocument = gql`
    mutation AdminSendEmail($data: AdminSendEmailInput!) {
  admin_send_email(data: $data) {
    ok
  }
}
    `;

export function useAdminSendEmailMutation() {
  return Urql.useMutation<AdminSendEmailMutation, AdminSendEmailMutationVariables>(AdminSendEmailDocument);
};
export const AdminOrderActionDocument = gql`
    mutation AdminOrderAction($data: AdminOrderActionInput!) {
  admin_order_action(data: $data) {
    ok
  }
}
    `;

export function useAdminOrderActionMutation() {
  return Urql.useMutation<AdminOrderActionMutation, AdminOrderActionMutationVariables>(AdminOrderActionDocument);
};
export const MasterDataDocument = gql`
    query MasterData {
  currency(order_by: {currency_code: asc}) {
    id: currency_id
    code: currency_code
    active
  }
  shipPort: ship_port(order_by: {name: asc}) {
    id: ship_port_id
    countryCode: country_code
    name
    active
  }
  measureUnit: measure_unit_meta(order_by: {name: asc}) {
    id: measure_unit_meta_id
    label: name
    title: description
    active
  }
  product: product_meta(order_by: {short_name: asc}) {
    id: product_meta_id
    shortName: short_name
    fullName: full_name
    additional
    active
  }
}
    `;

export function useMasterDataQuery(options?: Omit<Urql.UseQueryArgs<MasterDataQueryVariables>, 'query'>) {
  return Urql.useQuery<MasterDataQuery>({ query: MasterDataDocument, ...options });
};
export const AdminAddCurrencyDocument = gql`
    mutation AdminAddCurrency($data: currency_insert_input!) {
  insert_currency_one(
    object: $data
    on_conflict: {constraint: currency_currency_code_key, update_columns: [active]}
  ) {
    currency_id
  }
}
    `;

export function useAdminAddCurrencyMutation() {
  return Urql.useMutation<AdminAddCurrencyMutation, AdminAddCurrencyMutationVariables>(AdminAddCurrencyDocument);
};
export const AdminAddPortDocument = gql`
    mutation AdminAddPort($data: ship_port_insert_input!) {
  insert_ship_port_one(
    object: $data
    on_conflict: {constraint: ship_port_country_code_name_key, update_columns: [active]}
  ) {
    ship_port_id
  }
}
    `;

export function useAdminAddPortMutation() {
  return Urql.useMutation<AdminAddPortMutation, AdminAddPortMutationVariables>(AdminAddPortDocument);
};
export const AdminAddMeasureUnitDocument = gql`
    mutation AdminAddMeasureUnit($data: measure_unit_meta_insert_input!) {
  insert_measure_unit_meta_one(
    object: $data
    on_conflict: {constraint: measure_unit_meta_name_key, update_columns: [active]}
  ) {
    measure_unit_meta_id
  }
}
    `;

export function useAdminAddMeasureUnitMutation() {
  return Urql.useMutation<AdminAddMeasureUnitMutation, AdminAddMeasureUnitMutationVariables>(AdminAddMeasureUnitDocument);
};
export const AdminAddProductDocument = gql`
    mutation AdminAddProduct($data: product_meta_insert_input!) {
  insert_product_meta_one(
    object: $data
    on_conflict: {constraint: product_meta_short_name_key, update_columns: [active]}
  ) {
    product_meta_id
  }
}
    `;

export function useAdminAddProductMutation() {
  return Urql.useMutation<AdminAddProductMutation, AdminAddProductMutationVariables>(AdminAddProductDocument);
};
export const AdminVesselsDocument = gql`
    subscription AdminVessels {
  vessel: vessel_meta {
    id: vessel_meta_id
    mmsi
    countryCode: country_code
    name: ship_name
    imo
    additional
  }
}
    `;

export function useAdminVesselsSubscription<TData = AdminVesselsSubscription>(options: Omit<Urql.UseSubscriptionArgs<AdminVesselsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AdminVesselsSubscription, TData>) {
  return Urql.useSubscription<AdminVesselsSubscription, TData, AdminVesselsSubscriptionVariables>({ query: AdminVesselsDocument, ...options }, handler);
};