import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FormItem from 'src/components/form-item';
import FormTitle from 'src/components/form-title';

export type BankAccountPinFormFields = {
  confirmation_code: string;
};

export const BankAccountPinForm = () => {
  const [t] = useTranslation();

  return (
    <div className="container">
      <FormTitle suffix={false}>
        {t('banking.banking_account_modal_confirmation')}
      </FormTitle>
      <FormItem
        name="confirmation_code"
        label={t('banking.form.confirmation_code')}
      >
        <Input />
      </FormItem>
    </div>
  );
};

export default BankAccountPinForm;
