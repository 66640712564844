import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TabNavigation from '../layout-elements/tab-navigation';
import { BANKING } from '@common/routes';
import Access from 'src/access-checker/access';
import LayoutLoader from 'src/components/layout-loader';
import AddNewCurrencyModal from 'src/pages/banking/add-new-currency-modal';
import AddBankAccountModal from 'src/pages/banking/bank-account-add-modal';

export const LayoutBanking: React.FC = ({ children }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const onAccountsPage = history.location.pathname === BANKING.ACCOUNTS;

  return (
    <>
      <TabNavigation
        offset={false}
        tabs={[
          {
            accessKey: 'banking.nav.your-balance',
            title: t('banking.navigation.tab_your_balance'),
            url: BANKING.INTERNAL_TRANSACTIONS + history.location.search,
            key: BANKING.INTERNAL_TRANSACTIONS,
          },
          {
            accessKey: 'banking.nav.bank-accounts',
            title: t('banking.navigation.tab_bank_accounts'),
            url: BANKING.ACCOUNTS + history.location.search,
            key: BANKING.ACCOUNTS,
          },
        ]}
        customActions={
          onAccountsPage ? (
            <Access accessKey="banking.bank-accounts.add-bank-accounts">
              <AddBankAccountModal />
            </Access>
          ) : (
            <Access accessKey="banking.your-balance.add-new-currency">
              <AddNewCurrencyModal />
            </Access>
          )
        }
      />
      <div className="wrapper wrapper--offset-bottom">
        <LayoutLoader>{children}</LayoutLoader>
      </div>
    </>
  );
};

export default LayoutBanking;
