import { useEffect, useRef } from 'react';

import { useCloseWebsocket } from '../graphql/provider';
import { restClient } from '../graphql/rest-client';
import { useAuthSubscription } from './auth';

export function useReconnectOnRoleChange() {
  const didMount = useRef(false);
  const auth = useAuthSubscription();
  const closeWebsocket = useCloseWebsocket();
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      restClient
        .get('/api/rest/refresh')
        .then(() => {
          closeWebsocket();
        })
        .catch(() => {
          // This will trigger logout afterwards
          closeWebsocket();
        });
    }
  }, [auth.role]);
}
