import { Exchange, makeOperation } from 'urql';
import { pipe, map } from 'wonka';

function normalizeTypename(name: string) {
  return name.replace(/_by_pk$/, '');
}

export function additionalTypenamesExchange(): Exchange {
  return ({ forward, dispatchDebug }) =>
    (operations) =>
      pipe(
        operations,
        map((operation) => {
          const derivedTypenames = operation.query.definitions
            .flatMap(
              (def) =>
                def.kind === 'OperationDefinition' &&
                def.selectionSet.selections.map(
                  (sel) =>
                    sel.kind === 'Field' && normalizeTypename(sel.name.value),
                ),
            )
            .filter(Boolean) as string[];
          if (derivedTypenames.length) {
            dispatchDebug({
              type: 'additional-typenames',
              operation,
              message: 'Additional typenames were derived from the operation',
              data: { derivedTypenames },
            });
          }
          const additionalTypenames = (
            operation.context.additionalTypenames ?? []
          ).concat(derivedTypenames);
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            additionalTypenames,
          });
        }),
        forward,
      );
}
