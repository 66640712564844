import { notification } from 'antd';
import { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthState } from '../graphql/cookie-auth';
import { useCloseWebsocket } from '../graphql/provider';
import { Uuid } from '@common/db-types';
import { UserRole } from '@common/roles';
import {
  CheckAuthSubscription,
  useCheckAuthSubscription,
} from '@gql/auth.urql';
import { MutationRootLoginArgs } from '@gql/types';
import { restClient, RestClientError } from 'src/graphql/rest-client';

type AuthInfo = {
  profileInfo: CheckAuthSubscription['user'];
  userId: string;
  companyId: string;
  role: UserRole;
  login(data: MutationRootLoginArgs): Promise<{ error?: RestClientError }>;
  logout(): Promise<void>;
  loading: boolean;
  reloadProfile(): void;
  authState: AuthState;
};

// @ts-expect-error uninitialized
export const authContext = createContext<AuthInfo>();

export const useAuth = () => useContext(authContext);

export function useAuthSubscription(): AuthInfo {
  const [{ data, fetching, stale }, reloadProfile] = useCheckAuthSubscription();
  const history = useHistory();
  const [t] = useTranslation();
  const profileInfo = data?.user;
  const profileLoading = fetching;
  const [restLoading, setRestLoading] = useState(false);
  const closeWebsocket = useCloseWebsocket();

  const role = profileInfo?.role;
  const userId = profileInfo?.id as Uuid;
  const companyId = profileInfo?.company?.id as Uuid;

  let authState: AuthState;
  if (stale || fetching) {
    authState = AuthState.AUTHORIZING;
  } else if (role === undefined) {
    authState = AuthState.UNAUTHORIZED;
  } else {
    authState = AuthState.AUTHORIZED;
  }

  const login = useCallback(
    async (
      data: MutationRootLoginArgs,
    ): Promise<{ error?: RestClientError }> => {
      try {
        setRestLoading(true);
        await restClient.post('api/rest/login', { data });
        reloadProfile();
        return {};
      } catch (error) {
        const { data } = error as RestClientError;
        notification.error({ message: t(data.error) });
        return { error: error as RestClientError };
      } finally {
        setRestLoading(false);
      }
    },
    [reloadProfile],
  );

  const logout = useCallback(async () => {
    try {
      setRestLoading(true);
      await restClient.get('/api/rest/logout');
    } catch (err) {
      console.error(err);
    }
    closeWebsocket();
    setRestLoading(false);
    reloadProfile();
    history.replace(location.pathname, { isLogout: true });
  }, [reloadProfile, history]);

  return {
    userId,
    companyId,
    role: role as UserRole,
    profileInfo,
    authState: authState as AuthState,
    // profileLoading,
    reloadProfile,
    login,
    logout,
    loading: restLoading,
  };
}
