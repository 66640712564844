import { CombinedError } from 'urql';

export enum GqlError {
  INVALID_PASSWORD = 'invalid-password', // pragma: allowlist secret
  OFFER_ACCEPT_NOT_ENOUGH = 'accept-not-enough',
}

export function checkError(error: CombinedError | undefined, code: GqlError) {
  return error?.graphQLErrors.some(
    ({ extensions }) => extensions.code === code,
  );
}

export function getMessage(error: CombinedError | undefined, code: GqlError) {
  return (
    error?.graphQLErrors.find((e) => e.extensions.code === code)?.message ?? ''
  );
}
