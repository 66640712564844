import { Button, ButtonProps } from 'antd';
import { useContext } from 'react';

import { FormLoadingContext } from './form-main';
import { ACCESS_DENIED, ACCESS_READ } from 'src/access-checker/access-types';
import { AccessContext } from 'src/access-checker/provider';
import { AccessKey } from 'src/access-checker/types';

export interface ButtonSubmitProps extends ButtonProps {
  accessKey?: AccessKey;
}

export const ButtonSubmit: React.FC<ButtonSubmitProps> = ({
  accessKey,
  ...props
}) => {
  const { type = 'primary', children } = props;
  const { isLoading } = useContext(FormLoadingContext);
  const accessState = useContext(AccessContext);
  if (accessState && accessKey) {
    if (accessState[accessKey] === ACCESS_DENIED) {
      return null;
    }

    const propsMod = {
      ...props,
      disabled: accessState[accessKey] === ACCESS_READ,
    };

    return (
      <Button {...propsMod} htmlType="submit" type={type} loading={isLoading}>
        {children}
      </Button>
    );
  }

  return (
    <Button {...props} htmlType="submit" type={type} loading={isLoading}>
      {children}
    </Button>
  );
};

export default ButtonSubmit;
