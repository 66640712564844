import { QuestionOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { t } from 'i18next';

export const HelpContainer: React.FC = () => (
  <div className="help-box">
    <Tooltip title={t('help.title')}>
      <Button
        onClick={() => {
          window.$chatwoot?.toggle();
        }}
        type="text"
        size="large"
        shape="circle"
        icon={<QuestionOutlined />}
      />
    </Tooltip>
  </div>
);

export default HelpContainer;
