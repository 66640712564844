import { DeleteOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';

import FormModal, { ButtonPropsTypeMod } from './form-modal';
import { XSMALL_MODAL_WIDTH } from 'src/config/styles';

type FormValues = {
  setVisibility: (visibility: boolean) => void;
};

const CONFIRMATION_MODAL_DELETE = 'delete';
const CONFIRMATION_MODAL_EDIT = 'edit';

export type ConfirmationModalProps = {
  children?: React.ReactNode;
  onSubmit: (
    values: FormValues,
  ) => Promise<{ status: boolean }> | { status: boolean };
  button?: React.ReactNode;
  buttonType?: 'link' | 'text' | 'ghost' | 'primary' | 'default' | 'dashed';
  title?: string;
  okButton?: string;
  type?: typeof CONFIRMATION_MODAL_DELETE | typeof CONFIRMATION_MODAL_EDIT;
  beforeOpen?: (setIsModalVisible: (visible: boolean) => void) => void;
  disabled?: boolean;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  children,
  onSubmit,
  type = CONFIRMATION_MODAL_DELETE,
  button,
  title,
  okButton,
  buttonType,
  beforeOpen,
  disabled,
}) => {
  const [t] = useTranslation();

  const defaults = {
    [CONFIRMATION_MODAL_DELETE]: {
      button: {
        buttonText: <DeleteOutlined />,
        type: 'primary',
        danger: true,
      } as ButtonPropsTypeMod,
      title: t('components.confirm_removal'),
      okButtonText: t('components.delete_submit'),
      okButtonProps: {
        type: 'primary',
        danger: true,
      } as ButtonProps,
    },
    [CONFIRMATION_MODAL_EDIT]: {
      button: {
        buttonText: t('components.edit_button'),
        type: 'default',
        danger: false,
      } as ButtonPropsTypeMod,
      title: t('components.confirm_edit'),
      okButtonText: t('components.edit_submit'),
      okButtonProps: {
        type: 'primary',
        danger: false,
      } as ButtonProps,
    },
  };

  const modalInfo = defaults[type];

  if (typeof button !== 'undefined') {
    modalInfo.button.buttonText = <>{button}</>;
  }
  if (typeof buttonType !== 'undefined') {
    modalInfo.button.type = buttonType;
  }
  if (typeof title !== 'undefined') {
    modalInfo.title = title;
  }
  if (typeof okButton !== 'undefined') {
    modalInfo.okButtonText = okButton;
  }
  if (typeof beforeOpen !== 'undefined') {
    modalInfo.button.beforeOpen = beforeOpen;
  }
  if (disabled) {
    modalInfo.button.disabled = true;
  }

  return (
    <FormModal
      title={modalInfo.title}
      width={XSMALL_MODAL_WIDTH}
      formProps={{
        onSubmit,
      }}
      buttonProps={modalInfo.button}
      okButtonText={modalInfo.okButtonText}
      okButtonProps={modalInfo.okButtonProps}
    >
      <div className="container offset-bottom">{children}</div>
    </FormModal>
  );
};

export default ConfirmationModal;
