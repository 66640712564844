import { Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';

import { AcceptModalForm, AcceptModalProps } from './types';
import { ConfirmationAction } from '@common/confirmation-code';
import { checkError, GqlError } from '@common/errors';
import { ShipownerAction } from '@common/trading/status';
import { useSendConfirmationCodeMutation } from '@gql/auth.urql';
import { useShipownerOrderActionMutation } from '@gql/trading.urql';
import FormItem from 'src/components/form-item';
import FormModal from 'src/components/form-modal';
import { SMALL_MODAL_WIDTH } from 'src/config/styles';
import { getErrorMessage } from 'src/graphql/errors';

export const AcceptModal: React.FC<AcceptModalProps> = ({ id, disabled }) => {
  const [t] = useTranslation();
  const [{ fetching: orderActionLoading }, sendShipownerAction] =
    useShipownerOrderActionMutation();
  const [form] = useForm();

  if (!id) {
    return null;
  }

  const [{ fetching: sendConfirmationLoading }, sendConfirmCode] =
    useSendConfirmationCodeMutation();

  return (
    <FormModal
      title={t('shipowner.accept_modal')}
      width={SMALL_MODAL_WIDTH}
      formProps={{
        form,
        requiredMark: false,
        layout: 'vertical',
        onSubmit: async ({ setVisibility, code }: AcceptModalForm) => {
          const { error: confirmError } = await sendConfirmCode({
            data: {
              confirmation_code: code,
              confirmation_action: ConfirmationAction.ACCEPT_OFFER,
            },
          });
          if (confirmError) {
            form.setFields([
              { name: 'code', errors: [t(['shipowner.inccorect_code'])] },
            ]);
            return { status: false, error: t(['shipowner.inccorect_code']) };
          }
          const { data, error } = await sendShipownerAction({
            data: { action: ShipownerAction.ACCEPT_CONFIRM, order_id: id },
          });
          if (checkError(error, GqlError.OFFER_ACCEPT_NOT_ENOUGH)) {
            // TODO(arustamyan): show modal with cancel and deposit (https://jira.nodeart.app/browse/MAR-387)
          } else if (data) {
            setVisibility(false);
          }
          return { status: !!data };
        },
      }}
      buttonProps={{
        disabled,
        loading: orderActionLoading || sendConfirmationLoading,
        beforeOpen: async (setIsModalVisible) => {
          const { data } = await sendShipownerAction({
            data: { action: ShipownerAction.ACCEPT, order_id: id },
          });
          if (data) {
            setIsModalVisible(true);
          }
        },
        type: 'primary',
        buttonText: t('shipowner.form.accept'),
        className: 'cy-accept-modal',
      }}
      okButtonProps={{
        className: 'cy-accept-modal-submit',
      }}
    >
      <div className="container padding-bottom">
        <FormItem
          label={t('shipowner.confirmation_code')}
          name="code"
          rules={[
            {
              required: true,
              message: t('shipowner.errors.code_required'),
            },
          ]}
        >
          <Input />
        </FormItem>
      </div>
    </FormModal>
  );
};

export default AcceptModal;
