import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

const langs = [
  { langKey: 'en', langName: 'header_navigation.lng_english' },
  { langKey: 'es', langName: 'header_navigation.lng_spanish' },
  { langKey: 'de', langName: 'header_navigation.lng_german' },
  { langKey: 'fr', langName: 'header_navigation.lng_french' },
];

export const Languages: React.FC = () => {
  const [t] = useTranslation();
  const menu = (
    <Menu
      items={langs.map((l) => ({ label: t(l.langName), key: l.langKey }))}
    />
  );

  return (
    <Dropdown
      className="layout-languages"
      overlay={menu}
      placement="bottomRight"
      arrow
    >
      <GlobalOutlined className="color-heading" />
    </Dropdown>
  );
};

export default Languages;
