import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Dropdown, FormItemProps, Space } from 'antd';
import { t } from 'i18next';
import { useContext } from 'react';

import { currencyFormatter } from './currency-input';
import { dateFormat, stringToDateSaveForInput } from './date-format';
import { FormLoadingContext } from './form-main';
import { FormState } from './form-main-reducer';
import FormTitle from './form-title';
import { getInitialValueByName } from './helpers';
import { convert, TimeUnit } from './period-input';
import { volumeFormatter } from './volume-input';

export type Changes = {
  date: string;
  values: {
    [prop: string]: any;
  };
};

export const FORMAT_DATE = 'date';
export const FORMAT_SELECT = 'select';
export const FORMAT_VOLUME = 'volume';
export const FORMAT_PRICE = 'price';
export const FORMAT_TEXT = 'text';
export const FORMAT_BOOLEAN = 'bool';
export const FORMAT_PERIOD = 'period';

type FormatDate = {
  type: typeof FORMAT_DATE;
};
type FormatText = {
  type: typeof FORMAT_TEXT;
};
type FormatPrice = {
  type: typeof FORMAT_PRICE;
};
type FormatVolume = {
  type: typeof FORMAT_VOLUME;
};
type FormatBoolean = {
  type: typeof FORMAT_BOOLEAN;
};
type FormatPeriod = {
  type: typeof FORMAT_PERIOD;
  unit: TimeUnit;
};
type FormatSelect<T> = {
  type: typeof FORMAT_SELECT;
  options: T[];
  key: keyof T;
  value: string;
  value2?: string;
};

export type HistoryFormat<T> =
  | FormatDate
  | FormatText
  | FormatSelect<T>
  | FormatPrice
  | FormatBoolean
  | FormatPeriod
  | FormatVolume;

type FormItemChangesProps<T> = {
  names: FormItemProps['name'][];
  listName?: string[];
  label: React.ReactNode;
  format?: HistoryFormat<T>;
  changeHistory?: FormState['changeHistory'];
  separator?: React.ReactNode;
};

const formatValue = <T,>(value: any, format: HistoryFormat<T>) => {
  const emptyVal = (
    <span className="form-item-change-history__empty">{t('form.empty')}</span>
  );

  if (!value && value !== 0) return emptyVal;
  switch (format.type) {
    case FORMAT_DATE:
      return dateFormat(value);
    case FORMAT_SELECT:
      if (!format.options.length) return emptyVal;
      return (
        (format.options.find(
          (option) =>
            typeof option === 'object' && option?.[format.key] === value,
        ) ?? {})[format.value] ?? emptyVal
      );
    case FORMAT_PRICE:
      return currencyFormatter(value);
    case FORMAT_VOLUME:
      return volumeFormatter(value);
    case FORMAT_BOOLEAN:
      return value ? <CheckOutlined /> : <CloseOutlined />;
    case FORMAT_PERIOD:
      return convert(value, format.unit);
    default:
      return value;
  }
};

export const FormItemChanges = <T,>({
  names,
  listName,
  label,
  format = { type: FORMAT_TEXT },
  separator = '-',
  changeHistory,
}: FormItemChangesProps<T>): React.ReactElement => {
  const { initialValues } = useContext(FormLoadingContext);
  if (!Array.isArray(changeHistory) || !changeHistory.length || !names.length)
    return <>{label}</>;

  const fullNames: { name: FormItemProps['name']; key: string }[] = names.map(
    (name) => {
      const nameArr = Array.isArray(name) ? name : [name ?? ''];
      const fullName: (string | number)[] = [...(listName ?? []), ...nameArr];
      return {
        name: fullName,
        key: fullName.join(),
      };
    },
  );

  const currentItemChanges: Changes[] = [
    ...changeHistory,
    initialValues,
  ].reduce<Changes[]>((changes, initialValues, index) => {
    if (!(typeof initialValues === 'object' && initialValues)) return changes;
    const actionDate = stringToDateSaveForInput(
      initialValues.version_updated_at,
    );
    if (!actionDate) return changes;
    let isChanged = false;
    const values: Changes['values'] = {};
    for (const { name, key } of fullNames) {
      const value = getInitialValueByName(
        initialValues,
        name,
        'value-not-found-1',
      );

      values[key] = value;
      const prevChange = changes[changes.length - 1];
      if (
        value !== 'value-not-found-1' &&
        (changes.length === 0 || prevChange.values[key] !== value)
      )
        isChanged = true;
    }
    if (!isChanged) return changes;

    changes.push({
      values,
      date: dateFormat(actionDate) || '',
    });

    return changes;
  }, []);

  const setTitle = () => {
    if (typeof label === 'string') return label;
    return undefined;
  };

  if (currentItemChanges.length <= 1)
    return (
      <div
        className="form-item-change-history__label-only form-item-change-history__label"
        title={setTitle()}
      >
        {label}
      </div>
    );

  return (
    <div>
      {
        <div className="form-item-change-history__label" title={setTitle()}>
          {label}
        </div>
      }
      <div className="form-item-change-history__history-box">
        <Dropdown
          placement="top"
          overlay={
            <div className="form-item-change-history">
              {label && (
                <div className="form-item-change-history__item">
                  <FormTitle suffix={false} className="offset-bottom__none">
                    <>{label}</>
                  </FormTitle>
                </div>
              )}
              {currentItemChanges.map(({ date, values }, key) => {
                if (key === currentItemChanges.length - 1)
                  return <div key={key}></div>;
                return (
                  <div key={key} className="form-item-change-history__item">
                    <Space>
                      <FormTitle className="offset-bottom__none">
                        <>{date}</>
                      </FormTitle>
                      <span>
                        {Object.keys(values).map((key, index) => (
                          <span key={key}>
                            {index > 0 && <span> {separator} </span>}
                            <span>{formatValue(values[key], format)}</span>
                          </span>
                        ))}
                      </span>
                    </Space>
                  </div>
                );
              })}
            </div>
          }
        >
          <HistoryOutlined className="form-item-change-history__icon" />
        </Dropdown>
      </div>
    </div>
  );
};

export default FormItemChanges;
