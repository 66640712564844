import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { ADMIN } from '@common/routes';
import Access from 'src/access-checker/access';
import { AccessKey } from 'src/access-checker/types';
import LayoutLoader from 'src/components/layout-loader';

export const LayoutAdmin: React.FC = ({ children }) => {
  const [t] = useTranslation();

  const menuItems: { key: AccessKey; to: string; t: string }[] = [
    { key: 'admin-tabs.users', to: ADMIN.USERS, t: 'users' },
    { key: 'admin-tabs.companies', to: ADMIN.COMPANIES, t: 'companies' },
    { key: 'admin-tabs.vessels', to: ADMIN.VESSELS, t: 'vessels' },
    { key: 'admin-tabs.offers', to: ADMIN.OFFERS, t: 'offers' },
    {
      key: 'admin-tabs.transactions',
      to: ADMIN.TRANSACTIONS,
      t: 'transactions',
    },
    { key: 'admin-tabs.documents', to: ADMIN.DOCUMENTS, t: 'documents' },
    { key: 'admin-tabs.master-data', to: ADMIN.MASTER_DATA, t: 'master_data' },
    { key: 'admin-tabs.logs', to: ADMIN.LOGS, t: 'logs' },
    {
      key: 'admin-tabs.mailer',
      to: ADMIN.EMAIL_PLAYGROUND,
      t: 'email_playground',
    },
  ];

  return (
    <>
      <div className="container--white text-center admin-nav">
        {menuItems.map(({ key, to, t: tr }) => {
          const isActive = useRouteMatch(to);
          return (
            <Access accessKey={key} key={key}>
              <Link
                className={isActive ? 'admin-nav--active' : undefined}
                to={to}
              >
                {t('admin.navigation.' + tr)}
              </Link>
            </Access>
          );
        })}
      </div>
      <div className="wrapper wrapper--offset-bottom">
        <LayoutLoader>{children}</LayoutLoader>
      </div>
    </>
  );
};

export default LayoutAdmin;
