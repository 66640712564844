import { Select, SelectProps } from 'antd';
import { useMemo } from 'react';

import { countryNames } from '@common/country-utils';

type ValueType = string; // TODO
type Props = SelectProps<ValueType> & {
  value?: ValueType;
  onChange?: (value: ValueType) => void;
  countryCodes?: string[];
};

const handleFilter = (inputValue: string, option: any) => {
  const countryName = option.children.toLowerCase();
  return countryName.indexOf(inputValue.toLowerCase()) > -1;
};

export const CountrySelect: React.FC<Props> = ({
  value,
  onChange,
  countryCodes,
  ...selectProps
}) => {
  const filteredCountryNames = useMemo(() => {
    if (countryCodes) {
      return countryNames.filter(({ code }) => countryCodes.includes(code));
    }
    return countryNames;
  }, [countryCodes]);
  return (
    <Select
      value={value}
      showSearch
      onChange={(value: string) => onChange?.(value)}
      filterOption={handleFilter}
      {...selectProps}
    >
      {filteredCountryNames.map(({ name, code, flag }) => (
        <Select.Option key={code} value={code}>
          {`${flag} ${name}`}
        </Select.Option>
      ))}
    </Select>
  );
};
