import { Button, Result } from 'antd';
import { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { ACCESS_READ, ACCESS_WRITE } from './access-types';
import { AccessContext } from './provider';
import { AccessKey } from './types';
import { HOME_URL } from '@common/routes';

export type AccessProps = {
  accessKey: AccessKey;
  children?: React.ReactNode;
  show403?: boolean;
  redirectTo?: string;
  keepSpace?: boolean;
};

export const Access: React.FC<AccessProps> = ({
  accessKey,
  children,
  redirectTo,
  show403,
  keepSpace,
}) => {
  if (!accessKey) return null;
  const state = useContext(AccessContext);
  const histroy = useHistory();
  if (state[accessKey] === ACCESS_READ || state[accessKey] === ACCESS_WRITE) {
    return <>{children}</>;
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  if (show403) {
    return (
      <>
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              type="primary"
              onClick={() => {
                histroy.push(HOME_URL);
              }}
            >
              Back Home
            </Button>
          }
        />
      </>
    );
  }
  if (keepSpace) {
    return <div className="padding-bottom" />;
  }

  return null;
};
export default Access;
