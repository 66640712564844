import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { REQUEST_FOR_ROLE } from '@common/routes';
import { LayoutMain, LAYOUT_MODE_MIXED } from 'src/layouts/main/main';
const NewRoleRegistration = lazy(() => import('./registration/registration'));

export const RequestRoleApp: React.FC = () => (
  <LayoutMain mode={LAYOUT_MODE_MIXED}>
    <Switch>
      <Route path={REQUEST_FOR_ROLE.APP} component={NewRoleRegistration} />
    </Switch>
  </LayoutMain>
);

export default RequestRoleApp;
