import { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import EmailPlayground from './email-playground';
import { ADMIN } from '@common/routes';
import { PrivateRoute } from 'src/components/private-route';
import LayoutAdmin from 'src/layouts/admin/admin';
import { LayoutMain } from 'src/layouts/main/main';

const UsersApp = lazy(() => import('./users/users-app'));
const CompaniesApp = lazy(() => import('./companies/companies-app'));
const VesselsApp = lazy(() => import('./vessels/vessels-app'));
const OffersApp = lazy(() => import('./offers/offers-app'));
const TransactionsApp = lazy(() => import('./transactions/transactions-app'));
const DocumentsApp = lazy(() => import('./documents/documents-app'));
const LogsApp = lazy(() => import('./logs-app/logs-app'));
const MasterApp = lazy(() => import('./master/master-app'));

export const AdminApp: React.FC = () => (
  <LayoutMain>
    <LayoutAdmin>
      <Switch>
        <PrivateRoute
          accessProps={{ accessKey: 'users.app', show403: true }}
          path={ADMIN.USERS}
          component={UsersApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'companies.app', show403: true }}
          path={ADMIN.COMPANIES}
          component={CompaniesApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'vessels.app', show403: true }}
          path={ADMIN.VESSELS}
          component={VesselsApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'offers.app', show403: true }}
          path={ADMIN.OFFERS}
          component={OffersApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'transactions.app', show403: true }}
          path={ADMIN.TRANSACTIONS}
          component={TransactionsApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'documents.app', show403: true }}
          path={ADMIN.DOCUMENTS}
          component={DocumentsApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'logs.app', show403: true }}
          path={ADMIN.LOGS}
          component={LogsApp}
        />
        <PrivateRoute
          accessProps={{ accessKey: 'master.app', show403: true }}
          path={ADMIN.MASTER_DATA}
          component={MasterApp}
        />
        <PrivateRoute
          path={ADMIN.EMAIL_PLAYGROUND}
          component={EmailPlayground}
        />
        <Redirect to={ADMIN.USERS} />
      </Switch>
    </LayoutAdmin>
  </LayoutMain>
);

export default AdminApp;
