import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';

import { FileAttachment } from '@gql/types';

export function transformGqlToAntdFormValues(values: object) {
  const entries = Object.entries(values).map(([name, value]) => {
    if (typeof value !== 'string' || !value.endsWith('Z')) {
      return [name, value];
    }
    const d = new Date(value);
    return [name, d.toString() === 'Invalid Date' ? value : dayjs(value)];
  });
  return Object.fromEntries(entries);
}

const fmt = new Intl.DateTimeFormat();

export function formatDateInTable(isoDate: string) {
  return fmt.format(new Date(isoDate));
}

export const antdFilesToGraphQL = (fileList: UploadFile[]) =>
  Promise.all(fileList.map(antdFileToGraphQL));

export async function antdFileToGraphQL(
  file: UploadFile,
): Promise<FileAttachment> {
  return {
    content_base64: await fileToBase64(file.originFileObj!),
    file_name: file.name,
    mime_type: file.type,
  };
}

export function fileToBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (ev) => {
      const result = ev.target?.result as string;
      const parts = result.split('base64,');
      resolve(parts[1]);
    };
    fr.onerror = (ev) => reject(ev);
    fr.readAsDataURL(file);
  });
}
