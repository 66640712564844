import { ControlOutlined } from '@ant-design/icons';
import roles from '@hasura-roles';
import { Button, Select, Switch } from 'antd';
import { createContext, useEffect, useReducer, useState } from 'react';

import { ACCESS_DENIED, ACCESS_READ, ACCESS_WRITE } from './access-types';
import { actionChangeKey, actionChangeState } from './actions';
import accessKeys from './keys';
import accessContextReducer, { defaultState } from './reducer';
import { AccessType } from './types';
import { AvailableRolesQuery } from '@common/__generated__/admin.urql';
import FormItem from 'src/components/form-item';
import { useAuthSubscription } from 'src/hooks';

export const AccessContext = createContext(defaultState);
const filterKeys: string[] = [];
for (const accessKey of accessKeys) {
  const accessArr = accessKey.split('.');
  const [filterKey1, filterKey2] = accessArr;
  const filterKey =
    accessArr.length > 2 ? [filterKey1, filterKey2].join('.') : filterKey1;
  if (filterKeys.includes(filterKey)) continue;
  filterKeys.push(filterKey);
}

type Props = {
  children: React.ReactNode;
  cpanel?: boolean;
};

export const AccessProvider: React.FC<Props> = ({ children, cpanel }) => {
  const [state, dispatch] = useReducer(accessContextReducer, {
    ...defaultState,
  });
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState('');
  const auth = useAuthSubscription();
  const [saveRole, setSaveRole] = useState<string>();
  const tmpRoles: AvailableRolesQuery['available_roles']['roles'][number][] =
    Object.entries(roles).map(([role, inherits]) => ({
      id: role,
      inherits_from: inherits,
      name: role,
    }));

  useEffect(() => {
    const { role } = auth;
    if (role !== saveRole) {
      console.log('change role', role);
      setSaveRole(role);
      const roleItem = tmpRoles.find(({ id }) => id === role);
      dispatch(actionChangeState(roleItem ? roleItem.inherits_from : []));
    }
  }, [auth]);

  return (
    <AccessContext.Provider value={state}>
      {children}
      {cpanel && (
        <>
          {show && (
            <div className="cpanel">
              {accessKeys
                .filter((key) => key.search(filter) === 0)
                .map((key) => (
                  <FormItem key={key} label={key}>
                    {key.search('\\.f\\.') === -1 ? (
                      <Switch
                        checked={state[key] !== ACCESS_DENIED}
                        onChange={() => {
                          dispatch(
                            actionChangeKey(
                              key,
                              state[key] === ACCESS_DENIED
                                ? ACCESS_READ
                                : ACCESS_DENIED,
                            ),
                          );
                        }}
                      />
                    ) : (
                      <Select
                        className="input-sm"
                        onChange={(value: AccessType) => {
                          dispatch(actionChangeKey(key, value));
                        }}
                        value={state[key]}
                      >
                        <Select.Option value={ACCESS_DENIED}>
                          Denied
                        </Select.Option>
                        <Select.Option value={ACCESS_READ}>Read</Select.Option>
                        <Select.Option value={ACCESS_WRITE}>
                          Write
                        </Select.Option>
                      </Select>
                    )}
                  </FormItem>
                ))}
            </div>
          )}
          <div className="cpanel--actions">
            <Button
              onClick={() => {
                setShow(!show);
              }}
            >
              <ControlOutlined />
            </Button>
            {show && (
              <Select
                className="cpanel--filter"
                onChange={(value: string) => setFilter(value)}
                value={filter}
              >
                <Select.Option value="">Show All</Select.Option>
                {filterKeys.map((filterKey) => (
                  <Select.Option key={filterKey} value={filterKey}>
                    {filterKey}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
        </>
      )}
    </AccessContext.Provider>
  );
};

export default AccessProvider;
