import { CloseCode } from 'graphql-ws';
import { Exchange, makeErrorResult, Operation, OperationResult } from 'urql';
import { share, filter, pipe, makeSubject, merge, Source } from 'wonka';

import { restClient } from './rest-client';

type CookieAuth = () => {
  exchange: Exchange;
  authStateSource: Source<AuthState>;
};

export enum AuthState {
  AUTHORIZING = 'authorizing',
  AUTHORIZED = 'authorized',
  INVALID_JWT = 'invalid_jwt',
  REFRESHING = 'refreshing',
  UNAUTHORIZED = 'unauthorized',
  INVALID_ROLE = 'invalid_role',
  FORCE_CLOSED = 'force_closed',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function resultToAuthState(result: OperationResult): AuthState {
  const { error } = result;
  if (!error) return AuthState.AUTHORIZED;
  const { networkError, graphQLErrors } = error;
  if (error.networkError) {
    if (Array.isArray(networkError)) {
      graphQLErrors.push(...networkError);
    } else if (networkError instanceof CloseEvent) {
      if (networkError.code === 1006 && networkError.reason === '') {
        // See README.hasura.md
        return AuthState.INVALID_JWT;
      }
      if (networkError.code === CloseCode.BadRequest) {
        return AuthState.INVALID_JWT;
      }
      return AuthState.UNAUTHORIZED;
      // @ts-expect-error typing
    } else if (networkError.code === 4499) {
      return AuthState.FORCE_CLOSED;
    }
  }
  if (
    graphQLErrors.some(
      (err) =>
        err.extensions.code === 'validation-failed' &&
        (err.message === 'no subscriptions exist' ||
          err.message.match(
            /^field ["']\S+["'] not found in type: '(query|mutation|subscription)_root'/,
          )),
    )
  )
    return AuthState.UNAUTHORIZED;
  if (graphQLErrors.some((err) => err.extensions.code === 'invalid-jwt'))
    return AuthState.INVALID_JWT;
  if (
    graphQLErrors.some(
      (err) =>
        err.extensions.code === 'validation-failed' &&
        err.message.match(/^field ["']\S+["'] not found in type: '\S+'/),
    )
  )
    return AuthState.INVALID_ROLE;
  return AuthState.AUTHORIZED;
}

export class UnauthorizedError extends Error {}

export const cookieAuth: CookieAuth = () => {
  const { source: injectedOperations, next: pushOperation } =
    makeSubject<Operation>();
  const { source: injectedResults, next: pushResult } =
    makeSubject<OperationResult>();
  const { source: authStateSource, next: pushAuthState } =
    makeSubject<AuthState>();

  pushAuthState(AuthState.AUTHORIZING);

  let isRefreshing = false;
  let operationQueue: Operation[] = [];

  // Should never throw
  async function refreshJwt(result: OperationResult) {
    try {
      isRefreshing = true;
      pushAuthState(AuthState.REFRESHING);
      await restClient.get('/api/rest/refresh');
      for (const op of operationQueue) {
        pushOperation(op);
      }
      operationQueue = [];
      pushAuthState(AuthState.AUTHORIZED);
    } catch (err) {
      await restClient.get('/api/rest/logout').catch(() => undefined);
      // We need to push result to satisfy `dedupExchange` mechanism, which
      // will block any further requests which didn't receive a response
      const errorResult = makeErrorResult(
        result.operation,
        new UnauthorizedError(),
      );
      pushResult(errorResult);
      pushAuthState(AuthState.UNAUTHORIZED);
    } finally {
      isRefreshing = false;
    }
  }

  const exchange: Exchange =
    ({ forward }) =>
    // eslint-disable-next-line arrow-body-style
    (operations) => {
      const filteredOps = pipe(
        operations,
        filter((op) => {
          if (isRefreshing) {
            if (op.kind !== 'teardown') {
              operationQueue.push(op);
            }
            return false;
          }
          return true;
        }),
        share,
      );

      const results = pipe(
        merge([filteredOps, injectedOperations]),
        forward,
        filter((result) => {
          const state = resultToAuthState(result);
          switch (state) {
            case AuthState.INVALID_JWT: {
              // We need to refresh token
              operationQueue.push(result.operation);
              if (!isRefreshing) {
                refreshJwt(result);
              }
              return false;
            }
            case AuthState.UNAUTHORIZED: {
              // We need to push result to satisfy `dedupExchange` mechanism
              const errorResult = makeErrorResult(
                result.operation,
                new UnauthorizedError(),
              );
              pushResult(errorResult);
              pushAuthState(AuthState.UNAUTHORIZED);
              return false;
            }
            case AuthState.AUTHORIZED:
              pushAuthState(AuthState.AUTHORIZED);
              return true;
            // case AuthState.FORCE_CLOSED:
            //   pushOperation(result.operation);
            //   return false;
            default:
              return true;
          }
        }),
        share,
      );

      return merge([results, injectedResults]);
    };

  return { exchange, authStateSource };
};
