import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { activityType, ActivityType, UserRole } from '@common/roles';
import { REQUEST_FOR_ROLE } from '@common/routes';
import ConfirmationModal from 'src/components/confirmation-modal';
import { useAuth } from 'src/hooks';

export const RequestRoleModal = () => {
  const [t] = useTranslation();
  const { profileInfo, role } = useAuth();
  const activity = activityType(role as UserRole);
  const history = useHistory();

  if (!profileInfo) return <Spin />;
  const disabled = profileInfo.additional.request_another_activity;

  const props =
    activity === ActivityType.SUPPLIER
      ? {
          button: 'profile.request_shipowner_activity',
          message: 'profile.request_shipowner_activity_confirmation',
        }
      : {
          button: 'profile.request_supplier_activity',
          message: 'profile.request_supplier_activity_confirmation',
        };

  return (
    <ConfirmationModal
      title={t(props.button)}
      buttonType="default"
      button={t(props.button)}
      disabled={disabled}
      type="edit"
      onSubmit={({ setVisibility }) => {
        history.push(REQUEST_FOR_ROLE.APP);
        setVisibility(false);
        return Promise.resolve({ status: true });
      }}
    >
      {t(props.message)}
    </ConfirmationModal>
  );
};

export default RequestRoleModal;
