import { SET_NOTIFICATIONS } from './actions';
import { NotificationsState } from './types';

export const defaultState: NotificationsState = {
  loading: false,
  unread: 0,
  notifications: [],
};

const getUnreadCount = (
  notificationItems: NotificationsState['notifications'] = [],
): number => {
  let count = 0;
  for (const item of notificationItems) {
    // TODO: counter
    count++;
  }
  return count;
};

export const accessContextReducer = (
  state = defaultState,
  action,
): NotificationsState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        unread: getUnreadCount(action.payload),
      };
    default:
      return state;
  }
};

export default accessContextReducer;
