import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  suffix?: boolean;
  children: JSX.Element | JSX.Element[];
  className?: string;
};

export const FormTitle = ({
  children,
  suffix = true,
  className = '',
}: Props) => {
  const [t] = useTranslation();
  return (
    <Typography className={'form-title ' + className}>
      {children}
      {suffix ? t('form.title_suffix') : ''}
    </Typography>
  );
};

export default FormTitle;
