export type RestResponse<T = unknown> = {
  response: Response;
  data: T;
};

export type RestClientErrorType = {
  path: string;
  error: string;
  code: string;
};

export class RestClientError<E = RestClientErrorType> extends Error {
  constructor(public data: E, public response: Response) {
    super('Error fetching from rest endpoint');
  }
}

export class RestClient {
  constructor(private basePath: string) {}

  private normalizeUrl(path: string) {
    return `${this.basePath}${path.replace(/^\/*/, '/')}`;
  }

  private defaultHeaders() {
    const headers = { 'client-origin': location.origin };
    const queryParams = new URLSearchParams(location.search);
    const ciHcaptchaSecret = queryParams.get('ci');
    if (ciHcaptchaSecret) {
      headers['ci-hcaptcha-secret'] = ciHcaptchaSecret;
    }
    return headers;
  }

  async post<T = unknown>(
    path: string,
    requestData: unknown,
    fetchOptions?: Partial<Request>,
  ): Promise<RestResponse<T>> {
    const response = await fetch(this.normalizeUrl(path), {
      method: 'POST',
      headers: {
        ...this.defaultHeaders(),
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(requestData),
      ...fetchOptions,
    });
    const data = await response.json();
    if (response.ok) {
      return { response, data };
    }
    throw new RestClientError(data, response);
  }

  async get<T = unknown>(
    path: string,
    fetchOptions?: Partial<Request>,
  ): Promise<RestResponse<T>> {
    const response = await fetch(this.normalizeUrl(path), {
      credentials: 'include',
      headers: this.defaultHeaders(),
      ...fetchOptions,
    });
    const data: T = await response.json();
    if (response.ok) {
      return { response, data };
    }
    throw new RestClientError(data, response);
  }

  async call<T = unknown>(path: string, data?: unknown) {
    if (data) {
      return this.post<T>(path, data);
    } else {
      return this.get<T>(path);
    }
  }
}

const {
  VITE_HASURA_SERVICE_SCHEMA,
  VITE_HASURA_SERVICE_NAME,
  VITE_HASURA_SERVICE_PORT,
  MODE,
} = import.meta.env;

let HTTP_BASE_URL = `${VITE_HASURA_SERVICE_SCHEMA}://${VITE_HASURA_SERVICE_NAME}:${VITE_HASURA_SERVICE_PORT}`;
if (MODE === 'development') {
  HTTP_BASE_URL = '';
}

export const restClient = new RestClient(HTTP_BASE_URL);
