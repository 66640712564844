import { notification } from 'antd';
import {
  createContext,
  HTMLAttributes,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// import ReCaptcha from 'react-google-recaptcha';

type ResolveCb = (value: string) => void;
type RejectCb = (err: Error) => void;

type ContextValue = {
  getToken(): Promise<string | undefined>;
  loading: boolean;
  _sitekey: string;
  _widgetId: string | undefined;
  _setWidgetId(id: string | undefined): void;
  _resolveRef: MutableRefObject<ResolveCb | undefined>;
  _rejectRef: MutableRefObject<RejectCb | undefined>;
  // _setInstance(instance: ReCaptcha | null): void;
};

type Props = {
  sitekey: string;
};

type RecaptchaProps = HTMLAttributes<HTMLDivElement>;

// @ts-expect-error allow empty
const recaptchaV2Context = createContext<ContextValue>();
export const useRecaptcha = () => useContext(recaptchaV2Context);

const RECAPTCHA_KEY = 'grecaptcha';
const SCRIPT_ID = 'recaptcha-src';
const ON_LOAD_NAME = 'recaptchaOnLoad';

const isCypress = typeof window['Cypress'] !== 'undefined';

export const ReCaptchaV2Provider: React.FC<Props> = ({ children, sitekey }) => {
  const [_widgetId, _setWidgetId] = useState<string | undefined>();
  const [executeLoading, setExecuteLoading] = useState(false);
  const _resolveRef = useRef<ResolveCb>();
  const _rejectRef = useRef<RejectCb>();
  const loading = !isCypress && (_widgetId === undefined || executeLoading);
  const getToken = () =>
    new Promise<string>((resolve, reject) => {
      if (isCypress) {
        resolve('CYPRESS_ENV');
        return;
      }
      const loadingFlagTimeout = setTimeout(() => setExecuteLoading(false));
      function resetAll() {
        clearTimeout(loadingFlagTimeout);
        setExecuteLoading(false);
        _resolveRef.current = undefined;
        _rejectRef.current = undefined;
        window[RECAPTCHA_KEY]?.reset();
      }
      if (typeof window[RECAPTCHA_KEY]?.execute === 'function') {
        setExecuteLoading(true);
        window[RECAPTCHA_KEY].execute(_widgetId);
        _resolveRef.current = (token: string) => {
          resetAll();
          resolve(token);
        };
        _rejectRef.current = (...args) => {
          resetAll();
          reject(...args);
        };
      } else {
        clearTimeout(loadingFlagTimeout);
        resolve('NOT_LOADED');
      }
    });
  const value = {
    getToken,
    loading,
    _sitekey: sitekey,
    _widgetId,
    _setWidgetId,
    _resolveRef,
    _rejectRef,
  };
  return (
    <recaptchaV2Context.Provider value={value}>
      {children}
    </recaptchaV2Context.Provider>
  );
};

export const RecaptchaV2Container: React.FC<RecaptchaProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { _sitekey, _widgetId, _setWidgetId, _resolveRef, _rejectRef } =
    useRecaptcha();
  useEffect(() => {
    if (isCypress) return;
    function onLoad() {
      if (_widgetId !== undefined) return;
      const widgetId = window[RECAPTCHA_KEY]?.render(containerRef.current, {
        sitekey: _sitekey,
        size: 'invisible',
        callback(token: string) {
          _resolveRef.current?.(token);
        },
        'expired-callback'() {
          _rejectRef.current?.(new Error('expired'));
        },
        'error-callback'(err: Error) {
          _rejectRef.current?.(err);
        },
      });
      delete window[ON_LOAD_NAME];
      _setWidgetId(widgetId);
    }
    if (document.head.querySelector(`script#${SCRIPT_ID}`)) {
      onLoad();
    } else {
      Object.assign(window, { [ON_LOAD_NAME]: onLoad });
      const scriptElem = document.createElement('script');
      scriptElem.src = `https://www.google.com/recaptcha/api.js?onload=${ON_LOAD_NAME}&render=explicit`;
      scriptElem.id = SCRIPT_ID;
      scriptElem.async = true;
      scriptElem.defer = true;
      scriptElem.addEventListener('error', () => {
        notification.error({
          message: 'Failed to load the captcha',
          description: 'Click to reload the page',
          style: { cursor: 'pointer' },
          onClick: () => location.reload(),
        });
        _setWidgetId('failed-to-load');
      });
      document.head.appendChild(scriptElem);
    }
    return () => {
      if (_widgetId !== undefined) {
        window[RECAPTCHA_KEY]?.reset(_widgetId);
        _setWidgetId(undefined);
      }
    };
  }, [_widgetId]);
  return <div {...props} ref={containerRef} />;
};
