import { useCallback } from 'react';
import { useClient } from 'urql';

import { Uuid } from '@common/db-types';
import {
  GetDocumentContentDocument,
  GetDocumentContentQuery,
} from '@gql/admin.urql';

export function useDownloadFile() {
  const client = useClient();
  return useCallback(async (fileId: Uuid) => {
    const { data } = await client
      .query<GetDocumentContentQuery>(
        GetDocumentContentDocument,
        { fileId },
        { requestPolicy: 'network-only' },
      )
      .toPromise();
    if (data?.file) {
      const { name, content, type } = data.file;
      const dataUrl = `data:${type};base64,${content}`;
      const dataUrlBlob = await fetch(dataUrl).then((r) => r.blob());
      const objUrl = URL.createObjectURL(dataUrlBlob);
      const anchor = document.createElement('a');
      anchor.href = objUrl;
      if (name) {
        anchor.download = name;
      }
      anchor.click();
      setTimeout(() => {
        URL.revokeObjectURL(objUrl);
      }, 100);
    }
  }, []);
}
